import React from 'react';
import { Discount } from './Discount';

const discountRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/order/:orderID/discount`,
		},
		component: <Discount />,
	},
];

export default discountRoutes;
