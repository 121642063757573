import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetTableDocument,
	useCloseOrderStatusMutation,
} from 'generated/graphql';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

export const CloseOrderDialog = (props: any) => {
	const { state, dispatch, order, orderItems } = props;

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const { handleSubmit } = useForm<any>({
		defaultValues: {},
	});

	const history = useHistory();

	// function
	const onCloseSubmit = async (data: any) => {
		closeOrderStatus({
			variables: {
				orderID: state?.selectedOrder?.ID,
				billInput: {
					orderID: state?.selectedOrder?.ID,
					outletID: state?.outletID,
					orderItemIDs: orderItems?.map(x => x?.ID),
				},
			},
			refetchQueries: [
				{
					query: GetTableDocument,
					variables: { outletID: state?.outletID },
				},
			],
		});
	};

	const [
		closeOrderStatus,
		{ data: closeOrder, loading: closeOrderLoading },
	] = useCloseOrderStatusMutation({
		onError: error => {
			console.log('Suberror', error);
			setOpenSnackBar(true);
			setSnackBarMsg('Failed to close order');
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg('Closed order successfully');
			history.push({
				pathname: `/menu/outlet-app/${state?.outletID}/table/grid`,
				state: { success: true, msgMode: 'create' },
			});
		},
	});

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={state?.closeOrderDialog}
				onClose={() => dispatch({ type: 'closeOrderDialog', payload: false })}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title highlight-text">Close Order</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="xsTitle infoline">{order?.docNo}</div>
								</div>
							</div>
						),
					},
					body: () => (
						<div className="content-container mdDesc">
							Do you want to close order {order?.docNo}?
						</div>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										if (orderItems?.length > 0)
											history.push({
												pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/close-table`,
												state: {
													close: 'order',
													order: order,
												},
											});
										dispatch({ type: 'closeOrderDialog', payload: false });
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => handleSubmit(onCloseSubmit)(),
									variant: 'contained',
									form: 'close-form',
									color: closeOrderLoading ? 'inherit' : 'primary',
									disabled: closeOrderLoading ? true : false,
									type: 'submit',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
