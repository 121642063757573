import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { useTransferTableMutation } from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

interface TransferTableProps {}

export const TransferTableDialog = (props: any) => {
	const {
		state,
		dispatch,
		currentTable,
		orderID,
		setOpenSnackBar,
		setSnackBarMsg,
		orders,
	} = props;

	const history = useHistory();

	const { handleSubmit } = useForm<TransferTableProps>({
		defaultValues: {},
	});

	const [
		transferTable,
		{ data: transferTableData, loading: transferTableLoading },
	] = useTransferTableMutation({
		onError: error => {
			console.log('Suberror', error);
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);

			history.push(`/menu/outlet-app/${state?.outletID}/table/grid`);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.transferRecord());

			history.push({
				pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.selectedTable?.ID}/edit/${orderID}`,
				state: {
					table: state?.selectedTable,
					orders: orders,
					orderID: orderID,
					page: 'edit-order-info',
				},
			});
		},
	});

	const onSubmit = async (data: any) => {
		transferTable({
			variables: {
				newTableID: state?.selectedTable?.ID,
				oldTableID: state?.tableID,
				orderID: orderID,
			},
			refetchQueries: [],
		});
		dispatch({ type: 'transferTableDialog', payload: false });
		dispatch({ type: 'tableData', payload: state?.selectedTable });
	};

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={state?.transferTableDialog}
				onClose={() => {
					dispatch({ type: 'transferTableDialog', payload: false });
					dispatch({ type: 'selectedTable', payload: {} });
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title highlight-text">Transfer Table</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="xsTitle infoline">{currentTable}</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="content-container mdDesc">
								Transfer to {state?.selectedTable?.prefix}
								{state?.selectedTable?.tableNumber}?
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										dispatch({ type: 'transferTableDialog', payload: false });
										dispatch({ type: 'selectedTable', payload: {} });
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => handleSubmit(onSubmit)(),
									variant: 'contained',
									form: 'close-form',
									color: transferTableLoading ? 'inherit' : 'primary',
									disabled: transferTableLoading ? true : false,
									type: 'submit',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
