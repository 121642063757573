import React, {
	Reducer,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { IAction } from 'helpers/model';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import {
	CommonStatus,
	GetHomeTableSummaryDocument,
	GetOrderDocument,
	GetTableDocument,
	GetTableSubmenuTableDocument,
	OrderStatus,
	TableStatus,
	useCreateOrderMutation,
	useCreateQrMutation,
	useGetCurrentMealPeriodLazyQuery,
	useGetMenuXInhouseListLazyQuery,
	useGetOfficerLazyQuery,
	useGetOutletCustomerPolicyQuery,
	useGetOutletQuery,
	useGetPatronQuery,
	useGetReceiptPrinterLazyQuery,
	useGetStaffLazyQuery,
	useGetTableSubmenuTableQuery,
	useGetUsersByAccountAndSoftwareQuery,
	useUpdateOrderMutation,
} from 'generated/graphql';
import { countryAlphaCodes } from 'utils/countryAlphaCodes';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { Controller, useForm } from 'react-hook-form';
import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { ProfileIcon } from 'assets';
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'helpers/SystemMsg';
import { printReceiptText } from 'containers/OutletModule/Home/TableSubmenu/BillSettlement/BillReceipt/BillReceiptFunctions';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { TransferTableDialog } from './TransferTableDialog';

interface NewOrderProps {
	tableNumber: string;
	tableID: string;
	pax: number;
	patronName: string;
	contactNo: string;
	email: string;
	patronAccountID: string;
	patronID: string;
	staffID?: string;
	officerID?: string;
	docNo: string;
}

interface Props {
	visitorTextField: boolean;
	hotelSearchField: boolean;
	staffSearchField: boolean;
	officerSearchField: boolean;
	hotelState: any;
	staffState: any;
	officerState: any;
	selectedGuest: any;
	selectedStaff: any;
	selectedOfficer: any;
	currentMealPeriod: any;
	countryCode: any;
	disable: boolean;
	printerName: any;
	qrID: any;
	tableData: any;
	tableID: any;
	outletID: any;
	transferTableDialog: boolean;
	selectedTable: any;
}

export const NewOrder = (props: any) => {
	const history = useHistory();

	const { tableID, outletID } = useParams<any>();
	const { state: location }: any = useLocation();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const { mode } = props;

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const initialState: Props = {
		visitorTextField: false,
		hotelSearchField: false,
		staffSearchField: false,
		officerSearchField: false,
		hotelState: '',
		staffState: '',
		officerState: '',
		selectedGuest: {},
		selectedStaff: {},
		selectedOfficer: {},
		currentMealPeriod: null,
		countryCode: 'my',
		disable: true,
		printerName: null,
		qrID: '',
		tableData: location.table,
		tableID: tableID,
		outletID: outletID,
		transferTableDialog: false,
		selectedTable: {},
	};

	const reducer: Reducer<Props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const selectedOrder = location?.orders?.filter(
		x => x?.ID === location?.orderID,
	)[0];

	const [state, dispatch] = useReducer(reducer, initialState);
	const [changePatronAccountID, setChangePatronAccountID] = useState();

	const yupSchema = yup.object().shape({
		// patronID: yup.string().required('Customer Type is required!'),
		pax: yup.string().required(SystemMsgs.pax()),
	});

	console.log('selectedOrder >>>', selectedOrder);
	const {
		control,
		register,
		handleSubmit,
		errors,
		watch,
		setValue,
		getValues,
	} = useForm<NewOrderProps>({
		defaultValues: {
			tableNumber:
				mode === 'edit'
					? tableID
					: `${state?.tableData?.prefix}${state?.tableData?.tableNumber}`,
			pax:
				mode === 'add'
					? state?.tableData?.orderInfo
						? state?.tableData?.orderInfo?.totalPax >=
						  state?.tableData?.seatCount
							? 0
							: state?.tableData?.seatCount -
							  state?.tableData?.orderInfo?.totalPax
						: state?.tableData?.seatCount
					: selectedOrder?.pax,
			docNo: mode === 'edit' ? selectedOrder?.docNo : null,
			patronID: mode === 'edit' ? selectedOrder?.patronID : null,
			patronName: mode === 'edit' ? selectedOrder?.patronName : null,
			email: mode === 'edit' ? selectedOrder?.email : null,
			contactNo: mode === 'edit' ? selectedOrder?.contactNo : null,
		},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	// API
	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			dispatch({ type: 'currentMealPeriod', payload: getCurrentMealPeriod });
		},
	});

	const {
		loading: outletLoading,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
			const countryCodes = Object.keys(countryAlphaCodes);
			countryCodes.forEach(code => {
				if (countryAlphaCodes[code] === getOutlet[0]?.address?.country) {
					dispatch({ type: 'countryCode', payload: code.toLowerCase() });
				}
			});
		},
	});

	const {
		data: { getPatron } = { getPatron: [] },
		loading: patronLoading,
	} = useGetPatronQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: state?.outletID, isActive: true },
	});

	const [
		loadMenuXInhouseList,
		{
			loading: MenuXInhouseListLoading,
			called: MenuXInhouseListCalled,
			data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
		},
	] = useGetMenuXInhouseListLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadStaff,
		{ loading: getStaffLoading, data: { getStaff } = { getStaff: [] } },
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadOfficer,
		{ loading: getOfficerLoading, data: { getOfficer } = { getOfficer: [] } },
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
	});

	const {
		loading: getOutletCustomerPolicyLoading,
		data: { getOutletCustomerPolicy } = { getOutletCustomerPolicy: [] },
	} = useGetOutletCustomerPolicyQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: state?.outletID,
		},
	});

	const [
		loadReceiptPrinter,
		{
			loading: receiptPrinterLoading,
			data: { getReceiptPrinter } = { getReceiptPrinter: [] },
		},
	] = useGetReceiptPrinterLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getReceiptPrinter }) => {
			dispatch({ type: 'printerName', payload: getReceiptPrinter[0].name });
		},
	});

	const {
		loading: userLoading,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			status: CommonStatus.Active,
		},
	});

	const {
		data: { getTable } = { getTable: [] },
		loading: getTableLoading,
		fetchMore,
	} = useGetTableSubmenuTableQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
			orderByAsc: 'sequence',
			isActive: true,
		},
	});

	const [
		submitQR,
		{ data: createdQRData, loading: submitQRLoading },
	] = useCreateQrMutation({
		fetchPolicy: 'network-only',
		onCompleted: ({ createQR }) => {
			dispatch({ type: 'qrID', payload: createQR.ID });
		},
	});

	const [createOrder, { loading: createOrderLoading }] = useCreateOrderMutation(
		{
			onError: error => {
				setOpenSnackBar(true);
				if (error?.message?.includes('mealPeriod')) {
					setSnackBarMsg('No applicable meal period');
				} else {
					setSnackBarMsg(SystemMsgs.createNewRecordFail());
				}
			},
			onCompleted: ({ createOrder }) => {
				history.push({
					pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableData?.ID}/order/${createOrder?.ID}/add-edit-order`,
					state: {
						page: 'new-order',
					},
				});
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.createNewRecord());
			},
		},
	);

	const [updateOrder, { loading: updateOrderLoading }] = useUpdateOrderMutation(
		{
			onError: error => {
				console.log('Suberror', error);
				setOpenSnackBar(true);
				setSnackBarMsg(error.message);
				history.push(`/menu/outlet-app/${outletID}/table/grid`);
			},
			onCompleted: ({ updateOrder }) => {
				history.push({
					pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableData?.ID}/order/${location?.orderID}/confirm-order`,
				});
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.updateRecord());
			},
		},
	);

	// function
	const handlePatronClass = patronClass => {
		dispatch({ type: 'visitorTextField', payload: false });
		dispatch({ type: 'hotelSearchField', payload: false });
		dispatch({ type: 'staffSearchField', payload: false });
		dispatch({ type: 'officerSearchField', payload: false });

		switch (patronClass) {
			case 'Visitor':
				dispatch({ type: 'visitorTextField', payload: true });
				break;
			case 'Member':
				break;
			case 'Hotel Guest':
				dispatch({ type: 'hotelSearchField', payload: true });
				if (
					getOutlet[0]?.outletChannel?.hotelID &&
					getMenuXInhouseList?.length === 0 &&
					!MenuXInhouseListCalled
				) {
					loadMenuXInhouseList({
						variables: {
							hotelID: getOutlet[0]?.outletChannel?.hotelID,
							outletID: state?.outletID,
						},
					});
				}
				break;
			case 'Staff':
				dispatch({ type: 'staffSearchField', payload: true });
				if (!getStaffLoading) {
					loadStaff({
						variables: {
							isActive: true,
						},
					});
				}
				break;
			case 'Officer':
				dispatch({ type: 'officerSearchField', payload: true });
				if (!getOfficerLoading) {
					loadOfficer({
						variables: {
							isActive: true,
						},
					});
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		handlePatronClass(selectedOrder?.patron?.patronClass);
	}, [selectedOrder]);

	const onSubmit = (data: any) => {
		submitQR({
			variables: {
				input: {
					outletID: state?.outletID,
					code:
						state?.tableData?.prefix.concat(state?.tableData?.tableNumber) ??
						'',
				},
			},
		});
	};

	const submitOrder = (data, qrIDSubmit) => {
		if (mode === 'edit') {
			updateOrder({
				variables: {
					input: {
						ID: selectedOrder?.ID,
						qrID: selectedOrder?.qrID
							? selectedOrder?.qrID
							: '00000000-0000-0000-0000-000000000000',
						tableID: tableID,
						outletID: outletID,
						pax: parseFloat(data?.pax),
						patronID: watch('patronID'),
						patronAccountID: changePatronAccountID,
						patronName:
							state?.visitorTextField === true
								? data?.patronName
								: state?.staffSearchField === true
								? `${state?.selectedStaff?.designation} | ${state?.selectedStaff?.name}`
								: state?.officerSearchField === true
								? `${state?.selectedOfficer?.designation} | ${state?.selectedOfficer?.name}`
								: state?.hotelSearchField === true
								? `${
										state?.selectedGuest?.RoomNo
											? `${state?.selectedGuest?.RoomNo} |`
											: ''
								  } ${state?.selectedGuest?.GuestName}`
								: null,
						contactNo: data.contactNo,
						email: data.email,
						registrationID:
							state?.hotelSearchField === true
								? state?.selectedGuest?.RegistrationID
								: null,
					},
				},

				refetchQueries: [
					{
						query: GetHomeTableSummaryDocument,
						variables: { tableID: tableID },
					},
					{
						query: GetTableSubmenuTableDocument,
						variables: {
							outletID: outletID,
							orderByAsc: 'sequence',
							isActive: true,
						},
					},
					{
						query: GetOrderDocument,
						variables: {
							outletID: outletID,
							tableID: tableID,
							status: OrderStatus.Open,
						},
					},
				],
			});
		} else if (mode === 'add') {
			createOrder({
				variables: {
					input: {
						tableID: state?.tableID,
						outletID: state?.outletID,
						qrID: qrIDSubmit
							? qrIDSubmit
							: '00000000-0000-0000-0000-000000000000',
						pax: parseFloat(data.pax),
						patronID: data.patronID,
						mealPeriodID: state?.currentMealPeriod?.ID,
						patronAccountID:
							state?.selectedGuest?.RegistrationID ??
							state?.selectedStaff?.ID ??
							state?.selectedOfficer?.ID,
						patronName:
							state?.visitorTextField === true
								? data?.patronName
								: state?.staffSearchField === true
								? `${state?.selectedStaff?.designation} | ${state?.selectedStaff?.name}`
								: state?.officerSearchField === true
								? `${state?.selectedOfficer?.designation} | ${state?.selectedOfficer?.name}`
								: state?.hotelSearchField === true
								? `${
										state?.selectedGuest?.RoomNo
											? `${state?.selectedGuest?.RoomNo} |`
											: ''
								  } ${state?.selectedGuest?.GuestName}`
								: null,
						contactNo: data.contactNo,
						email: data.email,
						registrationID:
							state?.hotelSearchField === true
								? state?.selectedGuest?.RegistrationID
								: null,
					},
				},
				refetchQueries: [
					{
						query: GetTableDocument,
						variables: { tableID: state?.tableID, outletID: state?.outletID },
					},
				],
			});
		}
	};

	const handlePrinting = () => {
		loadReceiptPrinter({
			variables: {
				outletID: outletID,
				isDefault: true,
			},
		});
	};

	const print = async () => {
		handlePrinting();
	};

	// useEffect
	useEffect(() => {
		if (state?.printerName !== null) {
			handleSubmit(data => submitOrder(data, state?.qrID))();
			printReceiptText(
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				state?.printerName,
				'qr',
				null,
				'new-order',
				null,
				getOutlet[0]?.name,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				`${state?.tableData?.prefix}${state?.tableData?.tableNumber}`,
				user?.name,
				state?.outletID,
				state.qrID,
				user?.accountID,
				getUsersByAccountAndSoftware,
				null,
				null,
			);
		}
	}, [state?.printerName]);

	useEffect(() => {
		if (
			state?.qrID !== '' &&
			getOutletCustomerPolicy[0]?.qrCodeGenerate === true
		) {
			print();
		}
	}, [state?.qrID]);

	useEffect(() => {
		let p = getPatron
			?.filter(x => x?.ID === watch('patronID'))
			?.map(a => a?.patronClass);

		if (p[0] === 'Hotel Guest') {
			if (
				state?.selectedGuest?.RegistrationID === undefined ||
				watch('patronID') === undefined
			) {
				return (
					dispatch({ type: 'disable', payload: true }),
					dispatch({ type: 'selectedOfficer', payload: {} }),
					dispatch({ type: 'selectedStaff', payload: {} })
				);
			} else {
				return dispatch({ type: 'disable', payload: false });
			}
		} else if (p[0] === 'Officer') {
			if (
				state?.selectedOfficer?.ID === undefined ||
				watch('patronID') === undefined
			) {
				return (
					dispatch({ type: 'disable', payload: true }),
					dispatch({ type: 'selectedGuest', payload: {} }),
					dispatch({ type: 'selectedStaff', payload: {} })
				);
			} else {
				return dispatch({ type: 'disable', payload: false });
			}
		} else if (p[0] === 'Staff') {
			if (
				state?.selectedStaff?.ID === undefined ||
				watch('patronID') === undefined
			) {
				return (
					dispatch({ type: 'disable', payload: true }),
					dispatch({ type: 'selectedGuest', payload: {} }),
					dispatch({ type: 'selectedOfficer', payload: {} })
				);
			} else {
				return dispatch({ type: 'disable', payload: false });
			}
		} else if (p[0] === 'Member' || p[0] === 'Visitor') {
			if (watch('patronID') === undefined) {
				return (
					dispatch({ type: 'disable', payload: true }),
					dispatch({ type: 'selectedGuest', payload: {} }),
					dispatch({ type: 'selectedOfficer', payload: {} }),
					dispatch({ type: 'selectedStaff', payload: {} })
				);
			} else {
				return dispatch({ type: 'disable', payload: false });
			}
		}
	}, [
		getPatron,
		watch('patronID'),
		state?.selectedStaff?.ID,
		state?.selectedOfficer?.ID,
		state?.selectedGuest?.RegistrationID,
	]);

	// search
	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	const {
		filteredList: filteredList1,
		handleSearch: handleSearch1,
		setOriginalListing: setOriginalListing1,
	} = useFuseSearch();

	const {
		filteredList: filteredList2,
		handleSearch: handleSearch2,
		setOriginalListing: setOriginalListing2,
	} = useFuseSearch();

	const updateOriginalListing = (data, setter) => {
		if (data && data.length > 0) {
			setter(data);
		}
	};

	const hotelGuest = getMenuXInhouseList?.data?.MenuXInhouseList;

	useEffect(() => updateOriginalListing(hotelGuest, setOriginalListing), [
		hotelGuest?.length,
	]);
	useEffect(() => updateOriginalListing(getStaff, setOriginalListing1), [
		getStaff?.length,
	]);
	useEffect(() => updateOriginalListing(getOfficer, setOriginalListing2), [
		getOfficer?.length,
	]);

	const patronAssign = getPatron
		?.filter(x => x?.ID === watch('patronID'))
		?.map(a => a?.patronClass);

	useEffect(() => {
		if (patronAssign[0] === 'Visitor') {
			return setChangePatronAccountID(null);
		} else if (patronAssign[0] === 'Member') {
			return setChangePatronAccountID(null);
		} else if (patronAssign[0] === 'Hotel Guest') {
			return setChangePatronAccountID(state?.selectedGuest?.RegistrationID);
		} else if (patronAssign[0] === 'Staff') {
			return setChangePatronAccountID(state?.selectedStaff.ID);
		} else if (patronAssign[0] === 'Officer') {
			return setChangePatronAccountID(state?.selectedOfficer.ID);
		}
	});

	const handleSelection = (type, payload) => {
		dispatch({ type, payload });
	};

	const handleSelectedGuest = guest => handleSelection('selectedGuest', guest);
	const handleSelectedStaff = staff => handleSelection('selectedStaff', staff);
	const handleSelectedOfficer = officer =>
		handleSelection('selectedOfficer', officer);

	useEffect(() => {
		if (mode === 'edit') {
			setValue('patronID', watch('patronID'));
		}
	}, [mode]);

	const tableList = getTable?.filter(
		x => x?.status === TableStatus.Available || x?.ID === tableID,
	);

	return (
		<>
			{getCurrentMealPeriodLoading && <Loading />}
			{outletLoading && <Loading />}
			{patronLoading && <Loading />}
			{MenuXInhouseListLoading && <Loading />}
			{getStaffLoading && <Loading />}
			{getOfficerLoading && <Loading />}
			{getOutletCustomerPolicyLoading && <Loading />}
			{receiptPrinterLoading && <Loading />}
			{userLoading && <Loading />}
			{submitQRLoading && <Loading />}
			{createOrderLoading && <Loading />}
			{getTableLoading && <Loading />}

			<MainHeader
				onClick={() => {
					if (
						location.page === 'table-grid' ||
						location.page === 'close-table' ||
						location.page === 'confirm-order'
					) {
						history.push({
							pathname: `/menu/outlet-app/${state?.outletID}/table/grid`,
						});
					} else {
						history.push({
							pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${location?.orderID}/confirm-order`,
						});
					}
				}}
				mainBtn="close"
				smTitle="MenuX"
				title={getOutlet[0]?.name}
				routeSegments={[
					{
						name:
							location.page === 'edit-order-info'
								? `Table ${state?.tableData?.tableNumber}`
								: 'Open Order',
						current: true,
					},
				]}
				rightRouteSegments={[
					{
						name:
							location.page === 'edit-order-info'
								? 'Edit'
								: `Table: ${state?.tableData?.prefix}${state?.tableData?.tableNumber}`,
					},
				]}
			/>

			<ContentWrapper footer>
				<CardContents>
					{mode === 'edit' ? (
						<Controller
							as={
								<TextField select required style={{ marginTop: '7px' }}>
									{tableList?.map(x => (
										<MenuItem
											key={x.ID}
											value={x.ID}
											selected={x?.ID === tableID}
											onClick={e => {
												dispatch({ type: 'selectedTable', payload: x });
												dispatch({
													type: 'transferTableDialog',
													payload: true,
												});
											}}
										>
											{x.prefix}
											{x.tableNumber}
										</MenuItem>
									))}
								</TextField>
							}
							name="tableNumber"
							margin="normal"
							label="Table No."
							fullWidth
							autoComplete="off"
							className="left"
							control={control}
							ref={register}
						/>
					) : (
						<Controller
							as={TextField}
							name="tableNumber"
							label="Table No."
							autoComplete="off"
							disabled
							control={control}
							multiline={true}
							fullWidth
							margin="dense"
							className="left"
							defaultValue={`${state?.tableData?.prefix}${state?.tableData?.tableNumber}`}
						/>
					)}

					<Controller
						as={
							<NumberFormat
								customInput={TextField}
								allowNegative={false}
								fullWidth
								autoComplete="off"
								isNumericString
								fixedDecimalScale
								required
								className="right"
								margin="dense"
							/>
						}
						name="pax"
						label="No. of Pax"
						style={{ marginTop: '10px' }}
						control={control}
						ref={register}
						defaultValue={
							mode === 'add'
								? state?.tableData?.orderInfo
									? state?.tableData?.orderInfo?.totalPax >=
									  state?.tableData?.seatCount
										? 0
										: state?.tableData?.seatCount -
										  state?.tableData?.orderInfo?.totalPax
									: state?.tableData?.seatCount
								: selectedOrder?.pax
						}
						helperText={
							watch('pax')?.toString() !== ''
								? mode === 'add'
									? state?.tableData?.orderInfo
										? Number(watch('pax')) +
												state?.tableData?.orderInfo?.totalPax >
										  state?.tableData?.seatCount
											? 'Exceed total seat count 1'
											: null
										: Number(watch('pax')) > state?.tableData?.seatCount
										? 'Exceed total seat count 2'
										: null
									: Number(watch('pax')) >
									  selectedOrder?.pax +
											(state?.tableData?.seatCount -
												state?.tableData?.orderInfo?.totalPax)
									? 'Exceed total seat count 3'
									: null
								: null
						}
						error={
							watch('pax')?.toString() !== ''
								? mode === 'add'
									? state?.tableData?.orderInfo
										? Number(watch('pax')) +
												state?.tableData?.orderInfo?.totalPax >
										  state?.tableData?.seatCount
											? true
											: false
										: Number(watch('pax')) > state?.tableData?.seatCount
										? true
										: false
									: Number(watch('pax')) >
									  selectedOrder?.pax +
											(state?.tableData?.seatCount -
												state?.tableData?.orderInfo?.totalPax)
									? true
									: false
								: false
						}
					/>

					<FormControl
						component="fieldset"
						margin="dense"
						fullWidth
						required
						style={{ marginBottom: '0px' }}
					>
						<FormLabel component="legend" className="mdDesc">
							Customer Type
						</FormLabel>

						<Controller
							as={
								<RadioGroup
									aria-label="patronType"
									name="patronID"
									onChange={e => {
										register('patronID');
										setValue('patronID', e.target.value);
									}}
									value={mode === 'edit' && watch('patronID')}
								>
									{getPatron?.map((v, index) => {
										return (
											<>
												<FormControlLabel
													key={index}
													value={v.ID}
													name="patronID"
													control={<Radio color="primary" name="patronID" />}
													label={toProperCase(v.patronClass)}
													onClick={() => {
														handlePatronClass(v?.patronClass);
														setValue('patronID', v?.ID);
													}}
												/>

												{v?.patronClass == 'Hotel Guest' &&
												state?.hotelSearchField ? (
													<>
														<TextField
															style={{ marginTop: '5px', marginBottom: '10px' }}
															onChange={e => {
																handleSearch(`'"${e?.target?.value}"`, [
																	`GuestName`,
																	`RoomNo`,
																]);
																dispatch({
																	type: 'hotelState',
																	payload: e.target.value,
																});
															}}
															label="Search Hotel Guest"
															variant="standard"
														></TextField>
													</>
												) : null}

												{v?.patronClass == 'Hotel Guest' &&
												state?.hotelSearchField &&
												state?.hotelState ? (
													<List>
														{filteredList
															?.filter(v => v?.GuestType === 'RoomGuest')
															?.map((x, index) => {
																return (
																	<ListItem
																		key={index}
																		alignItems="flex-start"
																		button
																		onClick={e => {
																			handleSelectedGuest(x);
																		}}
																		selected={
																			state?.selectedGuest?.RegistrationID ===
																			x?.RegistrationID
																		}
																	>
																		<Grid container>
																			<ListItemIcon>
																				<Grid
																					item
																					xs={2}
																					style={{ paddingTop: '5px' }}
																				>
																					<div>
																						<img
																							className="icon-svg"
																							style={{
																								height: '40px',
																								width: '40px',
																								borderRadius: '30px',
																								marginRight: '10px',
																							}}
																							src={ProfileIcon}
																						/>
																					</div>
																					<div>
																						<span
																							className="desc"
																							style={{
																								color: '#000',
																								textAlign: 'center',
																								paddingLeft: '10px',
																							}}
																						>
																							{x?.RoomNo}
																						</span>
																					</div>
																				</Grid>
																			</ListItemIcon>

																			<ListItemText
																				primary={
																					<Grid
																						item
																						xs={10}
																						style={{ paddingLeft: '10px' }}
																					>
																						<div>
																							<span
																								className="desc"
																								style={{ color: '#0000008A' }}
																							>
																								Hotel Guest Info
																							</span>
																						</div>
																						<div>
																							<span>{x?.GuestName}</span>
																						</div>
																						<div>
																							<span className="desc">
																								Credit Balance:
																								<span className="highlight-text">
																									&nbsp;{x?.CreditBalance}
																								</span>
																							</span>
																						</div>
																					</Grid>
																				}
																			/>
																		</Grid>
																	</ListItem>
																);
															})}
													</List>
												) : null}

												{v?.patronClass == 'Staff' &&
												state?.staffSearchField === true ? (
													<>
														<TextField
															style={{
																marginTop: '5px',
																marginBottom: '10px',
															}}
															onChange={e => {
																handleSearch1(`'${e?.target?.value}`, [`name`]);
																dispatch({
																	type: 'staffState',
																	payload: e.target.value,
																});
															}}
															label="Search Staff"
															variant="standard"
														></TextField>
													</>
												) : null}

												{v?.patronClass == 'Staff' &&
												state?.staffSearchField === true &&
												state?.staffState ? (
													<List>
														{filteredList1?.map((staff, index) => (
															<ListItem
																key={index}
																alignItems="flex-start"
																button
																onClick={e => {
																	handleSelectedStaff(staff);
																}}
																selected={state?.selectedStaff.ID === staff?.ID}
															>
																<Grid container>
																	<ListItemIcon>
																		<Grid
																			item
																			xs={2}
																			style={{ paddingTop: '5px' }}
																		>
																			<div>
																				<img
																					className="icon-svg"
																					style={{
																						height: '40px',
																						width: '40px',
																						borderRadius: '30px',
																						marginRight: '10px',
																					}}
																					src={ProfileIcon}
																				/>
																			</div>
																		</Grid>
																	</ListItemIcon>

																	<ListItemText
																		primary={
																			<Grid
																				item
																				xs={10}
																				style={{
																					paddingLeft: '10px',
																				}}
																			>
																				<div>
																					<span
																						className="desc"
																						style={{
																							color: '#0000008A',
																						}}
																					>
																						Staff Info
																					</span>
																				</div>
																				<div>
																					<span>{staff?.name}</span>
																				</div>
																				<div>
																					<span className="desc">
																						{staff?.designation}
																					</span>
																				</div>
																			</Grid>
																		}
																	/>
																</Grid>
															</ListItem>
														))}
													</List>
												) : null}

												{v?.patronClass == 'Officer' &&
												state?.officerSearchField === true ? (
													<>
														<TextField
															style={{
																marginTop: '5px',
																marginBottom: '10px',
															}}
															onChange={e => {
																handleSearch2(`'${e?.target?.value}`, [`name`]);
																dispatch({
																	type: 'officerState',
																	payload: e.target.value,
																});
															}}
															label="Search Officer"
															variant="standard"
														></TextField>
													</>
												) : null}

												{v?.patronClass == 'Officer' &&
												state?.officerSearchField === true &&
												state?.officerState ? (
													<List>
														{filteredList2?.map((officer, index) => (
															<ListItem
																key={index}
																alignItems="flex-start"
																button
																onClick={e => {
																	handleSelectedOfficer(officer);
																}}
																selected={
																	state?.selectedOfficer.ID === officer?.ID
																}
															>
																<Grid container>
																	<ListItemIcon>
																		<Grid
																			item
																			xs={2}
																			style={{ paddingTop: '5px' }}
																		>
																			<div>
																				<img
																					className="icon-svg"
																					style={{
																						height: '40px',
																						width: '40px',
																						borderRadius: '30px',
																						marginRight: '10px',
																					}}
																					src={ProfileIcon}
																				/>
																			</div>
																		</Grid>
																	</ListItemIcon>

																	<ListItemText
																		primary={
																			<Grid
																				item
																				xs={10}
																				style={{
																					paddingLeft: '10px',
																				}}
																			>
																				<div>
																					<span
																						className="desc"
																						style={{
																							color: '#0000008A',
																						}}
																					>
																						Officer Info
																					</span>
																				</div>
																				<div>
																					<span>{officer?.name}</span>
																				</div>
																				<div>
																					<span className="desc">
																						{officer?.designation}
																					</span>
																				</div>
																			</Grid>
																		}
																	/>
																</Grid>
															</ListItem>
														))}
													</List>
												) : null}
											</>
										);
									})}
								</RadioGroup>
							}
							name="patronID"
							control={control}
							ref={register}
						/>

						{state?.visitorTextField && (
							<>
								<Controller
									as={TextField}
									name="patronName"
									label={'Name'}
									autoComplete="off"
									control={control}
									multiline={true}
									fullWidth
									margin="dense"
									ref={register}
									helperText={errors?.patronName?.message}
									error={errors?.patronName ? true : false}
								/>

								<Controller
									as={TextField}
									name="email"
									label={'Email'}
									autoComplete="off"
									control={control}
									multiline={true}
									fullWidth
									margin="dense"
									ref={register}
									style={{ marginBottom: '30px' }}
									helperText={errors?.email?.message}
									error={errors?.email ? true : false}
								/>

								<Controller
									as={
										<PhoneNumInput
											country={state?.countryCode}
											label={'Contact No.'}
											fullWidth
											inputProps={{
												style: {
													borderColor: errors?.contactNo ? '#E53935' : null,
												},
											}}
										/>
									}
									customInput={TextField}
									name="contactNo"
									required
									marginTop
									marginBottom
									type="tel"
									autoComplete="off"
									control={control}
									ref={register}
									helperText={errors?.contactNo?.message}
									error={errors?.contactNo ? true : false}
								/>

								<div style={{ marginTop: '15px' }} />
							</>
						)}
					</FormControl>
				</CardContents>
			</ContentWrapper>

			<DynamicFooter
				options={[
					{
						name: 'Cancel',
						onClick: () => {
							history.push(`/menu/outlet-app/${state?.outletID}/table/grid`);
						},
						color: 'primary',
						props: {
							type: 'submit',
						},
					},
					{
						name: mode === 'edit' ? 'Confirm' : 'Next',
						onClick: () => {
							if (getOutletCustomerPolicy[0]?.qrCodeGenerate === true) {
								handleSubmit(onSubmit)();
							} else if (getOutletCustomerPolicy[0]?.qrCodeGenerate === false) {
								handleSubmit(data => submitOrder(data, state?.qrID))();
							}
						},
						color:
							watch('pax')?.toString() !== ''
								? mode === 'add'
									? state?.tableData?.orderInfo
										? Number(watch('pax')) +
												state?.tableData?.orderInfo?.totalPax >
										  state?.tableData?.seatCount
											? 'inherit'
											: 'primary'
										: Number(watch('pax')) > state?.tableData?.seatCount
										? 'inherit'
										: 'primary'
									: Number(watch('pax')) >
									  selectedOrder?.pax +
											(state?.tableData?.seatCount -
												state?.tableData?.orderInfo?.totalPax)
									? 'inherit'
									: 'primary'
								: 'inherit',

						props: {
							type: 'submit',
						},
						disabled:
							watch('pax')?.toString() !== ''
								? mode === 'add'
									? state?.tableData?.orderInfo
										? Number(watch('pax')) +
												state?.tableData?.orderInfo?.totalPax >
										  state?.tableData?.seatCount
											? true
											: false
										: Number(watch('pax')) > state?.tableData?.seatCount
										? true
										: false
									: Number(watch('pax')) >
									  selectedOrder?.pax +
											(state?.tableData?.seatCount -
												state?.tableData?.orderInfo?.totalPax)
									? true
									: false
								: true,
					},
				]}
			/>

			<TransferTableDialog
				state={state}
				dispatch={dispatch}
				currentTable={`${state?.tableData?.prefix}${state?.tableData?.tableNumber}`}
				orderID={selectedOrder?.ID}
				setOpenSnackBar={setOpenSnackBar}
				setSnackBarMsg={setSnackBarMsg}
				orders={location?.orders}
			/>
		</>
	);
};
