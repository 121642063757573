import { amtStr } from 'helpers/numFormatter';
import React from 'react';

export const AmountFooter = (props: any) => {
	const {
		style,
		currencyCode,
		subtotal,
		discount,
		serviceTax,
		total,
		rounding,
		page,
	} = props;

	const divChild = (info1: any, info2: any) => {
		return (
			<div className={'mdDesc amount-footer-child'}>
				<span>{info1}</span>
				<span className="right-info">{info2}</span>
			</div>
		);
	};

	return (
		<>
			<div className={'amount-footer'} style={{ ...style }}>
				<div
					style={{ fontWeight: 'bold', textDecoration: 'underline' }}
					className={'xsTitle'}
				>
					Ordering Summary:
				</div>

				<div style={{ paddingBottom: '10px' }} />

				{divChild('Total', currencyCode + amtStr(subtotal))}
				{divChild('Discount', `(${currencyCode}${amtStr(discount)})`)}

				{page === 'payment'
					? divChild(
							'Rounding',
							rounding < 0
								? `(${currencyCode}${amtStr(rounding)})`
								: `${currencyCode}${amtStr(rounding)}`,
					  )
					: null}

				<hr className="amount-line" />

				<div style={{ paddingBottom: '10px' }} />

				<div style={{ marginBottom: '7px' }} className={'xsTitle'}>
					<span>Total Payable</span>
					<span className="right-info">{currencyCode + amtStr(total)}</span>
				</div>

				{divChild('Service Tax', currencyCode + amtStr(serviceTax))}
			</div>
		</>
	);
};
