import React from 'react';
import { ConfirmOrder } from './ConfirmOrder';

const confirmOrderRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/order/:orderID/confirm-order`,
		},
		component: <ConfirmOrder />,
	},
];

export default confirmOrderRoutes;
