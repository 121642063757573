import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { IAction } from 'helpers/model';
import React, {
	Reducer,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import { useHistory, useParams } from 'react-router';
import { formatTime } from 'helpers/hooks/formatTime';
import {
	CommonStatus,
	CreditCardType,
	GetAllOrderItemDocument,
	GetOrderItemDocument,
	OrderItemDiscountStatus,
	OrderItemStatus,
	OrderStatus,
	PaymentClass,
	useCreateOnlinePaymentMutation,
	useCreatePaymentByQuantityMutation,
	useGetAllOrderItemLazyQuery,
	useGetCurrentMealPeriodLazyQuery,
	useGetDefaultPaymentQuery,
	useGetDiscountQuery,
	useGetHomeTableSummaryLazyQuery,
	useGetOrderLazyQuery,
	useGetOutletNameQuery,
	useGetReceiptPrinterLazyQuery,
	useGetRoundingPolicyQuery,
	useGetTaxSchemeDetailQuery,
	useGetUsersByAccountAndSoftwareQuery,
	useGetVoidedDiscountQuery,
} from 'generated/graphql';
import {
	FooterAmount,
	generateRoundingAmount,
	NameByCustomerType,
} from '../SharedFunction';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import AuditIcon from 'assets/icons/audit.svg';
import { amtNumStr, amtStr } from 'helpers/numFormatter';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import {
	Grid,
	List,
	ListItem,
	ListItemText,
	useMediaQuery,
} from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { AmountFooter } from '../AmountFooter';
import AppContext from 'containers/App/Store/AppContext';
import theme from '@ifca-root/react-component/src/assets/theme';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import { PaymentDialog, SignatureDialog } from './CustomDialog';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'helpers/SystemMsg';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import EditIcon from '@material-ui/icons/Edit';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import {
	StyledMenu,
	StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu';
import { CloseTableDialog } from '../CloseTable/CloseTableDialog';
import { CloseOrderDialog } from '../CloseTable/CloseOrderDialog';
import { printReceiptText } from 'containers/OutletModule/Home/TableSubmenu/BillSettlement/BillReceipt/BillReceiptFunctions';
import * as htmlToImage from 'html-to-image';

let paymentUrl;
switch (process.env.REACT_APP_ENV) {
	case 'swan':
		paymentUrl = `https://swan.menux.asia`;
		break;
	case 'swanuat':
		paymentUrl = `https://swan-uat.menux.asia`;
		break;
	case 'promenade':
		paymentUrl = `https://promenade.menux.asia`;
		break;
	case 'promenade-staging':
		paymentUrl = `https://promenade-uat.menux.asia`;
		break;
	case 'imperial':
		paymentUrl = `https://imperial.menux.asia`;
		break;
	case 'imperial-staging':
		paymentUrl = `https://imperial-uat.menux.asia`;
		break;
	case 'edu':
		paymentUrl = `https://edu.menux.asia`;
		break;
	case 'local':
		paymentUrl = `http://localhost:3250`;
		break;
	case 'dev':
		paymentUrl = `https://posx-dev-outlet.ifca.io`;
		break;
	case 'uat':
		paymentUrl = `https://menux-uat-outlet.menux.asia`;
		break;
	default:
		paymentUrl = `https://outlet.menux.asia`;
		break;
}

interface PaymentItemProps {
	key?: typeof String;
	receivedAmount: any;
	cardType: String;
	cardNo: Number;
	referenceNo: String;
	remark: String;
	patron: any;
}

interface Props {
	outletID: any;
	tableID: any;
	orderID: any;
	currentMealPeriod: any;
	negOrderItem: any;
	itemArray: any;
	selectedOrder: any;
	paymentDialog: boolean;
	signatureDialog: boolean;
	receivedAmount: number;
	changeAmount: number;
	radioError: boolean;
	disablePaymentClass: any;
	minimumValue: number;
	lastArray: any;
	closeTableDialog: boolean;
	closeOrderDialog: boolean;
}

export const Payment = () => {
	const history = useHistory();
	const { tableID, outletID, orderID } = useParams<Record<string, any>>();

	const initialState: Props = {
		outletID: outletID,
		tableID: tableID,
		orderID: orderID,
		currentMealPeriod: null,
		negOrderItem: {},
		itemArray: [],
		selectedOrder: null,
		paymentDialog: false,
		signatureDialog: false,
		receivedAmount: 0,
		changeAmount: 0,
		radioError: false,
		disablePaymentClass: '',
		minimumValue: 0,
		lastArray: [],
		closeTableDialog: false,
		closeOrderDialog: false,
	};

	const reducer: Reducer<Props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [state, dispatch] = useReducer(reducer, initialState);

	const [selectedRoom, setSelectedRoom] = useState({} as any);
	const [selectedNonGuest, setSelectedNonGuest] = useState({} as any);
	const [selectedFolio, setSelectedFolio] = useState({} as any);
	const [selectedOfficer, setSelectedOfficer] = useState({} as any);
	const [selectedStaff, setSelectedStaff] = useState({} as any);
	const [selectedDebtor, setSelectedDebtor] = useState({} as any);
	const [selectedCredit, setSelectedCredit] = useState({} as any);
	const [defaultRoom, setDefaultRoom] = useState({} as any);
	const [defaultNonGuest, setDefaultNonGuest] = useState({} as any);
	const [defaultFolio, setDefaultFolio] = useState({} as any);
	const [defaultDebtor, setDefaultDebtor] = useState({} as any);
	const [defaultOfficer, setDefaultOfficer] = useState({} as any);
	const [defaultStaff, setDefaultStaff] = useState({} as any);
	const [selectedOrderItems, setSelectedOrderItems] = useState({});

	const yupSchema = yup.object().shape({});

	const {
		register,
		control,
		handleSubmit,
		errors,
		clearErrors,
		watch,
		setValue,
		getValues,
		reset,
	} = useForm<PaymentItemProps>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const [setVariables, name] = NameByCustomerType();
	const [hotelGuestInfo, setHotelGuestInfo] = useState({} as any);
	const [baseImage, setBaseImage] = useState({} as any);

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const { globalState }: any = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	// API
	const {
		loading: outletLoading,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
			loadHomeTableSummary({
				variables: {
					tableID: tableID,
				},
			});
			LoadOrder({
				variables: {
					tableID: state?.tableID,
					status: OrderStatus.Open,
					outletID: state?.outletID,
				},
			});

			var xhr = new XMLHttpRequest();
			xhr.open('GET', `${getOutlet[0]?.logo?.fileURL}`);
			xhr.responseType = 'blob';
			xhr.send();

			var reader = new FileReader();
			xhr.addEventListener('load', function() {
				reader.readAsDataURL(xhr.response);
				reader.addEventListener('loadend', function() {
					setBaseImage(reader.result);
				});
			});
		},
	});

	const [
		LoadOrder,
		{ data: { getOrder } = { getOrder: [] }, loading: orderLoading },
	] = useGetOrderLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOrder }) => {
			dispatch({
				type: 'selectedOrder',
				payload: getOrder?.filter(order => order?.ID === state?.orderID)[0],
			});
			setVariables({
				order: getOrder?.filter(order => order?.ID === state?.orderID)[0],
				outletID: state?.outletID,
				hotelID: getOutlet[0]?.outletChannel?.hotelID,
			});
		},
	});

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			dispatch({
				type: 'currentMealPeriod',
				payload: getCurrentMealPeriod,
			});
		},
	});

	const [
		loadHomeTableSummary,
		{ loading: homeTableSummaryLoading, data: homeTableSummaryData },
	] = useGetHomeTableSummaryLazyQuery({
		fetchPolicy: 'network-only',
	});

	const {
		data: { getRoundingPolicy } = { getRoundingPolicy: [] },
		loading: getRoundingPolicyLoading,
	} = useGetRoundingPolicyQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	const [
		LoadOrderItem,
		{
			loading: useGetAllOrderItemLoading,
			called: useGetAllOrderItemCalled,
			data: { getAllOrderItem } = { getAllOrderItem: [] },
		},
	] = useGetAllOrderItemLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getAllOrderItem }) => {
			dispatch({
				type: 'partialPaid',
				payload:
					getAllOrderItem?.filter(item => item?.billID !== null)?.length !== 0,
			});
			dispatch({ type: 'selectedOrder', payload: getAllOrderItem[0]?.order });

			let unpaidOrderItem = getAllOrderItem?.filter(
				item => item?.billID === null,
			);
			let temp = {};

			unpaidOrderItem?.map(el => {
				let totalTax = 0;
				el?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				const activeOID = el?.orderItemDiscount?.filter(
					i => i?.status === OrderItemDiscountStatus?.Active,
				)[0];

				let totalOIDTax = 0;
				activeOID?.orderItemDiscountTax?.map(v => {
					totalOIDTax += v?.taxAmount;
				});

				let totalODITax = 0;
				el?.orderDiscountItem?.orderDiscountItemTax?.map(v => {
					totalODITax += v?.taxAmount;
				});

				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID].quantity += el?.quantity * -1;
						temp[el?.refID].amount += el?.amount;
						temp[el?.refID].taxAmount += totalTax;

						temp[el?.refID].orderItemDiscBaseAmount += activeOID?.baseAmount;
						temp[el?.refID].orderItemDiscTaxAmount += totalOIDTax;

						temp[el?.refID].orderDiscountItemBaseAmount +=
							el?.orderDiscountItem?.baseAmount;
						temp[el?.refID].orderDiscountItemTaxAmount += totalODITax;
					} else {
						temp[el?.refID] = {
							quantity: el?.quantity * -1,
							amount: el?.amount * -1,
							taxAmount: totalTax * -1,

							orderItemDiscBaseAmount:
								isNaN(activeOID?.baseAmount * -1) === true
									? 0
									: activeOID?.baseAmount * -1,
							orderItemDiscTaxAmount:
								isNaN(totalOIDTax * -1) === true ? 0 : totalOIDTax * -1,

							orderDiscountItemBaseAmount:
								isNaN(el?.orderDiscountItem?.baseAmount * -1) === true
									? 0
									: el?.orderDiscountItem?.baseAmount * -1,
							orderDiscountItemTaxAmount:
								isNaN(totalODITax * -1) === true ? 0 : totalODITax * -1,

							orderItemOptions: [],
						};

						// Loop through the orderItemOptionArray
						for (let i = 0; i < el?.orderItemOption?.length; i++) {
							const option = el?.orderItemOption[i];
							const optionItem = el?.orderItemOption[i]?.orderItemOptionItem;

							let optionData;

							if (optionItem) {
								optionItem?.map(x => {
									let totalTax = 0;
									x?.orderItemOptionItemTax?.map(v => {
										totalTax += v?.taxAmount;
									});

									let totalOptItemOIDTax = 0;
									x?.orderItemOptionItemDiscountTax?.map(v => {
										totalOptItemOIDTax += v?.taxAmount;
									});

									let totalOptItemODITax = 0;
									x?.orderDiscountItemOptionTax?.map(v => {
										totalOptItemODITax += v?.taxAmount;
									});

									optionData = {
										optionID: option?.optionID,
										orderItemOptionItem: {
											basePrice: x?.basePrice * -1,
											taxAmount: totalTax * -1,
											discountBaseAmount: x?.discountBaseAmount * -1,
											itemDiscountTaxAmount: totalOptItemOIDTax * -1,
											discountItemTaxAmount: totalOptItemODITax * -1,
										},
									};
								});
								temp[el?.refID].orderItemOptions.push(optionData);
							}
						}
					}
				}
			});

			const filtered = unpaidOrderItem
				?.map(el => {
					if (el?.quantity - temp[el?.ID]?.quantity === 0) {
						return { ...el, void: true };
					} else {
						return { ...el, void: false };
					}
				})
				?.filter(list => list.void === false);

			dispatch({ type: 'negOrderItem', payload: { ...temp } });
			dispatch({ type: 'itemArray', payload: filtered });
		},
	});

	const {
		loading: getDiscountLoading,
		data: { getDiscount } = { getDiscount: [] },
	} = useGetDiscountQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
	});

	const {
		loading: paymentLoading,
		data: { getDefaultPayment } = { getDefaultPayment: [] },
	} = useGetDefaultPaymentQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: state?.outletID, orderByAsc: 'paymentClass' },
	});

	const {
		data: { getVoidedDiscount } = { getVoidedDiscount: [] },
		loading: voidedDiscountLoading,
	} = useGetVoidedDiscountQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderID: orderID,
			outletID: outletID,
		},
		onError: error => {
			console.log('ERROR', error);
		},
	});

	const {
		data: { getTaxSchemeDetail } = {
			getTaxSchemeDetail: null,
		},
		loading: getTaxSchemeDetailLoading,
	} = useGetTaxSchemeDetailQuery({
		fetchPolicy: 'no-cache',
		variables: {
			accountID: user?.accountID,
			taxSchemeID: getOutlet[0]?.taxSchemeID,
			orderID: state?.selectedOrder?.ID,
		},
	});

	const {
		loading: userLoading,
		error,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			status: CommonStatus.Active,
		},
	});

	const [
		loadReceiptPrinter,
		{
			loading: receiptPrinterLoading,
			called: receiptPrinterCalled,
			data: { getReceiptPrinter } = { getReceiptPrinter: [] },
		},
	] = useGetReceiptPrinterLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getReceiptPrinter }) => {
			if (getReceiptPrinter?.length > 0) {
				printReceiptText(
					baseImage,
					getOutlet[0],
					getOrder[0],
					'bill-settlement',
					state?.itemArray,
					state?.negOrderItem,
					footerAmount,
					getDiscount,
					null,
					null,
					null,
					getOutlet[0]?.currencyCode ?? '',
					null,
					state?.selectedOrder?.patronName,
					getReceiptPrinter[0]?.name,
					'bill-settlement',
					false,
					'bill-receipt',
					getTaxSchemeDetail,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					null,
					getUsersByAccountAndSoftware,
					null,
					null,
				);
			} else if (getReceiptPrinter?.length === 0) {
				localPrinter();
			}
		},
	});

	const [
		createOnlinePayment,
		{ loading: createOnlinePaymentLoading },
	] = useCreateOnlinePaymentMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: ({ createOnlinePayment }) => {},
	});

	const [
		createPaymentByQuantity,
		{ loading: paymentQuantityLoading },
	] = useCreatePaymentByQuantityMutation({
		onError: error => {
			console.log('ERROR', error?.message);
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
		},
		onCompleted: ({ paymentByQuantity }) => {
			history.push(
				`/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${state?.selectedOrder?.ID}/bill-settlement/${paymentByQuantity?.ID}`,
			);
			dispatch({ type: 'radioError', payload: false });
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	// useEffect
	useEffect(() => {
		if (!useGetAllOrderItemCalled) {
			LoadOrderItem({
				variables: {
					orderID: state?.orderID,
					outletID: state?.outletID,
				},
			});
		}
	}, [useGetAllOrderItemCalled]);

	useEffect(() => {
		LoadOrderItem({
			variables: {
				orderID: state?.orderID,
				outletID: state?.outletID,
			},
		});
	}, [state?.selectedOrder?.ID]);

	useEffect(() => {
		if (selectedRoom?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedRoom);
		} else if (selectedNonGuest?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedNonGuest);
		} else if (selectedDebtor?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedDebtor);
		} else if (selectedFolio?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedFolio);
		} else if (defaultRoom?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultRoom);
		} else if (defaultNonGuest?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultNonGuest);
		} else if (defaultDebtor?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultDebtor);
		} else if (defaultFolio?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultFolio);
		} else if (selectedCredit?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedCredit);
		}
	}, [
		selectedRoom,
		defaultRoom,
		selectedNonGuest,
		defaultNonGuest,
		defaultDebtor,
		selectedDebtor,
		defaultFolio,
		selectedFolio,
		selectedCredit,
	]);

	const closeAmount = homeTableSummaryData?.close?.closeAmount;
	const footerAmount = FooterAmount(
		getAllOrderItem,
		'payment',
		getRoundingPolicy,
	);

	useEffect(() => {
		if (footerAmount?.total <= 0) {
			if (closeAmount <= 0) {
				dispatch({ type: 'closeTableDialog', payload: true });
				dispatch({ type: 'closeOrderDialog', payload: false });
			} else if (closeAmount > 0) {
				dispatch({ type: 'closeOrderDialog', payload: true });
				dispatch({ type: 'closeTableDialog', payload: false });
			} else {
				dispatch({ type: 'closeTableDialog', payload: false });
				dispatch({ type: 'closeOrderDialog', payload: false });
			}
		} else {
			dispatch({ type: 'closeTableDialog', payload: false });
			dispatch({ type: 'closeOrderDialog', payload: false });
		}
	}, [closeAmount, footerAmount?.total]);

	// function
	const creditCardEnum = data => {
		let credClass;
		if (data === 'American Express Card') {
			credClass = CreditCardType.AmericanExpressCard;
		} else if (data === 'Diners Card') {
			credClass = CreditCardType.DinersCard;
		} else if (data === 'JCB Card') {
			credClass = CreditCardType.JcbCard;
		} else if (data === 'Master Card') {
			credClass = CreditCardType.Mastercard;
		} else if (data === 'UNION PAY') {
			credClass = CreditCardType.UnionpayCard;
		} else if (data === 'Visa Card') {
			credClass = CreditCardType.VisaCard;
		} else if (data === 'Debit Card') {
			credClass = CreditCardType.DebitCard;
		} else if (data === 'E-Wallet') {
			credClass = CreditCardType.Ewallet;
		}
		return credClass;
	};

	const saveRounding = (defaultPayment, v, index, lastIndex) => {
		let roundingAmount;
		const gotCash =
			defaultPayment?.filter(x => x?.paymentClass === PaymentClass?.Cash)
				?.length > 0;

		if (gotCash && v?.paymentClass === PaymentClass.Cash) {
			roundingAmount = roundingAmount;
		}

		if (gotCash === false && index === lastIndex) {
			roundingAmount = roundingAmount;
		}

		return roundingAmount;
	};

	const selectedPaymentClass = () => {
		const name = getDefaultPayment?.filter(
			x => x?.ID === watch('defaultPaymentID'),
		)[0]?.paymentClass;
		return name;
	};

	const getSelectedOrderItemIDs = () => {
		let temp = state?.itemArray.filter(v => v?.quantity > 0);

		return temp?.map(x => {
			return {
				orderItemID: x?.ID,
				paymentQuantity: x?.quantity,
			};
		});
	};

	let cardType: any = watch('cardType');
	let paymentInputArr = [];
	const onSubmit = async (data: any, files?) => {
		const ref = state?.selectedOrder?.docNo;
		let billInput;
		let totalReceiveAmount = 0;

		const lastIndex =
			data?.defaultPayment?.filter(x => x?.selected === true)?.length - 1;

		data?.defaultPayment
			?.filter(x => x?.selected === true)
			?.map((v, index) => {
				billInput = {
					orderID: state?.selectedOrder?.ID,
					outletID: outletID,
					...(v?.paymentClass === PaymentClass.Room ||
					v?.paymentClass === PaymentClass.Member ||
					v?.paymentClass === PaymentClass.NonGuest ||
					v?.paymentClass === PaymentClass.Debtor ||
					v?.paymentClass === PaymentClass.SuspendFolio
						? { file: files }
						: null),
					...(((v?.paymentClass === PaymentClass.Debtor ||
						v?.paymentClass === PaymentClass.Credit) &&
						(selectedDebtor?.IsIndividual === true ||
							selectedCredit?.IsIndividual === true)) === true
						? {
								einvoice_buyer_name:
									selectedDebtor?.DebtorName ?? selectedCredit?.DebtorName,
								einvoice_buyer_tin: selectedDebtor?.Tin ?? selectedCredit?.Tin,
								einvoice_buyer_regno:
									selectedDebtor?.CompanyRegNo ?? selectedCredit?.CompanyRegNo,
								einvoice_buyer_address:
									selectedDebtor?.Address ?? selectedCredit?.Address,
								einvoice_buyer_post_code:
									selectedDebtor?.postCode ?? selectedCredit?.postCode,
								einvoice_buyer_city:
									selectedDebtor?.City ?? selectedCredit?.City,
								einvoice_buyer_state:
									selectedDebtor?.State ?? selectedCredit?.State,
								einvoice_buyer_country:
									selectedDebtor?.Country ?? selectedCredit?.Country,
								einvoice_buyer_contact_no:
									selectedDebtor?.ContactNo ?? selectedCredit?.ContactNo,
								einvoice_buyer_identity_type:
									selectedDebtor?.InvoiceType ?? selectedCredit?.InvoiceType,
								einvoice_buyer_email:
									selectedDebtor?.Email ?? selectedCredit?.Email,
								einvoice_directsend: true,
						  }
						: null),
				};

				let paymentInput = {
					outletID: outletID,
					amount:
						v?.paymentClass === PaymentClass?.Cash
							? state?.receivedAmount
							: v?.amountPayment,
					creditCardType:
						cardType?.GuestType !== null && cardType?.GuestType !== undefined
							? creditCardEnum(cardType?.GuestType)
							: cardType,
					cardNo: v?.cardRef,
					changeAmount:
						v?.paymentClass === PaymentClass?.Cash
							? state?.changeAmount >= 0
								? Math.round(
										(Math.abs(state?.changeAmount) + Number.EPSILON) * 100,
								  ) / 100
								: 0
							: 0,
					receivedAmount:
						v?.paymentClass === PaymentClass?.Cash
							? state?.receivedAmount !== 0
								? state?.receivedAmount
								: v?.amountPayment
							: 0,
					roundingAmount:
						saveRounding(data?.defaultPayment, v, index, lastIndex) ?? 0,
					defaultPaymentID: v?.defaultPaymentID,
					paymentClass: v?.paymentClass,
					patronAccountID:
						selectedOfficer?.ID !== null ||
						selectedOfficer?.ID !== undefined ||
						selectedStaff?.ID !== null ||
						selectedStaff?.ID !== undefined
							? selectedOfficer?.ID ?? selectedStaff?.ID
							: defaultOfficer?.ID ?? defaultStaff?.ID,
					registrationID:
						v?.paymentClass === PaymentClass?.Room
							? selectedRoom?.RegistrationID ?? defaultRoom?.RegistrationID
							: v?.paymentClass === PaymentClass?.NonGuest
							? selectedNonGuest?.RegistrationID ??
							  defaultNonGuest?.RegistrationID
							: v?.paymentClass === PaymentClass?.SuspendFolio
							? selectedFolio?.RegistrationID ?? defaultFolio?.RegistrationID
							: v?.paymentClass === PaymentClass?.Debtor
							? selectedDebtor?.RegistrationID ?? defaultDebtor?.RegistrationID
							: cardType?.RegistrationID,

					hotelGuestInfo: {
						BookingNo: hotelGuestInfo?.BookingNo,
						FullName: hotelGuestInfo?.GuestName,
						RoomNo: hotelGuestInfo?.RoomNo,
						GuestType: hotelGuestInfo?.GuestType,
						ArrivalDate: hotelGuestInfo?.ArrivalDate,
						DepartureDate: hotelGuestInfo?.DepartureDate,
						DebtorAccount: hotelGuestInfo?.DebtorAccount,
						CompanyName: hotelGuestInfo?.CompanyName,
					},
					referenceNo: v?.referenceNo,
					remark: v?.remark,
					billLedgerInput: {
						HotelID: getOutlet[0]?.outletChannel?.hotelID,
						RegistrationID:
							selectedRoom?.RegistrationID ??
							selectedNonGuest?.RegistrationID ??
							selectedFolio?.RegistrationID ??
							defaultRoom?.RegistrationID ??
							defaultNonGuest?.RegistrationID ??
							defaultFolio?.RegistrationID,
						OutletCode: getOutlet[0]?.outletCode,
						IncidentalChargeID: '',
						Reason:
							v?.paymentClass === PaymentClass?.Room
								? 'MenuX - Charge To Room'
								: v?.paymentClass === PaymentClass?.NonGuest
								? 'MenuX - Charge To Non Guest'
								: v?.paymentClass === PaymentClass?.SuspendFolio
								? 'MenuX - Charge To Suspend Folio'
								: v?.paymentClass === PaymentClass?.Debtor
								? 'MenuX - Charge To Debtor'
								: v?.paymentClass === PaymentClass?.Credit
								? 'MenuX - Charge To Credit'
								: '',
						Description: `${getOutlet[0]?.outletCode} -  ${ref}`,
						TrxDate: new Date(),
						Qty: 1,
						UnitPrice: parseFloat(amtNumStr(v?.amountPayment)),
						TrxAmount: parseFloat(amtNumStr(v?.amountPayment)),
						BaseAmount: parseFloat(amtNumStr(v?.amountPayment)),
						ServiceCharge: parseFloat(amtNumStr(0)),
						TaxAmount: parseFloat(amtNumStr(0)),
						DiscountAmount: 0,
						IsChargeToRoom: true,
						ReferenceNo: ref,
						CreatedBy: user?.ID,
					},
					scheduleBillingInput: {
						HotelID: getOutlet[0]?.outletChannel?.hotelID,
						BillNo: ref,
						CardNo: v?.cardRef,
						BillDate: new Date(),
						BillAmount: v?.amountPayment,
						DebtorID:
							v?.paymentClass === PaymentClass?.Credit
								? cardType?.RegistrationID
								: v?.registrationID,
						CreatedBy: user?.ID,
					},
				};
				paymentInputArr?.push(paymentInput);
			});

		paymentInputArr?.map(x => {
			totalReceiveAmount += Number(
				x?.paymentClass === PaymentClass.Cash ? x?.receivedAmount : x?.amount,
			);
		});

		dispatch({ type: 'lastArray', payload: paymentInputArr });

		// setBillInput(billInput);
		// setPaymentInput(paymentInput);

		if (selectedPaymentClass() === PaymentClass.Online) {
			createOnlinePayment({
				variables: {
					redirectUrl: `${paymentUrl}/menu/outlet-app/bill-settlement/online-payment/redirect`,
					payAmount: footerAmount?.total,
					detail: 'Detail',
					title: `Online Payment`,
					outletID: outletID,
				},
			}).then(i => {
				if (i?.data?.createOnlinePayment?.code === 'SUCCESS') {
					localStorage.setItem(
						'checkoutId',
						i?.data?.createOnlinePayment?.item?.checkoutId,
					);
					localStorage.setItem(
						'paymentInfo',
						JSON.stringify({
							paymentByQuantityInput: getSelectedOrderItemIDs(),
							billInput: billInput,
							paymentInput: paymentInputArr,
							tableID: tableID,
						}),
					);
					window.location.assign(i?.data?.createOnlinePayment?.item?.url);
					setOpenSnackBar(true);
					setSnackBarMsg(SystemMsgs.gatewayRedirect());
				} else {
					i?.data?.createOnlinePayment?.error?.code !== ''
						? setSnackBarMsg(i.data.createOnlinePayment?.error.message)
						: setSnackBarMsg(SystemMsgs.paymentFailed());
					setOpenSnackBar(true);
				}
			});
		} else {
			createPaymentByQuantity({
				variables: {
					paymentByQuantityInput: getSelectedOrderItemIDs(),
					billInput,
					paymentInput: paymentInputArr,
				},
				refetchQueries: [
					{
						query: GetOrderItemDocument,
						variables: {
							orderID: state?.selectedOrder?.ID,
							status: OrderItemStatus.Confirmed,
						},
					},
					{
						query: GetAllOrderItemDocument,
						variables: { orderID: state?.selectedOrder?.ID },
					},
				],
			});
		}
	};

	const handleChangeOrder = (e, obj) => {
		if (obj?.ID !== state?.orderID) {
			dispatch({
				type: 'selectedOrder',
				payload: getOrder?.filter(order => order?.ID === obj?.ID)[0],
			});
			dispatch({ type: 'orderID', payload: obj?.ID });
			history.push({
				pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/order/${state?.orderID}/payment`,
			});
		}
	};

	const handlePrinting = () => {
		loadReceiptPrinter({
			variables: {
				outletID: outletID,
				isDefault: true,
			},
		});
	};

	const localPrinter = () => {
		let temp = document.getElementById('share-receipt');

		htmlToImage
			.toPng(temp, {
				style: { background: '#ffffff' },
				cacheBust: true,
			})
			.then(x => {
				const win = window.open('', '');
				win.document.write('<html><head>');
				win.document.write('</head><body >');
				win.document.write('<img id="print-image-element" src="' + x + '"/>');
				win.document.write(
					'<script>var img = document.getElementById("print-image-element"); img.addEventListener("load",function(){ window.focus(); window.print(); window.document.close(); window.close(); }); </script>',
				);
				win.document.write('</body></html>');
				win.document?.close();
				setTimeout(() => {
					win.window.print();
				}, 100);
			})
			.catch(error => {
				console.error('Printing failed', error);
			});
	};

	// const
	const currencyCode = getOutlet[0]?.currencyCode ?? '';
	const itemCount = homeTableSummaryData?.orderItemCount?.orderItem;
	const discountAmount =
		amtStr(
			Number(
				homeTableSummaryData?.billItemDiscount?.discountAmount || 0,
			).toFixed(2),
		) ?? 0;
	let roundingAmount =
		getRoundingPolicy[0]?.inUse === true
			? generateRoundingAmount(
					homeTableSummaryData?.close?.closeAmount,
					getRoundingPolicy[0]?.smallestDenomination,
					getRoundingPolicy,
			  )
			: 0;
	const totalAmount = amtStr(
		homeTableSummaryData?.orderItemCount?.totalAmount + roundingAmount,
	);
	const voidItemCount = homeTableSummaryData?.orderItemCount?.voidItem;
	const voidDiscountAmount = amtStr(
		getVoidedDiscount.reduce((sum, item) => sum + parseFloat(item?.amount), 0),
	);

	return (
		<>
			{outletLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			{homeTableSummaryLoading && <Loading />}
			{getRoundingPolicyLoading && <Loading />}
			{useGetAllOrderItemLoading && <Loading />}
			{getDiscountLoading && <Loading />}
			{paymentLoading && <Loading />}
			{createOnlinePaymentLoading && <Loading />}
			{paymentQuantityLoading && <Loading />}
			{orderLoading && <Loading />}

			<MainHeader
				onClick={() => {
					history.push({
						pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/order/${state?.orderID}/confirm-order`,
					});
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Payment', current: true },
				]}
				rightRouteSegments={[
					{
						name: state?.currentMealPeriod
							? `${state?.currentMealPeriod?.name}: ${formatTime(
									state?.currentMealPeriod?.startTime,
							  )} - ${formatTime(state?.currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>

			<DynamicSubHeader
				title={
					(state?.selectedOrder !== null ||
						state?.selectedOrder !== undefined) && (
						<>
							<span className="highlight" style={{ color: '#ff9800' }}>
								{`${state?.selectedOrder?.table?.prefix || ''}${state
									?.selectedOrder?.table?.tableNumber || ''}`}
							</span>
							<span style={{ paddingLeft: '4px' }}>
								{`${state?.selectedOrder?.docNo || ''}`}
							</span>
							<span style={{ paddingLeft: '6px' }}>
								{`${state?.selectedOrder?.patron?.patronClass || ''}`}
							</span>
						</>
					)
				}
				handleClick={e => handleClick(e, state?.orderID, 0)}
				dropDownOptions={
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
						onClick={handleClose}
					>
						{getOrder.map((order, index) => (
							<StyledMenuItem
								key={index}
								value={order.ID}
								defaultValue={order.ID}
								onClick={e => {
									handleChangeOrder(e, order);
								}}
							>
								<ListItemText primary={order?.docNo} />
							</StyledMenuItem>
						))}
					</StyledMenu>
				}
				rightText={
					state?.selectedOrder !== null && (
						<div>
							{`${state?.selectedOrder?.pax} Pax`}{' '}
							<span className="desc" onClick={() => {}}>
								<EditIcon
									style={{ fontSize: '15px' }}
									onClick={() => {
										history.push({
											pathname: `/menu/outlet-app/${outletID}/table/grid/${state?.selectedOrder?.table?.ID}/edit/${state?.selectedOrder?.ID}`,
											state: {
												table: state?.selectedOrder?.table,
												orders: getOrder,
												orderID: state?.orderID,
												page: 'edit-order-info',
											},
										});
									}}
								/>
							</span>
						</div>
					)
				}
				infoLine={
					<>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<span className="desc">{name}</span>
							<span className="desc right-info">
								{`Total Item: ${itemCount ||
									0} | Disc: (${discountAmount}) | ${totalAmount}`}
							</span>
						</div>
					</>
				}
				subInfoLine={
					<>
						<div>
							<span
								className="desc"
								onClick={() => {
									history.push({
										pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}/payment/audit-log`,
										state: getOrder,
									});
								}}
							>
								<img
									src={AuditIcon}
									alt="ifca"
									width={12}
									loading="lazy"
									style={{
										paddingLeft: '4px',
										width: '18px',
										height: '18px',
									}}
								/>
							</span>
							<span className="desc right-info">
								{`Void Item: ${voidItemCount ||
									0} | Void Disc: ${voidDiscountAmount}`}
							</span>
						</div>
					</>
				}
			/>

			<ContentWrapper singleDynamicInfo multiLineSubheader footer>
				{getAllOrderItem === undefined || getAllOrderItem?.length === 0 ? (
					<EmptyList
						title="There are no orders found"
						subtitle="Please make a new order in the Menu "
					/>
				) : (
					<>
						<List
							style={{
								backgroundColor: 'white',
							}}
						>
							{state?.itemArray?.map((oi, index) => {
								const hasItemDisc =
									oi?.orderItemDiscount?.filter(
										disc => disc?.status === OrderItemDiscountStatus.Active,
									)?.length !== 0 ||
									(oi?.orderDiscountItem &&
										oi?.orderDiscountItem?.amount !== 0) ||
									(oi?.orderDiscountItem?.baseAmount > 0 &&
										oi?.orderDiscountItem?.amount === 0);

								let totalTax = 0;
								oi?.orderItemTax?.map(x => {
									totalTax += x?.taxAmount;
								});

								const baseItemAmountBill = oi?.amount + totalTax;

								const negBaseItemAmountBill =
									(state?.negOrderItem?.[oi?.ID]?.amount ?? 0) +
									(state?.negOrderItem?.[oi?.ID]?.taxAmount ?? 0);

								const activeOID = oi?.orderItemDiscount?.filter(
									el => el?.status === OrderItemDiscountStatus.Active,
								)[0];

								let totalOIDTax = 0;
								activeOID?.orderItemDiscountTax?.map(x => {
									totalOIDTax += x?.taxAmount;
								});

								const oidBA = activeOID?.baseAmount;
								const oidTx = totalOIDTax;

								const oid = oidBA + oidTx;

								let totalODITax = 0;
								oi?.orderDiscountItemTax?.map(x => {
									totalODITax += x?.taxAmount;
								});

								const odiTx = totalODITax;
								const odi = oi?.orderDiscountItem?.baseAmount + odiTx;

								const negItemDisc =
									(state?.negOrderItem?.[oi?.ID]?.orderItemDiscBaseAmount ??
										0) +
									(state?.negOrderItem?.[oi?.ID]?.orderItemDiscTaxAmount ?? 0);

								const negOrderDisc =
									(state?.negOrderItem?.[oi?.ID]?.orderDiscountItemBaseAmount ??
										0) +
									(state?.negOrderItem?.[oi?.ID]?.orderDiscountItemTaxAmount ??
										0);

								const discount = oid ? oid - negItemDisc : odi - negOrderDisc;
								return (
									<ListItem>
										<ListItemText
											primary={
												<>
													<Grid container>
														<Grid item xs={1}>
															<span className="xsTitle text-noflow">
																{`${(index + 1).toString().padStart(2, '0')}.`}
															</span>
														</Grid>
														<Grid item xs={8}>
															<span
																className="xsTitle text-noflow"
																style={
																	oi?.status === OrderItemStatus.Voided ||
																	oi?.status === OrderItemStatus.TransferOut
																		? { textDecoration: 'line-through' }
																		: null
																}
															>
																{oi?.openItemName ?? oi.menuItem?.name}&nbsp;
																&nbsp;
																{oi?.status === OrderItemStatus.Voided ||
																oi?.status === OrderItemStatus.TransferOut
																	? `x${Math.abs(oi.quantity)}`
																	: `x${oi?.quantity -
																			(state?.negOrderItem?.[oi?.ID]
																				?.quantity ?? 0)}`}
															</span>
														</Grid>

														<Grid item xs={3}>
															{hasItemDisc && (
																<span
																	className="mdDesc xsTitle right-info"
																	style={{
																		textDecoration: 'line-through ',
																		padding: '7px 0px 4px 4px',
																	}}
																>
																	{`${currencyCode}${amtStr(
																		Math.abs(
																			new BigNumber(baseItemAmountBill)
																				.minus(negBaseItemAmountBill ?? 0)
																				.toNumber(),
																		),
																	)}`}
																</span>
															)}
															<span
																className="highlight-text flex-space xsTitle right-info"
																style={
																	oi?.status === OrderItemStatus.Voided ||
																	oi?.status === OrderItemStatus.TransferOut
																		? {
																				padding: '7px 0px 4px 4px',
																				textDecoration: 'line-through',
																		  }
																		: { padding: '7px 0px 4px 4px' }
																}
															>
																{hasItemDisc
																	? `${currencyCode}${amtStr(
																			Math.abs(
																				new BigNumber(baseItemAmountBill)
																					.minus(negBaseItemAmountBill ?? 0)
																					.minus(discount ?? 0)
																					.toNumber(),
																			),
																	  )}`
																	: oi.status === OrderItemStatus.Voided ||
																	  oi?.status === OrderItemStatus.TransferOut
																	? `${currencyCode}${amtStr(
																			Math.abs(
																				new BigNumber(baseItemAmountBill)
																					.minus(negBaseItemAmountBill ?? 0)
																					.toNumber(),
																			),
																	  )}`
																	: `${currencyCode}${amtStr(
																			Math.abs(
																				new BigNumber(baseItemAmountBill)
																					.minus(negBaseItemAmountBill ?? 0)
																					.toNumber(),
																			),
																	  )}`}
															</span>
														</Grid>
													</Grid>
												</>
											}
											secondary={
												<>
													<Grid container>
														{oi?.orderItemOption?.length
															? oi?.orderItemOption?.map(oio => (
																	<>
																		{oio?.orderItemOptionItem?.map(
																			oioptitem => {
																				let totalTax = 0;
																				oioptitem?.orderItemOptionItemTax?.map(
																					x => {
																						totalTax += x?.taxAmount;
																					},
																				);

																				const baseOptItemAmountBill =
																					oioptitem?.basePrice + totalTax;

																				const negOptBaseItemAmountBill =
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.basePrice ?? 0) +
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.taxAmount ?? 0);

																				let totalOptItemOIDTax = 0;
																				oioptitem?.orderItemOptionItemDiscountTax?.map(
																					v => {
																						totalOptItemOIDTax += v?.taxAmount;
																					},
																				);

																				let totalOptItemODITax = 0;
																				oioptitem?.orderDiscountItemOptionTax?.map(
																					v => {
																						totalOptItemODITax += v?.taxAmount;
																					},
																				);

																				const discOptItemAmountBill =
																					oioptitem?.discountBaseAmount +
																					totalOptItemOIDTax +
																					totalOptItemODITax;

																				const negDiscOptBaseItemAmountBill =
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.discountBaseAmount ?? 0) +
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.itemDiscountTaxAmount ?? 0) +
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.discountItemTaxAmount ?? 0);

																				const optItemDiscount =
																					discOptItemAmountBill -
																					negDiscOptBaseItemAmountBill;
																				return (
																					<>
																						<Grid item xs={8}>
																							<span className="mdDesc">
																								{oioptitem?.optionItem?.name}
																							</span>
																						</Grid>
																						{
																							<Grid item xs={4}>
																								<span
																									className="mdDesc right-info"
																									style={{
																										marginTop: '4px',
																									}}
																								>
																									{`${currencyCode}${amtStr(
																										new BigNumber(
																											baseOptItemAmountBill,
																										)
																											.minus(
																												negOptBaseItemAmountBill ??
																													0,
																											)
																											.minus(
																												optItemDiscount ?? 0,
																											)
																											.toNumber(),
																									)}`}
																								</span>
																							</Grid>
																						}
																					</>
																				);
																			},
																		)}
																	</>
															  ))
															: oi?.orderItemSetMenuOption?.length
															? oi?.orderItemSetMenuOption?.map(v => (
																	<>
																		{v?.orderItemSetMenuOptionItem?.map(
																			oisetmenuoptitem => (
																				<Grid item xs={8}>
																					<span className="mdDesc">
																						{
																							oisetmenuoptitem
																								?.setMenuOptionItem?.menuItem
																								?.name
																						}
																					</span>
																				</Grid>
																			),
																		)}
																	</>
															  ))
															: null}
													</Grid>
												</>
											}
										/>
									</ListItem>
								);
							})}
						</List>
					</>
				)}
			</ContentWrapper>

			<div style={{ marginBottom: '50px' }}></div>

			<AmountFooter
				style={{
					width: isDesktop && globalState.drawerOpen ? 'inherit' : '96.5%',
					backgroundColor: '#faf2e8',
					position: 'fixed',
				}}
				currencyCode={getOutlet[0]?.currencyCode ?? ''}
				subtotal={amtStr(footerAmount.subtotal)}
				discount={amtStr(footerAmount.discount)}
				serviceTax={amtStr(footerAmount.tax)}
				total={amtStr(footerAmount.total)}
				rounding={amtStr(footerAmount.roundingAmount)}
				page={'payment'}
			/>

			<Footer
				options={[
					{
						name: 'Print',
						onClick: () => {
							handlePrinting();
						},
						color:
							footerAmount?.total === 0 &&
							footerAmount.subtotal - footerAmount.discount === 0
								? 'primary'
								: footerAmount?.total === 0
								? '#faf2e8'
								: 'primary',
						disabled:
							footerAmount?.total === 0 &&
							footerAmount.subtotal - footerAmount.discount === 0
								? false
								: footerAmount?.total === 0
								? true
								: null,
					},
					{
						name: 'Make Payment',
						onClick: e => {
							dispatch({ type: 'paymentDialog', payload: true });
						},
						color:
							footerAmount?.total === 0 || getAllOrderItem?.length === 0
								? '#faf2e8'
								: 'primary',
						disabled:
							footerAmount?.total === 0 || getAllOrderItem?.length === 0,
						props: { type: 'submit' },
					},
				]}
			/>

			<PaymentDialog
				state={state}
				dispatch={dispatch}
				handleSubmit={handleSubmit}
				currencyCode={getOutlet[0]?.currencyCode ?? ''}
				integrateCityLedger={getOutlet[0]?.integrateCityLedger}
				hotelID={getOutlet[0]?.outletChannel?.hotelID}
				total={footerAmount.total}
				register={register}
				setValue={setValue}
				watch={watch}
				control={control}
				reset={reset}
				defaultNonGuest={defaultNonGuest}
				setDefaultNonGuest={setDefaultNonGuest}
				defaultRoom={defaultRoom}
				setDefaultRoom={setDefaultRoom}
				defaultFolio={defaultFolio}
				setDefaultFolio={setDefaultFolio}
				defaultOfficer={defaultOfficer}
				defaultStaff={defaultStaff}
				setDefaultOfficer={setDefaultOfficer}
				setDefaultStaff={setDefaultStaff}
				setDefaultDebtor={setDefaultDebtor}
				defaultDebtor={defaultDebtor}
				selectedRoom={selectedRoom}
				setSelectedRoom={setSelectedRoom}
				selectedFolio={selectedFolio}
				setSelectedFolio={setSelectedFolio}
				selectedNonGuest={selectedNonGuest}
				setSelectedNonGuest={setSelectedNonGuest}
				selectedOfficer={selectedOfficer}
				setSelectedOfficer={setSelectedOfficer}
				setSelectedDebtor={setSelectedDebtor}
				selectedDebtor={selectedDebtor}
				selectedStaff={selectedStaff}
				setSelectedStaff={setSelectedStaff}
				selectedCredit={selectedCredit}
				setSelectedCredit={setSelectedCredit}
				loading1={paymentQuantityLoading}
				loading2={createOnlinePaymentLoading}
				getDefaultPayment={getDefaultPayment}
				onSubmit={onSubmit}
			/>

			<SignatureDialog
				handleSubmit={handleSubmit}
				onSubmit={onSubmit}
				state={state}
				dispatch={dispatch}
				footerAmount={footerAmount}
				loading1={paymentQuantityLoading}
				loading2={createOnlinePaymentLoading}
			/>

			<CloseTableDialog
				state={state}
				dispatch={dispatch}
				table={state?.selectedOrder?.table}
				getOrder={getOrder}
				orderItems={state?.itemArray}
			/>

			<CloseOrderDialog
				state={state}
				dispatch={dispatch}
				orderItems={state?.itemArray}
				order={state?.selectedOrder}
			/>
		</>
	);
};
