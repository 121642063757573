import theme from '@ifca-root/react-component/src/assets/theme';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import {
	Checkbox,
	FormControl,
	Grid,
	MenuItem,
	RadioGroup,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { SearchField } from 'components/Dialog/SearchDialogComponent';
import {
	CreditCardType,
	PaymentClass,
	useGetMenuXDebtorListLazyQuery,
	useGetMenuXInhouseListQuery,
	useGetOfficerLazyQuery,
	useGetRoundingPolicyLazyQuery,
	useGetStaffLazyQuery,
} from 'generated/graphql';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { amtStr } from 'helpers/numFormatter';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import SignatureCanvas from 'react-signature-canvas';

export const PaymentDialog = (props: any) => {
	const {
		state,
		dispatch,
		handleSubmit,
		currencyCode,
		total,
		register,
		setValue,
		watch,
		reset,
		integrateCityLedger,
		hotelID,
		control,
		defaultRoom,
		setDefaultRoom,
		defaultNonGuest,
		setDefaultNonGuest,
		defaultFolio,
		setDefaultFolio,
		defaultDebtor,
		setDefaultDebtor,
		defaultOfficer,
		setDefaultOfficer,
		defaultStaff,
		setDefaultStaff,
		selectedOfficer,
		selectedStaff,
		setSelectedOfficer,
		setSelectedStaff,
		selectedDebtor,
		setSelectedDebtor,
		selectedOrder,
		selectedRoom,
		setSelectedRoom,
		selectedNonGuest,
		setSelectedNonGuest,
		selectedFolio,
		setSelectedFolio,
		selectedCredit,
		setSelectedCredit,
		loading1,
		loading2,
		getDefaultPayment,
		onSubmit,
	} = props;

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const [tempState, setTempState] = useState('');
	const [filterCond, setFilterCond] = useState({
		room: false,
		nonGuest: false,
		folio: false,
		debtor: false,
		officer: false,
		staff: false,
	});
	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	// API

	const {
		loading: MenuXInhouseListLoading,
		data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
	} = useGetMenuXInhouseListQuery({
		skip: !hotelID || !state?.outletID,
		variables: {
			hotelID,
			outletID: state?.outletID,
		},
		fetchPolicy: 'network-only',
		onCompleted: ({ getMenuXInhouseList }) => {
			let defaultOrder = state?.selectedOrder;
			if (defaultOrder?.patronAccountID !== null) {
				let defaultGuest = getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
					x => x?.RegistrationID === defaultOrder?.patronAccountID,
				)[0];
				if (defaultGuest?.GuestType === 'RoomGuest') {
					setDefaultRoom(defaultGuest);
				} else if (defaultGuest?.GuestType === 'NonGuest') {
					setDefaultNonGuest(defaultGuest);
				} else if (defaultGuest?.GuestType === 'Suspend Folio') {
					setDefaultFolio(defaultGuest);
				}
			}
		},
	});

	const [
		loadMenuXDebtorList,
		{
			loading: MenuXDebtorListLoading,
			data: { getMenuXDebtorList } = { getMenuXDebtorList: [] },
		},
	] = useGetMenuXDebtorListLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getMenuXDebtorList }) => {
			let defaultOrder = state?.selectedOrder;
			if (defaultOrder?.patronAccountID !== null) {
				setDefaultDebtor(
					getMenuXDebtorList?.data?.MenuXDebtorList?.filter(
						x => x?.RegistrationID === defaultOrder?.registrationID,
					)[0],
				);
			}
		},
	});

	const [
		loadOfficer,
		{ loading: loadingOfficer, data: { getOfficer } = { getOfficer: [] } },
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOfficer }) => {
			let defaultOrder = state?.selectedOrder;
			if (defaultOrder?.patronAccountID !== null) {
				setDefaultOfficer(
					getOfficer?.filter(x => x?.ID === defaultOrder?.patronAccountID)[0],
				);
			}
		},
	});

	const [
		loadStaff,
		{ loading: loadingStaff, data: { getStaff } = { getStaff: [] } },
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getStaff }) => {
			let defaultOrder = state?.selectedOrder;
			if (defaultOrder?.patronAccountID !== null) {
				setDefaultStaff(
					getStaff?.filter(x => x?.ID === defaultOrder?.patronAccountID)[0],
				);
			}
		},
	});

	const [
		loadRoundingPolicy,
		{
			called: getRoundingPolicyCalled,
			loading: getRoundingPolicyLoading,
			data: { getRoundingPolicy } = { getRoundingPolicy: [] },
			refetch: chargesRefetch,
		},
	] = useGetRoundingPolicyLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getRoundingPolicy }) => {
			console.log(
				'apa >>>',
				getRoundedAmt(balanceT, getRoundingPolicy[0]?.smallestDenomination),
				balanceT,
			);

			dispatch({
				type: 'receivedAmount',
				payload:
					getRoundingPolicy[0]?.inUse === true
						? getRoundedAmt(
								balanceT,
								getRoundingPolicy[0]?.smallestDenomination,
						  )
						: balanceT,
			});
			setValue(
				'receivedAmount',
				getRoundingPolicy[0]?.inUse === true
					? getRoundedAmt(balanceT, getRoundingPolicy[0]?.smallestDenomination)
					: balanceT,
			);
			dispatch({
				type: 'minimumValue',
				payload:
					getRoundingPolicy[0]?.inUse === true
						? getRoundedAmt(
								balanceT,
								getRoundingPolicy[0]?.smallestDenomination,
						  )
						: balanceT,
			});
		},
	});

	// useEffect
	useEffect(() => {
		if (filterCond.room) {
			setOriginalListing(getMenuXInhouseList?.data?.MenuXInhouseList);
		}
	}, [getMenuXInhouseList, filterCond.room]);

	useEffect(() => {
		if (filterCond.nonGuest) {
			setOriginalListing(getMenuXInhouseList?.data?.MenuXInhouseList);
		}
	}, [getMenuXInhouseList, filterCond.nonGuest]);

	useEffect(() => {
		if (filterCond.folio) {
			setOriginalListing(getMenuXInhouseList?.data?.MenuXInhouseList);
		}
	}, [getMenuXInhouseList, filterCond.folio]);

	useEffect(() => {
		if (filterCond.debtor) {
			setOriginalListing(getMenuXDebtorList?.data?.MenuXDebtorList);
		}
	}, [getMenuXDebtorList, filterCond.debtor]);

	useEffect(() => {
		if (getOfficer?.length > 0 && getOfficer && filterCond.officer) {
			setOriginalListing(getOfficer);
		}
	}, [getOfficer, filterCond.officer]);

	useEffect(() => {
		if (getStaff?.length > 0 && getStaff && filterCond.staff) {
			setOriginalListing(getStaff);
		}
	}, [getStaff, filterCond.staff]);

	// function
	const customSortPayment = [
		'CASH',
		'CREDIT',
		'CHEQUE',
		'BANK_TT',
		'ONLINE',
		'VOUCHER',
		'MEMBER',
		'STAFF',
		'OFFICER',
		'ROOM',
		'DEBTOR',
		'NON_GUEST',
		'SUSPEND_FOLIO',
		'SKIPPER',
		'OTHERS',
	];

	const customSort = (a, b) => {
		const indexA = customSortPayment.indexOf(a);
		const indexB = customSortPayment.indexOf(b);

		if (indexA === -1 && indexB === -1) {
			return a.localeCompare(b);
		} else if (indexA === -1) {
			return 1;
		} else if (indexB === -1) {
			return -1;
		}

		return indexA - indexB;
	};

	const handleCallLazy = v => {
		if (
			(v?.paymentClass === PaymentClass.Debtor ||
				v?.paymentClass === PaymentClass.Credit) &&
			integrateCityLedger
		) {
			setFilterCond({
				debtor: true,
				officer: false,
				room: false,
				nonGuest: false,
				staff: false,
				folio: false,
			});
			if (!MenuXDebtorListLoading && getMenuXDebtorList?.length === 0) {
				loadMenuXDebtorList({
					variables: {
						hotelID: hotelID,
						outletID: state?.outletID,
					},
				});
			}
		} else if (v?.paymentClass === PaymentClass.Room && hotelID) {
			setFilterCond({
				room: true,
				nonGuest: false,
				officer: false,
				debtor: false,
				staff: false,
				folio: false,
			});
		} else if (v?.paymentClass === PaymentClass.NonGuest && hotelID) {
			setFilterCond({
				room: false,
				nonGuest: true,
				officer: false,
				debtor: false,
				staff: false,
				folio: false,
			});
		} else if (v?.paymentClass === PaymentClass.SuspendFolio && hotelID) {
			setFilterCond({
				room: false,
				nonGuest: false,
				officer: false,
				debtor: false,
				staff: false,
				folio: true,
			});
		} else if (v?.paymentClass === PaymentClass.Officer) {
			setFilterCond({
				officer: true,
				debtor: false,
				room: false,
				nonGuest: false,
				staff: false,
				folio: false,
			});
			if (!loadingOfficer && getOfficer?.length === 0) {
				loadOfficer({
					variables: {
						isActive: true,
					},
				});
			}
		} else if (v?.paymentClass === PaymentClass.Staff) {
			setFilterCond({
				officer: false,
				debtor: false,
				room: false,
				nonGuest: false,
				staff: true,
				folio: false,
			});
			if (!loadingStaff && getStaff?.length === 0) {
				loadStaff({
					variables: {
						isActive: true,
					},
				});
			}
		}
	};

	const handleRadio = v => {
		setTempState('');
		const defaultState = [
			{
				type: PaymentClass.Credit,
				name: 'cardDropDown',
			},
			{
				type: PaymentClass.Cash,
				name: 'cashAmount',
			},
			{
				type: PaymentClass.Room,
				name: 'hotelSearchField',
			},
			{
				type: PaymentClass.NonGuest,
				name: 'nonGuestSearchField',
			},
			{
				type: PaymentClass.SuspendFolio,
				name: 'folioSearchField',
			},
			{
				type: PaymentClass.Debtor,
				name: 'debtorSearchField',
			},
			{
				type: PaymentClass.Officer,
				name: 'officerSearchField',
			},
			{
				type: PaymentClass.Staff,
				name: 'staffSearchField',
			},
			{
				type: PaymentClass.Voucher,
				name: 'voucher',
			},
			{
				type: PaymentClass.Others,
				name: 'others',
			},
			{
				type: PaymentClass.BankTt,
				name: 'bankTT',
			},
			{
				type: PaymentClass.Member,
				name: 'member',
			},
			{
				type: PaymentClass.Online,
				name: 'onlinePayment',
			},
			{
				type: PaymentClass.Skipper,
				name: 'skipperAccount',
			},
			{
				type: PaymentClass.Cheque,
				name: 'cheque',
			},
		];

		defaultState?.map(d => {
			if (d?.type === v?.paymentClass) {
				if (
					v?.paymentClass === PaymentClass.Cash &&
					getRoundingPolicy?.length
				) {
					loadRoundingPolicy();
				}
				dispatch({
					type: d?.name,
					payload: true,
				});
			} else {
				dispatch({
					type: d?.name,
					payload: false,
				});
			}
		});
	};

	const getRoundedAmt = (amount, round) => {
		let roundingAmt = amount - Math.floor(amount);

		let roundedAmt = Math.round((roundingAmt + Number.EPSILON) * 100) / 100;

		let roundingMulti = Math.round(
			(roundedAmt * 100) / ((round ?? 0.05) * 100),
		);
		let finalAmt = Math.floor(amount) + (round ?? 0.05) * roundingMulti;

		return finalAmt;
	};

	const creditCardText = data => {
		let credClass;
		setSelectedCredit(watch('cardType'));
		if (
			data === CreditCardType.AmericanExpressCard ||
			data === 'American Express Card'
		) {
			credClass = 'American Express Card';
		} else if (data === CreditCardType.DinersCard || data === 'Diners Card') {
			credClass = 'Diners Card';
		} else if (data === CreditCardType.JcbCard || data === 'JCB Card') {
			credClass = 'JCB Card';
		} else if (data === CreditCardType.Mastercard || data === 'Master Card') {
			credClass = 'Master Card';
		} else if (data === CreditCardType.UnionpayCard || data === 'UNION PAY') {
			credClass = 'UNION PAY';
		} else if (data === CreditCardType.VisaCard || data === 'Visa Card') {
			credClass = 'Visa Card';
		} else if (data === CreditCardType.DebitCard || data === 'Debit Card') {
			credClass = 'Debit Card';
		} else if (data === CreditCardType.Ewallet || data === 'E-Wallet') {
			credClass = 'E-Wallet';
		}
		return credClass;
	};

	const creditCardTypeEnum = Object.values(CreditCardType);

	const CardDropDownDownField = () => {
		const cardDebtors = getMenuXDebtorList?.data?.MenuXDebtorList?.filter(
			x => x?.GuestStatus === 'CARD',
		);

		if (!MenuXDebtorListLoading && cardDebtors?.length > 0) {
			return (
				<TextField select>
					{cardDebtors?.map((el: any, index: number) => (
						<MenuItem key={index} value={el}>
							<span className="text-noflow">
								{creditCardText(el?.GuestType)}
							</span>
						</MenuItem>
					))}
				</TextField>
			);
		} else {
			return (
				<TextField select>
					{creditCardTypeEnum?.map((el: any, index: number) => (
						<MenuItem key={index} value={el}>
							<span className="text-noflow">{creditCardText(el)}</span>
						</MenuItem>
					))}
				</TextField>
			);
		}
	};

	const filterPaymentClass = paymentClass => {
		const array = state?.lastArray?.filter(
			x => x?.paymentClass === paymentClass,
		);

		return array;
	};

	const isSignature = () => {
		const isSignature =
			array?.filter(
				x =>
					x?.paymentClass === PaymentClass.Room ||
					x?.paymentClass === PaymentClass.Debtor ||
					x?.paymentClass === PaymentClass.NonGuest ||
					x?.paymentClass === PaymentClass.SuspendFolio ||
					x?.paymentClass === PaymentClass.Member,
			)?.length > 0
				? true
				: false;

		return isSignature;
	};

	// const
	let [array, setArray] = useState([]);
	let [error, setError] = useState(false);
	let [errorMessage, setErrorMessage] = useState('');
	let [isTouch, setIsTouch] = useState(false);
	let [isCancel, setCancel] = useState(false);

	let totalT = 0,
		balanceT = 0;

	array?.map(x => {
		totalT +=
			x?.paymentClass === PaymentClass.Cash
				? state?.receivedAmount
				: isCancel === false
				? x?.amountPayment
				: 0;

		totalT = Number(totalT.toFixed(2));
	});

	useEffect(() => {
		if (array?.length === 0 || array === undefined) {
			totalT = 0;
			balanceT = 0;
			dispatch({ type: 'receivedAmount', payload: 0 });
		}
	}, [array?.length]);

	balanceT = Number((total - totalT).toFixed(2));

	useEffect(() => {
		if (balanceT < 0 && state?.receivedAmount === 0) {
			setError(true);
			setErrorMessage('Amount exceeded bill amount');
		} else if (balanceT > 0) {
			setError(true);
			setErrorMessage('Insufficient amount');
		} else if (balanceT === 0 || balanceT < 0) {
			setError(false);
		}
	}, [balanceT]);

	const hasSuspendFolioIssue =
		array?.filter(x => x?.paymentClass === PaymentClass.SuspendFolio)?.length >
			0 &&
		Object?.values(selectedFolio ?? {})?.length <= 0 &&
		Object?.values(defaultFolio ?? {})?.length <= 0;
	const hasDebtorIssue =
		array?.filter(x => x?.paymentClass === PaymentClass.Debtor)?.length > 0 &&
		(selectedDebtor?.CreditBalance <= 0 ||
			(Object?.values(selectedDebtor ?? {})?.length <= 0 &&
				Object?.values(defaultDebtor ?? {})?.length <= 0));
	const hasNonGuestIssue =
		array?.filter(x => x?.paymentClass === PaymentClass.NonGuest)?.length > 0 &&
		(selectedNonGuest?.CreditBalance <= 0 ||
			(Object?.values(selectedNonGuest ?? {})?.length <= 0 &&
				Object?.values(defaultNonGuest ?? {})?.length <= 0));
	const hasOfficerIssue =
		array?.filter(x => x?.paymentClass === PaymentClass.Officer)?.length > 0 &&
		Object?.values(selectedOfficer ?? {})?.length <= 0 &&
		Object?.values(defaultOfficer ?? {})?.length <= 0;
	const hasStaffIssue =
		array?.filter(x => x?.paymentClass === PaymentClass.Staff)?.length > 0 &&
		Object?.values(selectedStaff ?? {})?.length <= 0 &&
		Object?.values(defaultStaff ?? {})?.length <= 0;
	const hasRoomIssue =
		array?.filter(x => x?.paymentClass === PaymentClass.Room)?.length > 0 &&
		(selectedRoom?.CreditBalance <= 0 ||
			(Object?.values(selectedRoom ?? {})?.length <= 0 &&
				Object?.values(defaultRoom ?? {})?.length <= 0));
	const hasAnyIssue =
		hasSuspendFolioIssue ||
		hasDebtorIssue ||
		hasNonGuestIssue ||
		hasOfficerIssue ||
		hasStaffIssue ||
		hasRoomIssue;

	return (
		<>
			<CommonDialog
				open={state?.paymentDialog}
				hidden={state?.signatureDialog}
				fullWidth
				disableEnforceFocus
				onClose={() => {
					dispatch({ type: 'receivedAmount', payload: 0 });
					dispatch({ type: 'paymentDialog', payload: false });
					setSelectedRoom({});
					setSelectedNonGuest({});
					setSelectedFolio({});
					setSelectedDebtor({});
					setSelectedOfficer({});
					setSelectedStaff({});
					setSelectedCredit({});
					setIsTouch(false);
					dispatch({ type: 'radioError', payload: false });
					setCancel(true);
					dispatch({ type: 'disablePaymentClass', payload: null });
					setArray(null);
					handleSubmit(data => {
						const { defaultPayment } = data;
						setArray(defaultPayment?.filter(x => x?.selected === true));
					})();
				}}
				sections={{
					header: {
						dynamic: (
							<>
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="flex session">
												<div
													className="title highlight-text flex-space"
													style={{ color: '#ff9800' }}
												>
													{'Payment'}
												</div>
												<div className="highlight-text title">{`${currencyCode}${amtStr(
													total,
												)}`}</div>
											</div>
										</div>
									</div>
									{
										<div className="infoline-content ">
											<div className="xsTitle infoline">
												{`${state?.selectedOrder?.table?.prefix}${state?.selectedOrder?.table?.tableNumber} ${state?.selectedOrder?.docNo}`}
											</div>
										</div>
									}
								</div>
							</>
						),
					},
					body: () => (
						<>
							<span className="mdDesc" style={{ paddingLeft: '15px' }}>
								{'Payment Method'}
							</span>

							<RadioGroup
								aria-label="paymentMethod"
								onChange={e => {
									register(`defaultPaymentID`);
									setValue(`defaultPaymentID`, e.target.value);
								}}
							>
								{getDefaultPayment
									?.filter(s => s?.isActive === true)
									?.filter(s => s?.paymentClass !== PaymentClass.UnsettledBill)
									?.sort((a, b) => customSort(a?.paymentClass, b?.paymentClass))
									?.map((v, i) => {
										const defaultPayment = `defaultPayment[${i}]`;

										const isDisabled =
											state?.disablePaymentClass === PaymentClass?.Cash ||
											state?.disablePaymentClass === PaymentClass?.BankTt ||
											state?.disablePaymentClass === PaymentClass?.Credit ||
											state?.disablePaymentClass === PaymentClass?.Cheque ||
											state?.disablePaymentClass === PaymentClass?.Online ||
											state?.disablePaymentClass === PaymentClass?.Voucher ||
											state?.disablePaymentClass === PaymentClass?.Member ||
											state?.disablePaymentClass === PaymentClass?.Staff ||
											state?.disablePaymentClass === PaymentClass?.Officer ||
											state?.disablePaymentClass === PaymentClass?.Skipper ||
											state?.disablePaymentClass === PaymentClass?.Others
												? v?.paymentClass === PaymentClass.NonGuest ||
												  v?.paymentClass === PaymentClass.Room ||
												  v?.paymentClass === PaymentClass.Debtor ||
												  v?.paymentClass === PaymentClass.SuspendFolio
												: state?.disablePaymentClass === PaymentClass?.Room
												? v?.paymentClass !== PaymentClass?.Room
												: state?.disablePaymentClass === PaymentClass?.NonGuest
												? v?.paymentClass !== PaymentClass?.NonGuest
												: state?.disablePaymentClass === PaymentClass?.Debtor
												? v?.paymentClass !== PaymentClass?.Debtor
												: state?.disablePaymentClass ===
												  PaymentClass?.SuspendFolio
												? v?.paymentClass !== PaymentClass?.SuspendFolio
												: false;

										return (
											<>
												<>
													<Grid container>
														<Grid item xs={1}>
															<FormControl
																component="fieldset"
																style={{ paddingRight: 'revert' }}
															>
																<Checkbox
																	onChange={e => {
																		register(
																			`${defaultPayment}.defaultPaymentID`,
																		);
																		setValue(
																			`${defaultPayment}.defaultPaymentID`,
																			v?.ID,
																		);

																		register(`${defaultPayment}.paymentClass`);
																		setValue(
																			`${defaultPayment}.paymentClass`,
																			v?.paymentClass,
																		);

																		register(`${defaultPayment}.amountPayment`);
																		setValue(
																			`${defaultPayment}.amountPayment`,
																			balanceT < 0 ? 0 : balanceT,
																		);

																		register(
																			`${defaultPayment}.registrationID`,
																		);
																		setValue(
																			`${defaultPayment}.registrationID`,
																			state?.selectedOrder?.patronAccountID,
																		);
																		register(`${defaultPayment}.length`);
																		setValue(
																			`${defaultPayment}.length`,
																			state?.array?.length ?? 0,
																		);

																		handleSubmit(data => {
																			const { defaultPayment } = data;
																			setArray(
																				defaultPayment?.filter(
																					x => x?.selected === true,
																				),
																			);
																			setCancel(false);
																		})();
																	}}
																	onClick={e => {
																		if (watch(`defaultPaymentID`) !== v?.ID) {
																			handleCallLazy(v);
																			handleRadio(v);
																			chargesRefetch();
																			setSelectedCredit({});

																			if (
																				watch(`${defaultPayment}.selected`) ===
																				true
																			) {
																				dispatch({
																					type: 'disablePaymentClass',
																					payload: v?.paymentClass,
																				});
																			} else if (
																				watch(`${defaultPayment}.selected`) ===
																					false &&
																				watch(`${defaultPayment}.length`) === 0
																			) {
																				dispatch({
																					type: 'disablePaymentClass',
																					payload: null,
																				});
																				setSelectedRoom({});
																				setSelectedNonGuest({});
																				setSelectedFolio({});
																				setSelectedDebtor({});
																				setSelectedOfficer({});
																				setSelectedStaff({});
																				setSelectedCredit({});
																			}
																		}
																	}}
																	name={`${defaultPayment}.selected`}
																	inputRef={register()}
																	color="primary"
																	disabled={isDisabled}
																/>
															</FormControl>
														</Grid>

														<Grid
															item
															xs={6}
															style={{
																paddingTop: '10px',
																paddingLeft: '14px',
															}}
														>
															<div> {v?.paymentTypeInfo?.description} </div>
														</Grid>

														{watch(`${defaultPayment}.selected`) &&
															v?.paymentClass !== PaymentClass.Cash && (
																<Grid item xs={5}>
																	<div className="desc text-noflow">
																		{'Amount'}
																	</div>
																	<div
																		className="desc form-visitor-price"
																		style={{
																			color: '#ff9800',
																			fontWeight: 'bold',
																			marginTop: '-9px',
																		}}
																	>
																		<Controller
																			render={({ onChange, onBlur, value }) => (
																				<NumberFormat
																					customInput={TextField}
																					allowNegative={false}
																					fullWidth
																					autoComplete="off"
																					isNumericString
																					decimalScale={2}
																					fixedDecimalScale
																					thousandSeparator
																					defaultValue={
																						balanceT < 0 ? 0 : balanceT
																					}
																					variant="outlined"
																					margin="dense"
																					max={balanceT}
																					className="custom-add desc"
																					onValueChange={(value: any) => {
																						const { floatValue } = value;
																						register(
																							`${defaultPayment}.amountPayment`,
																						);
																						setValue(
																							`${defaultPayment}.amountPayment`,
																							floatValue,
																						);
																						handleSubmit(data => {
																							const { defaultPayment } = data;
																							setArray(
																								defaultPayment?.filter(
																									x => x?.selected === true,
																								),
																							);
																						})();
																					}}
																				/>
																			)}
																			name={`patron`}
																			control={control}
																			ref={register}
																			className="desc custom-add"
																		/>
																	</div>
																</Grid>
															)}
													</Grid>

													{watch(`${defaultPayment}.selected`) &&
														error === true && (
															<span
																className="desc"
																style={{ color: 'red', textAlign: 'right' }}
															>
																{errorMessage}
															</span>
														)}

													{watch(`${defaultPayment}.selected`) &&
													watch(`${defaultPayment}.paymentClass`) ===
														PaymentClass.Voucher ? (
														<Grid container>
															<Grid
																item
																xs={1}
																style={{
																	maxWidth: isDesktop
																		? 'calc(5%)'
																		: 'calc(10%)',
																}}
															></Grid>
															<Grid item xs={10} style={{ marginLeft: '4px' }}>
																<Controller
																	render={({ onChange, onBlur, value }) => (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			style={{
																				marginBottom: '0px',
																				marginTop: '0px',
																			}}
																			margin="dense"
																			label="Voucher Code"
																			onChange={e => {
																				register(
																					`${defaultPayment}.referenceNo`,
																				);
																				setValue(
																					`${defaultPayment}.referenceNo`,
																					e.target.value,
																				);
																			}}
																		/>
																	)}
																	name="referenceNo"
																	label={'Voucher Code'}
																	fullWidth
																	autoComplete="off"
																	multiline={false}
																	margin="dense"
																	ref={register}
																	control={control}
																	className="referenceNo"
																	helperText={
																		state?.radioError &&
																		watch('referenceNo') === undefined
																			? 'Voucher Code must not be empty'
																			: null
																	}
																	error={
																		state?.radioError &&
																		watch('referenceNo') === undefined
																	}
																/>
															</Grid>
														</Grid>
													) : null}

													{watch(`${defaultPayment}.selected`) &&
													watch(`${defaultPayment}.paymentClass`) ===
														PaymentClass.BankTt ? (
														<Grid container>
															<Grid
																item
																xs={1}
																style={{
																	maxWidth: isDesktop
																		? 'calc(5%)'
																		: 'calc(10%)',
																}}
															></Grid>
															<Grid item xs={10} style={{ marginLeft: '4px' }}>
																<Controller
																	render={({ onChange, onBlur, value }) => (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			style={{
																				marginBottom: '0px',
																				marginTop: '0px',
																			}}
																			margin="dense"
																			label="Remarks"
																			onChange={e => {
																				register(`${defaultPayment}.remark`);
																				setValue(
																					`${defaultPayment}.remark`,
																					e.target.value,
																				);
																			}}
																		/>
																	)}
																	name="remark"
																	label={'Remarks'}
																	fullWidth
																	autoComplete="off"
																	multiline={false}
																	margin="dense"
																	ref={register}
																	control={control}
																	className="remark"
																	helperText={
																		state?.radioError &&
																		watch('remark') === undefined
																			? 'Input must not be empty'
																			: null
																	}
																	error={
																		state?.radioError &&
																		watch('remark') === undefined
																	}
																/>
															</Grid>
														</Grid>
													) : null}

													{watch(`${defaultPayment}.selected`) &&
													watch(`${defaultPayment}.paymentClass`) ===
														PaymentClass.Others ? (
														<Grid container>
															<Grid
																item
																xs={1}
																style={{
																	maxWidth: isDesktop
																		? 'calc(5%)'
																		: 'calc(10%)',
																}}
															></Grid>
															<Grid item xs={10} style={{ marginLeft: '4px' }}>
																<Controller
																	render={({ onChange, onBlur, value }) => (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			style={{
																				marginBottom: '0px',
																				marginTop: '0px',
																			}}
																			margin="dense"
																			label="Remarks"
																			onChange={e => {
																				register(
																					`${defaultPayment}.referenceNo`,
																				);
																				setValue(
																					`${defaultPayment}.referenceNo`,
																					e.target.value,
																				);
																			}}
																		/>
																	)}
																	name="referenceNo"
																	label={'Remarks'}
																	fullWidth
																	autoComplete="off"
																	multiline={false}
																	margin="dense"
																	ref={register}
																	control={control}
																	className="referenceNo"
																	helperText={
																		state?.radioError &&
																		watch('referenceNo') === undefined
																			? 'Input must not be empty'
																			: null
																	}
																	error={
																		state?.radioError &&
																		watch('referenceNo') === undefined
																	}
																/>
															</Grid>
														</Grid>
													) : null}

													{watch(`${defaultPayment}.selected`) &&
													watch(`${defaultPayment}.paymentClass`) ===
														PaymentClass.Member ? (
														<Grid container>
															<Grid
																item
																xs={1}
																style={{
																	maxWidth: isDesktop
																		? 'calc(5%)'
																		: 'calc(10%)',
																}}
															></Grid>
															<Grid item xs={10} style={{ marginLeft: '4px' }}>
																<Controller
																	render={({ onChange, onBlur, value }) => (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			style={{
																				marginBottom: '0px',
																				marginTop: '0px',
																			}}
																			margin="dense"
																			label="Remarks"
																			onChange={e => {
																				register(`${defaultPayment}.remark`);
																				setValue(
																					`${defaultPayment}.remark`,
																					e.target.value,
																				);
																			}}
																		/>
																	)}
																	name="remark"
																	label={'Remarks'}
																	fullWidth
																	autoComplete="off"
																	multiline={false}
																	margin="dense"
																	ref={register}
																	control={control}
																	className="remark"
																	helperText={
																		state?.radioError &&
																		watch('remark') === undefined
																			? 'Input must not be empty'
																			: null
																	}
																	error={
																		state?.radioError &&
																		watch('remark') === undefined
																	}
																/>
															</Grid>
														</Grid>
													) : null}

													{watch(`${defaultPayment}.selected`) &&
													watch(`${defaultPayment}.paymentClass`) ===
														PaymentClass.Online ? (
														<Grid container>
															<Grid
																item
																xs={1}
																style={{
																	maxWidth: isDesktop
																		? 'calc(5%)'
																		: 'calc(10%)',
																}}
															></Grid>
															<Grid item xs={10} style={{ marginLeft: '4px' }}>
																<Controller
																	render={({ onChange, onBlur, value }) => (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			style={{
																				marginBottom: '0px',
																				marginTop: '0px',
																			}}
																			margin="dense"
																			label="Remarks"
																			onChange={e => {
																				register(`${defaultPayment}.remark`);
																				setValue(
																					`${defaultPayment}.remark`,
																					e.target.value,
																				);
																			}}
																		/>
																	)}
																	name="remark"
																	label={'Remarks'}
																	fullWidth
																	autoComplete="off"
																	multiline={false}
																	margin="dense"
																	ref={register}
																	control={control}
																	className="remark"
																	helperText={
																		state?.radioError &&
																		watch('remark') === undefined
																			? 'Input must not be empty'
																			: null
																	}
																	error={
																		state?.radioError &&
																		watch('remark') === undefined
																	}
																/>
															</Grid>
														</Grid>
													) : null}

													{watch(`${defaultPayment}.selected`) &&
													watch(`${defaultPayment}.paymentClass`) ===
														PaymentClass.Skipper ? (
														<Grid container>
															<Grid
																item
																xs={1}
																style={{
																	maxWidth: isDesktop
																		? 'calc(5%)'
																		: 'calc(10%)',
																}}
															></Grid>
															<Grid item xs={10} style={{ marginLeft: '4px' }}>
																<Controller
																	render={({ onChange, onBlur, value }) => (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			style={{
																				marginBottom: '0px',
																				marginTop: '0px',
																			}}
																			margin="dense"
																			label="Remarks"
																			onChange={e => {
																				register(`${defaultPayment}.remark`);
																				setValue(
																					`${defaultPayment}.remark`,
																					e.target.value,
																				);
																			}}
																		/>
																	)}
																	name="remark"
																	label={'Remarks'}
																	fullWidth
																	autoComplete="off"
																	multiline={false}
																	margin="dense"
																	ref={register}
																	control={control}
																	className="remark"
																	helperText={
																		state?.radioError &&
																		watch('remark') === undefined
																			? 'Input must not be empty'
																			: null
																	}
																	error={
																		state?.radioError &&
																		watch('remark') === undefined
																	}
																/>
															</Grid>
														</Grid>
													) : null}

													{watch(`${defaultPayment}.selected`) &&
													watch(`${defaultPayment}.paymentClass`) ===
														PaymentClass.Cheque ? (
														<Grid container>
															<Grid
																item
																xs={1}
																style={{
																	maxWidth: isDesktop
																		? 'calc(5%)'
																		: 'calc(10%)',
																}}
															></Grid>
															<Grid item xs={10} style={{ marginLeft: '4px' }}>
																<Controller
																	render={({ onChange, onBlur, value }) => (
																		<TextField
																			fullWidth
																			autoComplete="off"
																			style={{
																				marginBottom: '0px',
																				marginTop: '0px',
																			}}
																			margin="dense"
																			label="Remarks"
																			onChange={e => {
																				register(`${defaultPayment}.remark`);
																				setValue(
																					`${defaultPayment}.remark`,
																					e.target.value,
																				);
																			}}
																		/>
																	)}
																	name="remark"
																	label={'Remarks'}
																	fullWidth
																	autoComplete="off"
																	multiline={false}
																	margin="dense"
																	ref={register}
																	control={control}
																	className="remark"
																	helperText={
																		`${defaultPayment}.selected` === undefined
																			? 'Input must not be empty'
																			: null
																	}
																	error={
																		`${defaultPayment}.remark` === undefined
																	}
																/>
															</Grid>
														</Grid>
													) : null}

													{watch(`${defaultPayment}.selected`) === true &&
													watch(`${defaultPayment}.paymentClass`) ===
														PaymentClass.Cash ? (
														<Grid container>
															<Grid
																item
																xs={1}
																style={{
																	maxWidth: isDesktop
																		? 'calc(5%)'
																		: 'calc(10%)',
																}}
															></Grid>

															<Grid
																item
																xs={5}
																style={{
																	marginRight: '4px',
																}}
															>
																<Controller
																	render={({ onChange, onBlur, value }) => (
																		<NumberFormat
																			customInput={TextField}
																			fullWidth
																			autoComplete="off"
																			isNumericString
																			thousandSeparator
																			decimalScale={2}
																			fixedDecimalScale
																			style={{
																				marginBottom: '0px',
																				marginTop: '0px',
																			}}
																			label="Received Amt"
																			margin="dense"
																			value={state?.receivedAmount}
																			defaultValue={state?.minimumValue}
																			onValueChange={e => {
																				dispatch({
																					type: 'receivedAmount',
																					payload:
																						getRoundingPolicy[0]?.inUse === true
																							? getRoundedAmt(
																									e?.floatValue ?? 0,
																									getRoundingPolicy[0]
																										?.smallestDenomination,
																							  )
																							: state?.receivedAmount,
																				});

																				setValue(
																					'receivedAmount',
																					getRoundingPolicy[0]?.inUse === true
																						? getRoundedAmt(
																								e?.floatValue,
																								getRoundingPolicy[0]
																									?.smallestDenomination,
																						  )
																						: state?.receivedAmount,
																				);
																			}}
																			helperText={
																				!!!watch('receivedAmount')
																					? 'Amount is required'
																					: null
																			}
																			error={
																				!!!watch('receivedAmount')
																					? true
																					: false
																			}
																		/>
																	)}
																	style={{
																		paddingBottom: '0px',
																		float: 'right',
																	}}
																	name="receivedAmount"
																	label="Received Amt"
																	control={control}
																	ref={register}
																/>
															</Grid>
															<Grid item xs={5} style={{ marginLeft: '4px' }}>
																<Controller
																	render={({ onChange, onBlur, value }) => (
																		<NumberFormat
																			customInput={TextField}
																			fullWidth
																			autoComplete="off"
																			isNumericString
																			thousandSeparator
																			decimalScale={2}
																			fixedDecimalScale
																			style={{
																				marginBottom: '0px',
																				marginTop: '0px',
																			}}
																			label="Change Amt"
																			disabled
																			margin="dense"
																			value={totalT - total}
																			defaultValue={0}
																			onValueChange={e => {
																				dispatch({
																					type: 'changeAmount',
																					payload: getRoundedAmt(
																						e?.floatValue ?? 0,
																						getRoundingPolicy[0]
																							?.smallestDenomination,
																					),
																				});
																			}}
																		/>
																	)}
																	style={{
																		paddingBottom: '0px',
																		float: 'right',
																	}}
																	name="changeAmount"
																	label="Change Amt"
																	control={control}
																	ref={register}
																/>
															</Grid>
														</Grid>
													) : null}

													{watch(`${defaultPayment}.selected`) &&
													watch(`${defaultPayment}.paymentClass`) ===
														PaymentClass.Credit ? (
														<Grid container>
															<Grid
																item
																xs={1}
																style={{
																	maxWidth: isDesktop
																		? 'calc(5%)'
																		: 'calc(10%)',
																}}
															></Grid>

															<Grid
																item
																xs={5}
																style={{
																	marginRight: '4px',
																}}
															>
																<Controller
																	as={CardDropDownDownField()}
																	label={'Card Type'}
																	name={'cardType'}
																	style={{
																		marginTop: '0px',
																		marginBottom: '0px',
																	}}
																	autoComplete="off"
																	control={control}
																	multiline
																	fullWidth
																	margin="dense"
																	ref={register}
																	helperText={
																		filterPaymentClass(PaymentClass.Credit)
																			?.length > 0 &&
																		filterPaymentClass(PaymentClass.Credit)[0]
																			?.creditCardType === undefined &&
																		watch('cardType') === undefined
																			? 'Must select at least one option'
																			: filterPaymentClass(PaymentClass.Credit)
																					?.length > 0 &&
																			  filterPaymentClass(
																					PaymentClass.Credit,
																			  )[0]?.creditCardType === undefined &&
																			  Number(
																					watch('cardType')?.CreditBalance,
																			  ) < balanceT
																			? 'Not enough Credit Balance!'
																			: null
																	}
																	error={
																		(filterPaymentClass(PaymentClass.Credit)
																			?.length > 0 &&
																			filterPaymentClass(PaymentClass.Credit)[0]
																				?.creditCardType === undefined &&
																			watch('cardType') === undefined) ||
																		(filterPaymentClass(PaymentClass.Credit)
																			?.length > 0 &&
																			filterPaymentClass(PaymentClass.Credit)[0]
																				?.creditCardType === undefined &&
																			Number(watch('cardType')?.CreditBalance) <
																				balanceT)
																	}
																/>
															</Grid>
															<Grid item xs={5} style={{ marginLeft: '4px' }}>
																<Controller
																	as={
																		<NumberFormat
																			allowEmptyFormatting
																			customInput={TextField}
																			fullWidth
																			autoComplete="off"
																			style={{
																				marginBottom: '0px',
																				marginTop: '0px',
																			}}
																			margin="dense"
																			onValueChange={(value: any) => {
																				const { formattedValue } = value;
																				register(`${defaultPayment}.cardRef`);
																				setValue(
																					`${defaultPayment}.cardRef`,
																					formattedValue,
																				);
																			}}
																		/>
																	}
																	name="cardReference"
																	label={'Card Ref.'}
																	fullWidth
																	autoComplete="off"
																	multiline={false}
																	margin="dense"
																	ref={register}
																	control={control}
																	className="cardReference"
																	helperText={
																		filterPaymentClass(PaymentClass.Credit)
																			?.length > 0 &&
																		filterPaymentClass(PaymentClass.Credit)[0]
																			?.cardNo === undefined &&
																		watch('cardReference') === undefined
																			? 'Card Ref. must not be empty'
																			: null
																	}
																	error={
																		filterPaymentClass(PaymentClass.Credit)
																			?.length > 0 &&
																		filterPaymentClass(PaymentClass.Credit)[0]
																			?.cardNo === undefined &&
																		watch('cardReference') === undefined
																	}
																/>
															</Grid>
														</Grid>
													) : null}

													{!MenuXInhouseListLoading &&
														filterCond?.room &&
														v?.paymentClass == PaymentClass.Room &&
														watch(`${defaultPayment}.selected`) && (
															<SearchField
																data={v?.paymentClass}
																paymentEnum={PaymentClass.Room}
																tempState={tempState}
																reducerState={state?.hotelSearchField}
																label={'Search Hotel Guest'}
																defaultListField={{
																	defaultField: state?.defaultRoom,
																	paymentEnum: PaymentClass.Room,
																	data: 'GuestName',
																	type: 'RoomNo',
																	balance: 'CreditBalance',
																}}
																listField={{
																	filterCondition: 'GuestType',
																	fieldKey: ['RoomGuest'],
																	arg: 'RegistrationID',
																	filteredList: filteredList,
																	setSelectedListItem: setSelectedRoom,
																	selectedListItem: selectedRoom,
																	data: 'GuestName',
																	type: 'RoomNo',
																	balance: 'CreditBalance',
																	paymentEnum: PaymentClass.Room,
																	register: register,
																	setValue: setValue,
																	defaultPayment: defaultPayment,
																}}
																selectedField={selectedRoom}
																defaultField={defaultRoom}
																selectedOrder={selectedOrder}
																setSelectedField={setSelectedRoom}
																total={watch(`${defaultPayment}.amountPayment`)}
																onChange={e => {
																	handleSearch(`"${e?.target?.value}"`, [
																		`GuestName`,
																		`RoomNo`,
																	]);
																	if (filteredList?.length === 1) {
																		setSelectedRoom(filteredList[0]);
																	}
																	setTempState(e?.target?.value);
																}}
																defaultValue={
																	selectedOrder?.patronAccountID
																		? getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
																				x =>
																					x?.RegistrationID ===
																						selectedOrder?.patronAccountID &&
																					x?.GuestType === 'RoomGuest',
																		  )[0]?.GuestName
																		: null
																}
															/>
														)}

													{!MenuXInhouseListLoading &&
														filterCond?.nonGuest &&
														v?.paymentClass == PaymentClass.NonGuest &&
														watch(`${defaultPayment}.selected`) && (
															<SearchField
																data={v?.paymentClass}
																paymentEnum={PaymentClass.NonGuest}
																tempState={tempState}
																reducerState={state?.nonGuestSearchField}
																label={'Search Guest'}
																defaultListField={{
																	defaultField: defaultNonGuest,
																	paymentEnum: PaymentClass.NonGuest,
																	data: 'GuestName',
																	type: 'RoomNo',
																	balance: 'CreditBalance',
																}}
																listField={{
																	filterCondition: 'GuestType',
																	fieldKey: ['NonGuest'],
																	arg: 'RegistrationID',
																	filteredList: filteredList,
																	setSelectedListItem: setSelectedNonGuest,
																	selectedListItem: selectedNonGuest,
																	data: 'GuestName',
																	type: 'RoomNo',
																	balance: 'CreditBalance',
																	paymentEnum: PaymentClass.NonGuest,
																	register: register,
																	setValue: setValue,
																	defaultPayment: defaultPayment,
																}}
																selectedField={selectedNonGuest}
																defaultField={defaultNonGuest}
																selectedOrder={selectedOrder}
																setSelectedField={setSelectedNonGuest}
																total={watch(`${defaultPayment}.amountPayment`)}
																onChange={e => {
																	handleSearch(`"${e?.target?.value}"`, [
																		`GuestName`,
																		`RoomNo`,
																	]);
																	if (filteredList?.length === 1) {
																		setSelectedNonGuest(filteredList[0]);
																	}
																	setTempState(e?.target?.value);
																}}
																defaultValue={
																	selectedOrder?.patronAccountID
																		? getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
																				x =>
																					x?.RegistrationID ===
																						selectedOrder?.patronAccountID &&
																					x?.GuestType === 'NonGuest',
																		  )[0]?.GuestName
																		: null
																}
															/>
														)}

													{!MenuXInhouseListLoading &&
														filterCond?.folio &&
														v?.paymentClass == PaymentClass.SuspendFolio &&
														watch(`${defaultPayment}.selected`) && (
															<SearchField
																data={v?.paymentClass}
																paymentEnum={PaymentClass.SuspendFolio}
																tempState={tempState}
																reducerState={state.folioSearchField}
																label={'Search Suspend Folio'}
																defaultListField={{
																	defaultField: defaultFolio,
																	paymentEnum: PaymentClass.SuspendFolio,
																	data: 'GuestName',
																	type: 'RoomNo',
																	balance: 'CreditBalance',
																}}
																listField={{
																	filterCondition: 'GuestType',
																	fieldKey: ['SuspendFolio'],
																	arg: 'RegistrationID',
																	filteredList: filteredList,
																	setSelectedListItem: setSelectedFolio,
																	selectedListItem: selectedFolio,
																	data: 'GuestName',
																	type: 'RoomNo',
																	balance: 'CreditBalance',
																	paymentEnum: PaymentClass.SuspendFolio,
																	register: register,
																	setValue: setValue,
																	defaultPayment: defaultPayment,
																}}
																selectedField={selectedFolio}
																defaultField={defaultFolio}
																selectedOrder={selectedOrder}
																setSelectedField={setSelectedFolio}
																total={watch(`${defaultPayment}.amountPayment`)}
																onChange={e => {
																	handleSearch(`"${e?.target?.value}"`, [
																		`GuestName`,
																		`RoomNo`,
																	]);
																	if (filteredList?.length === 1) {
																		setSelectedFolio(filteredList[0]);
																	}
																	setTempState(e?.target?.value);
																}}
																defaultValue={
																	selectedOrder?.patronAccountID
																		? getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
																				x =>
																					x?.RegistrationID ===
																						selectedOrder?.patronAccountID &&
																					x?.GuestType === 'SuspendFolio',
																		  )[0]?.GuestName
																		: null
																}
															/>
														)}

													{!MenuXDebtorListLoading &&
														filterCond?.debtor &&
														v?.paymentClass == PaymentClass.Debtor &&
														watch(`${defaultPayment}.selected`) && (
															<SearchField
																data={v?.paymentClass}
																paymentEnum={PaymentClass.Debtor}
																tempState={tempState}
																reducerState={state?.debtorSearchField}
																label={'Search Hotel Debtor'}
																defaultListField={{
																	defaultField: defaultDebtor,
																	paymentEnum: PaymentClass.Debtor,
																	balance: 'CreditBalance',
																	data: 'GuestName',
																	type: 'GuestType',
																}}
																listField={{
																	filterCondition: 'GuestStatus',
																	fieldKey: ['DEBTOR'],
																	arg: 'RegistrationID',
																	filteredList: filteredList,
																	setSelectedListItem: setSelectedDebtor,
																	selectedListItem: selectedDebtor,
																	balance: 'CreditBalance',
																	data: 'GuestName',
																	type: 'GuestType',
																	paymentEnum: PaymentClass.Debtor,
																	register: register,
																	setValue: setValue,
																	defaultPayment: defaultPayment,
																}}
																selectedField={selectedDebtor}
																defaultField={defaultDebtor}
																selectedOrder={selectedOrder}
																setSelectedField={setSelectedDebtor}
																total={watch(`${defaultPayment}.amountPayment`)}
																onChange={e => {
																	handleSearch(`'"${e?.target?.value}"`, [
																		`GuestType`,
																		`GuestName`,
																	]);
																	if (filteredList?.length === 1) {
																		setSelectedDebtor(filteredList[0]);
																	}
																	setTempState(e?.target?.value);
																}}
																defaultValue={
																	selectedOrder?.registrationID
																		? getMenuXDebtorList?.data?.MenuXDebtorList?.filter(
																				x =>
																					x?.RegistrationID ===
																					selectedOrder?.registrationID,
																		  )[0]?.GuestName
																		: null
																}
															/>
														)}

													{!loadingOfficer &&
														filterCond?.officer &&
														v?.paymentClass == PaymentClass.Officer &&
														watch(`${defaultPayment}.selected`) && (
															<SearchField
																data={v?.paymentClass}
																paymentEnum={PaymentClass.Officer}
																tempState={tempState}
																reducerState={state?.officerSearchField}
																label={'Search Officer List'}
																defaultListField={{
																	defaultField: defaultOfficer,
																	paymentEnum: PaymentClass.Officer,
																	data: 'name',
																	type: 'designation',
																	balance: 'mobileNo',
																}}
																listField={{
																	fieldKey: ['OFFICER'],
																	arg: 'ID',
																	filteredList: filteredList,
																	setSelectedListItem: setSelectedOfficer,
																	selectedListItem: selectedOfficer,
																	balance: 'mobileNo',
																	data: 'name',
																	type: 'designation',
																	paymentEnum: PaymentClass.Officer,
																	register: register,
																	setValue: setValue,
																	defaultPayment: defaultPayment,
																}}
																selectedField={selectedOfficer}
																defaultField={defaultOfficer}
																selectedOrder={selectedOrder}
																setSelectedField={setSelectedOfficer}
																total={total}
																onChange={e => {
																	handleSearch(`'"${e?.target?.value}"`, [
																		'name',
																		'designation',
																	]);
																	if (filteredList?.length === 1) {
																		setSelectedOfficer(filteredList[0]);
																	}
																	setTempState(e?.target?.value);
																}}
																defaultValue={
																	selectedOrder?.patronAccountID
																		? getOfficer?.filter(
																				x =>
																					x?.ID ===
																					selectedOrder?.patronAccountID,
																		  )[0]?.name
																		: null
																}
															/>
														)}

													{!loadingStaff &&
														filterCond?.staff &&
														v?.paymentClass == PaymentClass.Staff &&
														watch(`${defaultPayment}.selected`) && (
															<SearchField
																data={v?.paymentClass}
																paymentEnum={PaymentClass.Staff}
																tempState={tempState}
																reducerState={state?.staffSearchField}
																label={'Search Staff List'}
																defaultListField={{
																	defaultField: defaultStaff,
																	paymentEnum: PaymentClass.Staff,
																	data: 'name',
																	type: 'designation',
																	balance: 'mobileNo',
																}}
																listField={{
																	fieldKey: ['STAFF'],
																	arg: 'ID',
																	filteredList: filteredList,
																	setSelectedListItem: setSelectedStaff,
																	selectedListItem: selectedStaff,
																	balance: 'mobileNo',
																	data: 'name',
																	type: 'designation',
																	paymentEnum: PaymentClass.Staff,
																	register: register,
																	setValue: setValue,
																	defaultPayment: defaultPayment,
																}}
																selectedField={selectedStaff}
																defaultField={defaultStaff}
																selectedOrder={selectedOrder}
																setSelectedField={setSelectedStaff}
																total={total}
																onChange={e => {
																	handleSearch(`'"${e?.target?.value}"`, [
																		'designation',
																		'name',
																	]);
																	if (filteredList?.length === 1) {
																		setSelectedStaff(filteredList[0]);
																	}
																	setTempState(e?.target?.value);
																}}
																defaultValue={
																	selectedOrder?.patronAccountID
																		? getStaff?.filter(
																				x =>
																					x?.ID ===
																					selectedOrder?.patronAccountID,
																		  )[0]?.name
																		: null
																}
															/>
														)}
												</>
											</>
										);
									})}
							</RadioGroup>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										reset();
										dispatch({ type: 'receivedAmount', payload: 0 });
										dispatch({ type: 'paymentDialog', payload: false });
										setSelectedRoom({});
										setSelectedNonGuest({});
										setSelectedFolio({});
										setSelectedDebtor({});
										setSelectedOfficer({});
										setSelectedCredit({});
										setSelectedStaff({});
										setIsTouch(false);
										dispatch({ type: 'radioError', payload: false });
										setCancel(true);
										handleSubmit(data => {
											const { defaultPayment } = data;
											setArray(
												defaultPayment?.filter(x => x?.selected === true),
											);
										})();
										dispatch({ type: 'disablePaymentClass', payload: null });
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										if (isSignature() === true) {
											dispatch({ type: 'signatureDialog', payload: true });
										} else if (isSignature() === false) {
											handleSubmit(data => onSubmit(data))();
										}
									},
									variant: 'contained',
									color:
										(state?.receivedAmount === 0 && balanceT !== 0) ||
										balanceT > 0 ||
										(array?.filter(x => x?.paymentClass === PaymentClass.Credit)
											?.length > 0 &&
											watch('cardReference') === undefined) ||
										(array?.filter(x => x?.paymentClass === PaymentClass.Credit)
											?.length > 0 &&
											watch('cardType') === undefined) ||
										hasAnyIssue ||
										loading1 ||
										loading2
											? 'inherit'
											: 'primary',
									disabled:
										(state?.receivedAmount === 0 && balanceT !== 0) ||
										balanceT > 0 ||
										(array?.filter(x => x?.paymentClass === PaymentClass.Credit)
											?.length > 0 &&
											watch('cardReference') === undefined) ||
										(array?.filter(x => x?.paymentClass === PaymentClass.Credit)
											?.length > 0 &&
											watch('cardType') === undefined) ||
										hasAnyIssue ||
										loading1 ||
										loading2,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const SignatureDialog = (props: any) => {
	const {
		handleSubmit,
		onSubmit,
		state,
		dispatch,
		footerAmount,
		loading1,
		loading2,
	} = props;

	const {
		files,
		handleUploadChange,
		previewFiles,
		remove: removeFile,
		handleEditUpload,
		setFiles,
	} = useUploadAttachment();

	const [signatureImage, setSignatureImage] = useState(null);

	let sigCanvas = {};

	const clear = () => {
		const signature: any = sigCanvas;
		signature.clear();
	};

	const saveSigCanvas = () => {
		const signature: any = sigCanvas;
		signature.getTrimmedCanvas().toBlob(blob => {
			setSignatureImage(blob);
		});
	};

	useEffect(() => {
		if (signatureImage) {
			signatureImage['name'] = 'sigName';
			const newFiles = [signatureImage];
			setFiles([...newFiles]);
			handleSubmit(data => onSubmit(data, newFiles))();
		}
	}, [signatureImage]);

	return (
		<>
			<CommonDialog
				fullWidth
				open={state?.signatureDialog}
				onClose={() => dispatch({ type: 'signatureDialog', payload: false })}
				sections={{
					header: {
						dynamic: (
							<>
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="flex session">
												<div
													className="title highlight-text flex-space"
													style={{ color: '#ff9800' }}
												>
													Signature
												</div>
												<div className="highlight-text title">{`$${amtStr(
													footerAmount?.total,
												)}`}</div>
											</div>
										</div>
									</div>
									<div className="infoline-content ">
										<div className="xsTitle infoline">
											{`${state?.selectedOrder?.table?.prefix}${state?.selectedOrder?.table?.tableNumber} ${state?.selectedOrder?.docNo}`}
										</div>
									</div>
								</div>
							</>
						),
					},
					body: () => (
						<>
							<Grid container style={{ paddingTop: '10px' }}>
								<Grid item xs={8}>
									<span className="sigTitleText"> Sign Here </span>
								</Grid>
								<Grid item xs={4} style={{ textAlign: 'right' }}>
									<span onClick={clear} className="highlight-text">
										{' '}
										Reset
									</span>
								</Grid>
							</Grid>

							<SignatureCanvas
								ref={ref => {
									sigCanvas = ref;
								}}
								penColor="black"
								canvasProps={{
									className: 'sigCanvas',
								}}
							/>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										dispatch({ type: 'signatureDialog', payload: false });
									},

									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Save',
								props: {
									onClick: () => {
										saveSigCanvas();
										dispatch({ type: 'signatureDialog', payload: false });
										dispatch({ type: 'paymentDialog', payload: false });
									},
									variant: 'contained',
									color: loading1 || loading2 ? 'inherit' : 'primary',
									disabled: loading1 || loading2 ? true : false,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
