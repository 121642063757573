import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import React, { Reducer, useContext, useEffect, useReducer } from 'react';
import { formatTime } from 'helpers/hooks/formatTime';
import { useHistory, useParams } from 'react-router';
import { IAction } from 'helpers/model';
import {
	GetAllOrderItemDocument,
	GetHomeTableSummaryDocument,
	GetOrderDocument,
	OrderItemDiscountStatus,
	OrderItemStatus,
	OrderStatus,
	useGetAllOrderItemLazyQuery,
	useGetCurrentMealPeriodLazyQuery,
	useGetHomeTableSummaryLazyQuery,
	useGetOrderLazyQuery,
	useGetOutletNameQuery,
	useGetReasonLazyQuery,
	useGetRoundingPolicyQuery,
	useGetVoidedDiscountQuery,
	usePostPrintDataMutation,
	useVoidOrderItemWithOptionsMutation,
	useVoidOrderItemWithSetMenuOptionsMutation,
} from 'generated/graphql';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import {
	FooterAmount,
	generateRoundingAmount,
	NameByCustomerType,
} from '../SharedFunction';
import { amtStr } from 'helpers/numFormatter';
import EditIcon from '@material-ui/icons/Edit';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import {
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	useMediaQuery,
} from '@material-ui/core';
import PickupIcon from 'assets/icons/pickup_grey.svg';
import BigNumber from 'bignumber.js';
import './confirmOrder.scss';
import { AmountFooter } from '../AmountFooter';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { VoidDialog } from './VoidDialog';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import theme from '@ifca-root/react-component/src/assets/theme';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'helpers/SystemMsg';
import { ConfirmOrderFooter } from './ConfirmOrderFooter';
import AppContext from 'containers/App/Store/AppContext';
import { TransferItemDialog } from '../TransferItem/TransferItemDialog';
import DeleteItem from 'assets/icons/deleteMenux.svg';
import {
	StyledMenu,
	StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu';
import { CloseTableDialog } from '../CloseTable/CloseTableDialog';
import { CloseOrderDialog } from '../CloseTable/CloseOrderDialog';
import AuditIcon from 'assets/icons/audit.svg';

export interface PaymentItemProps {
	key?: typeof String;
	receivedAmount: any;
	cardType: String;
	cardNo: Number;
	quantity: number;
}

interface Props {
	outletID: any;
	tableID: any;
	currentMealPeriod: any;
	selectedOrder: any;
	orderID: any;
	negOrderItem: any;
	itemArray: any;
	voidDialog: boolean;
	deductionQuantity: number;
	remark: any;
	reasonCode: any;
	isSubmitting: boolean;
	transferItemDialog: boolean;
	itemTransferArray: any;
	closeTableDialog: boolean;
	closeOrderDialog: boolean;
}

export const ConfirmOrder = () => {
	const history = useHistory();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const { tableID, outletID, orderID } = useParams<Record<string, any>>();

	const initialState: Props = {
		outletID: outletID,
		tableID: tableID,
		orderID: orderID,
		currentMealPeriod: null,
		selectedOrder: null,
		negOrderItem: {},
		itemArray: [],
		voidDialog: false,
		deductionQuantity: 1,
		remark: null,
		reasonCode: null,
		isSubmitting: false,
		transferItemDialog: false,
		itemTransferArray: [],
		closeTableDialog: false,
		closeOrderDialog: false,
	};

	const reducer: Reducer<Props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const {
		anchorEl: anchorElCO,
		menu: menuCO,
		handleClick: handleClickCO,
		handleClose: handleCloseCO,
	} = useMenuOption();

	const { globalState }: any = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [setVariables, name] = NameByCustomerType();

	const yupSchema = yup.object().shape({});

	const {
		register,
		control,
		handleSubmit,
		errors,
		clearErrors,
		watch,
		setValue,
	} = useForm<PaymentItemProps>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	// API
	const {
		loading: outletLoading,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
			loadHomeTableSummary({
				variables: {
					tableID: tableID,
				},
			});
			LoadOrder({
				variables: {
					tableID: state?.tableID,
					status: OrderStatus.Open,
					outletID: state?.outletID,
				},
			});
		},
	});

	const [
		LoadOrder,
		{ data: { getOrder } = { getOrder: [] }, loading: orderLoading },
	] = useGetOrderLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOrder }) => {
			dispatch({
				type: 'selectedOrder',
				payload: getOrder?.filter(order => order?.ID === state?.orderID)[0],
			});
			setVariables({
				order: getOrder?.filter(order => order?.ID === state?.orderID)[0],
				outletID: state?.outletID,
				hotelID: getOutlet[0]?.outletChannel?.hotelID,
			});
		},
	});

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			dispatch({
				type: 'currentMealPeriod',
				payload: getCurrentMealPeriod,
			});
		},
	});

	const [
		loadHomeTableSummary,
		{ loading: homeTableSummaryLoading, data: homeTableSummaryData },
	] = useGetHomeTableSummaryLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: () => {
			if (closeAmount <= 0) {
				dispatch({ type: 'closeTableDialog', payload: true });
			}
		},
	});

	const {
		data: { getRoundingPolicy } = { getRoundingPolicy: [] },
		loading: getRoundingPolicyLoading,
	} = useGetRoundingPolicyQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	const [
		LoadOrderItem,
		{
			loading: useGetAllOrderItemLoading,
			called: useGetAllOrderItemCalled,
			data: { getAllOrderItem } = { getAllOrderItem: [] },
		},
	] = useGetAllOrderItemLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getAllOrderItem }) => {},
	});

	const [
		loadReason,
		{ loading: reasonLoading, data: { getReason } = { getReason: [] } },
	] = useGetReasonLazyQuery({
		fetchPolicy: 'network-only',
	});

	const {
		data: { getVoidedDiscount } = { getVoidedDiscount: [] },
		loading: voidedDiscountLoading,
	} = useGetVoidedDiscountQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderID: orderID,
			outletID: outletID,
		},
		onError: error => {
			console.log('ERROR', error);
		},
	});

	const [postPrintData] = usePostPrintDataMutation({
		fetchPolicy: 'network-only',
	});

	const [
		voidOrderItemWithOptions,
		{
			data: voidOrderItemWithOptionsData,
			loading: voidOrderItemWithOptionsLoading,
		},
	] = useVoidOrderItemWithOptionsMutation({
		onError: error => {
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			history.push(`/menu/outlet-app/${outletID}/table/grid`);
		},
		onCompleted: data => {
			LoadOrderItem({
				variables: {
					orderID: orderID,
					outletID: state?.outletID,
				},
			});
			loadHomeTableSummary({
				variables: {
					tableID: tableID,
				},
			});
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecord());
			dispatch({ type: 'isSubmitting', payload: false });
			dispatch({ type: 'voidDialog', payload: false });
		},
	});

	const [
		voidOrderItemWithSetMenuOptions,
		{
			data: voidOrderItemWithSetMenuOptionsData,
			loading: voidOrderItemWithSetMenuOptionsLoading,
		},
	] = useVoidOrderItemWithSetMenuOptionsMutation({
		onError: error => {
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			history.push(`/menu/outlet-app/${outletID}/table/grid`);
		},
		onCompleted: data => {
			LoadOrderItem({
				variables: {
					orderID: orderID,
					outletID: state?.outletID,
				},
			});
			loadHomeTableSummary({
				variables: {
					tableID: tableID,
				},
			});
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecord());
			dispatch({ type: 'isSubmitting', payload: false });
			dispatch({ type: 'voidDialog', payload: false });
		},
	});

	// useEffect
	useEffect(() => {
		if (!useGetAllOrderItemCalled) {
			LoadOrderItem({
				variables: {
					orderID: state?.orderID,
					outletID: state?.outletID,
				},
			});
		}
	}, [useGetAllOrderItemCalled]);

	useEffect(() => {
		LoadOrderItem({
			variables: {
				orderID: state?.orderID,
				outletID: state?.outletID,
			},
		});
	}, [state?.selectedOrder?.ID]);

	useEffect(() => {
		if (getAllOrderItem?.length > 0) {
			let unpaidOrderItem = getAllOrderItem?.filter(
				item => item?.billID === null,
			);
			let temp = {};

			unpaidOrderItem?.map(el => {
				let totalTax = 0;
				el?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				const activeOID = el?.orderItemDiscount?.filter(
					i => i?.status === OrderItemDiscountStatus?.Active,
				)[0];

				let totalOIDTax = 0;
				activeOID?.orderItemDiscountTax?.map(v => {
					totalOIDTax += v?.taxAmount;
				});

				let totalODITax = 0;
				el?.orderDiscountItem?.orderDiscountItemTax?.map(v => {
					totalODITax += v?.taxAmount;
				});

				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID].quantity += el?.quantity * -1;
						temp[el?.refID].amount += el?.amount;
						temp[el?.refID].taxAmount += totalTax;

						temp[el?.refID].orderItemDiscBaseAmount += activeOID?.baseAmount;
						temp[el?.refID].orderItemDiscTaxAmount += totalOIDTax;

						temp[el?.refID].orderDiscountItemBaseAmount +=
							el?.orderDiscountItem?.baseAmount;
						temp[el?.refID].orderDiscountItemTaxAmount += totalODITax;
					} else {
						temp[el?.refID] = {
							quantity: el?.quantity * -1,
							amount: el?.amount * -1,
							taxAmount: totalTax * -1,

							orderItemDiscBaseAmount:
								isNaN(activeOID?.baseAmount * -1) === true
									? 0
									: activeOID?.baseAmount * -1,
							orderItemDiscTaxAmount:
								isNaN(totalOIDTax * -1) === true ? 0 : totalOIDTax * -1,

							orderDiscountItemBaseAmount:
								isNaN(el?.orderDiscountItem?.baseAmount * -1) === true
									? 0
									: el?.orderDiscountItem?.baseAmount * -1,
							orderDiscountItemTaxAmount:
								isNaN(totalODITax * -1) === true ? 0 : totalODITax * -1,

							orderItemOptions: [],
						};

						// Loop through the orderItemOptionArray
						for (let i = 0; i < el?.orderItemOption?.length; i++) {
							const option = el?.orderItemOption[i];
							const optionItem = el?.orderItemOption[i]?.orderItemOptionItem;

							let optionData;

							if (optionItem) {
								optionItem?.map(x => {
									let totalTax = 0;
									x?.orderItemOptionItemTax?.map(v => {
										totalTax += v?.taxAmount;
									});

									let totalOptItemOIDTax = 0;
									x?.orderItemOptionItemDiscountTax?.map(v => {
										totalOptItemOIDTax += v?.taxAmount;
									});

									let totalOptItemODITax = 0;
									x?.orderDiscountItemOptionTax?.map(v => {
										totalOptItemODITax += v?.taxAmount;
									});

									optionData = {
										optionID: option?.optionID,
										orderItemOptionItem: {
											basePrice: x?.basePrice * -1,
											taxAmount: totalTax * -1,
											discountBaseAmount: x?.discountBaseAmount * -1,
											itemDiscountTaxAmount: totalOptItemOIDTax * -1,
											discountItemTaxAmount: totalOptItemODITax * -1,
										},
									};
								});
								temp[el?.refID].orderItemOptions.push(optionData);
							}
						}
					}
				}
			});

			const filtered = unpaidOrderItem
				?.map(el => {
					if (el?.quantity - temp[el?.ID]?.quantity <= 0) {
						return { ...el, void: true };
					} else {
						return { ...el, void: false };
					}
				})
				?.filter(list => list.void === false);

			dispatch({ type: 'negOrderItem', payload: { ...temp } });
			dispatch({ type: 'itemArray', payload: filtered });
		}
	}, [getAllOrderItem?.length]);

	const footerAmount = FooterAmount(
		getAllOrderItem,
		'confirmOrder',
		getRoundingPolicy,
	);
	const closeAmount = homeTableSummaryData?.close?.closeAmount;

	useEffect(() => {
		if (footerAmount?.total <= 0) {
			if (closeAmount <= 0) {
				dispatch({ type: 'closeTableDialog', payload: true });
				dispatch({ type: 'closeOrderDialog', payload: false });
			} else if (closeAmount > 0) {
				dispatch({ type: 'closeOrderDialog', payload: true });
				dispatch({ type: 'closeTableDialog', payload: false });
			} else {
				dispatch({ type: 'closeTableDialog', payload: false });
				dispatch({ type: 'closeOrderDialog', payload: false });
			}
		} else {
			dispatch({ type: 'closeTableDialog', payload: false });
			dispatch({ type: 'closeOrderDialog', payload: false });
		}
	}, [closeAmount, footerAmount?.total]);

	// function
	const onClickVoidIcon = (e, orderItemID, index, orderItem) => {
		dispatch({ type: 'voidDialog', payload: 'true' });
		loadReason({
			variables: {
				outletID: outletID,
			},
		});
		handleClick(e, orderItemID, index, orderItem);
	};

	const onSubmit = (data, blob) => {
		if (menu?.obj?.menuItem?.isSetMenu === false) {
			voidOrderItemWithOptions({
				variables: {
					input: {
						orderItemID: menu?.ID,
						deductionQuantity: state?.deductionQuantity,
						reasonCode: data?.reasonCode,
						remark: data?.remark,
					},
					file: blob,
				},
				refetchQueries: [
					{
						query: GetAllOrderItemDocument,
						variables: { orderID, outletID: state?.outletID },
					},
					{
						query: GetOrderDocument,
						variables: {
							tableID: tableID,
							status: OrderStatus.Open,
							outletID,
						},
					},
					{
						query: GetHomeTableSummaryDocument,
						variables: {
							tableID: tableID,
						},
					},
				],
			});
		} else if (menu?.obj?.menuItem?.isSetMenu === true) {
			voidOrderItemWithSetMenuOptions({
				variables: {
					input: {
						orderItemID: menu?.ID,
						deductionQuantity: state?.deductionQuantity,
						reasonCode: data?.reasonCode,
						remark: data?.remark,
					},
				},
				refetchQueries: [
					{
						query: GetAllOrderItemDocument,
						variables: { orderID, outletID: state?.outletID },
					},
					{
						query: GetOrderDocument,
						variables: {
							tableID: tableID,
							status: OrderStatus.Open,
							outletID,
						},
					},
					{
						query: GetHomeTableSummaryDocument,
						variables: {
							tableID: tableID,
						},
					},
				],
			});
		}
	};

	const handlePrinting = async () => {
		let printerName = menu?.obj?.menuItem?.kitchenPrinter?.name;
		let orderItemIDs = menu?.ID;

		if (printerName) {
			await handleSubmit(onSubmit)().then(async result => {
				postPrintData({
					variables: {
						printData: {
							outletID,
							printerName,
							outletName: getOutlet[0]?.name,
							orderID: state?.orderID,
							orderItemIDs: [orderItemIDs],
							orderMode: 'void',
							voidQuantity: state?.deductionQuantity,
							reasonCode: state?.reasonCode,
							remark: state?.remark,
						},
					},
				});
			});
		} else {
			await handleSubmit(onSubmit)();
		}
	};

	const handleChangeOrder = (e, obj) => {
		if (obj?.ID !== state?.orderID) {
			dispatch({
				type: 'selectedOrder',
				payload: getOrder?.filter(order => order?.ID === obj?.ID)[0],
			});
			dispatch({ type: 'orderID', payload: obj?.ID });
			history.push({
				pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/order/${state?.orderID}/confirm-order`,
			});
		}
	};

	// const
	const currencyCode = getOutlet[0]?.currencyCode ?? '';
	const itemCount = homeTableSummaryData?.orderItemCount?.orderItem;
	const discountAmount =
		amtStr(
			Number(
				homeTableSummaryData?.billItemDiscount?.discountAmount || 0,
			).toFixed(2),
		) ?? 0;
	const roundingAmount =
		getRoundingPolicy[0]?.inUse === true
			? generateRoundingAmount(
					homeTableSummaryData?.close?.closeAmount,
					getRoundingPolicy[0]?.smallestDenomination,
					getRoundingPolicy,
			  )
			: 0;
	const totalAmount = amtStr(
		homeTableSummaryData?.orderItemCount?.totalAmount + roundingAmount,
	);
	const voidItemCount = homeTableSummaryData?.orderItemCount?.voidItem;
	const voidDiscountAmount = amtStr(
		getVoidedDiscount.reduce((sum, item) => sum + parseFloat(item?.amount), 0),
	);

	return (
		<>
			{outletLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			{homeTableSummaryLoading && <Loading />}
			{getRoundingPolicyLoading && <Loading />}
			{useGetAllOrderItemLoading && <Loading />}
			{orderLoading && <Loading />}

			<MainHeader
				onClick={() => {
					// dispatch({ type: 'closeTableDialog', payload: true });
					history.push({
						pathname: `/menu/outlet-app/${state?.outletID}/table/grid`,
						state: {
							selectedTable:
								state?.itemArray?.length === 0
									? state?.selectedOrder?.table
									: null,
						},
					});
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Confirm Order', current: true },
				]}
				rightRouteSegments={[
					{
						name: state?.currentMealPeriod
							? `${state?.currentMealPeriod?.name}: ${formatTime(
									state?.currentMealPeriod?.startTime,
							  )} - ${formatTime(state?.currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>

			<DynamicSubHeader
				title={
					(state?.selectedOrder !== null ||
						state?.selectedOrder !== undefined) && (
						<>
							<span className="highlight" style={{ color: '#ff9800' }}>
								{`${state?.selectedOrder?.table?.prefix || ''}${state
									?.selectedOrder?.table?.tableNumber || ''}`}
							</span>
							<span style={{ paddingLeft: '4px' }}>
								{`${state?.selectedOrder?.docNo || ''}`}
							</span>
							<span style={{ paddingLeft: '6px' }}>
								{`${state?.selectedOrder?.patron?.patronClass || ''}`}
							</span>
						</>
					)
				}
				handleClick={e => handleClickCO(e, state?.orderID, 0)}
				dropDownOptions={
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorElCO}
						keepMounted
						open={Boolean(anchorElCO)}
						onClose={handleCloseCO}
						onClick={handleCloseCO}
					>
						{getOrder.map((order, index) => (
							<StyledMenuItem
								key={index}
								value={order.ID}
								defaultValue={order.ID}
								onClick={e => {
									handleChangeOrder(e, order);
								}}
							>
								<ListItemText primary={order?.docNo} />
							</StyledMenuItem>
						))}
					</StyledMenu>
				}
				rightText={
					state?.selectedOrder !== null && (
						<div>
							{`${state?.selectedOrder?.pax} Pax`}{' '}
							<span className="desc" onClick={() => {}}>
								<EditIcon
									style={{ fontSize: '15px' }}
									onClick={() => {
										history.push({
											pathname: `/menu/outlet-app/${outletID}/table/grid/${state?.selectedOrder?.table?.ID}/edit/${state?.selectedOrder?.ID}`,
											state: {
												table: state?.selectedOrder?.table,
												orders: getOrder,
												orderID: state?.orderID,
												page: 'edit-order-info',
											},
										});
									}}
								/>
							</span>
						</div>
					)
				}
				infoLine={
					<>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<span className="desc">{name}</span>
							<span className="desc right-info">
								{`Total Item: ${itemCount ||
									0} | Disc: (${discountAmount}) | ${totalAmount}`}
							</span>
						</div>
					</>
				}
				subInfoLine={
					<>
						<div>
							<span
								className="desc"
								onClick={() => {
									history.push({
										pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}/payment/audit-log`,
										state: getOrder,
									});
								}}
							>
								<img
									src={AuditIcon}
									alt="ifca"
									width={12}
									loading="lazy"
									style={{
										paddingLeft: '4px',
										width: '18px',
										height: '18px',
									}}
								/>
							</span>
							<span className="desc right-info">
								{`Void Item: ${voidItemCount ||
									0} | Void Disc: ${voidDiscountAmount}`}
							</span>
						</div>
					</>
				}
			/>

			<ContentWrapper singleDynamicInfo multiLineSubheader footer>
				{getAllOrderItem === undefined || getAllOrderItem?.length === 0 ? (
					<EmptyList
						title="There are no orders found"
						subtitle="Please make a new order in the Menu "
					/>
				) : (
					<>
						<List
							style={{
								backgroundColor: 'white',
							}}
						>
							{state?.itemArray?.map((oi, index) => {
								const hasItemDisc =
									oi?.orderItemDiscount?.filter(
										disc => disc?.status === OrderItemDiscountStatus.Active,
									)?.length !== 0 ||
									(oi?.orderDiscountItem &&
										oi?.orderDiscountItem?.amount !== 0) ||
									(oi?.orderDiscountItem?.baseAmount > 0 &&
										oi?.orderDiscountItem?.amount === 0);

								let totalTax = 0;
								oi?.orderItemTax?.map(x => {
									totalTax += x?.taxAmount;
								});

								const baseItemAmountBill = oi?.amount + totalTax;

								const negBaseItemAmountBill =
									(state?.negOrderItem?.[oi?.ID]?.amount ?? 0) +
									(state?.negOrderItem?.[oi?.ID]?.taxAmount ?? 0);

								const activeOID = oi?.orderItemDiscount?.filter(
									el => el?.status === OrderItemDiscountStatus.Active,
								)[0];

								let totalOIDTax = 0;
								activeOID?.orderItemDiscountTax?.map(x => {
									totalOIDTax += x?.taxAmount;
								});

								const oidBA = activeOID?.baseAmount;
								const oidTx = totalOIDTax;

								const oid = oidBA + oidTx;

								let totalODITax = 0;
								oi?.orderDiscountItemTax?.map(x => {
									totalODITax += x?.taxAmount;
								});

								const odiTx = totalODITax;
								const odi = oi?.orderDiscountItem?.baseAmount + odiTx;

								const negItemDisc =
									(state?.negOrderItem?.[oi?.ID]?.orderItemDiscBaseAmount ??
										0) +
									(state?.negOrderItem?.[oi?.ID]?.orderItemDiscTaxAmount ?? 0);

								const negOrderDisc =
									(state?.negOrderItem?.[oi?.ID]?.orderDiscountItemBaseAmount ??
										0) +
									(state?.negOrderItem?.[oi?.ID]?.orderDiscountItemTaxAmount ??
										0);

								const discount = oid ? oid - negItemDisc : odi - negOrderDisc;
								return (
									<ListItem>
										<ListItemText
											style={
												oi?.status === OrderItemStatus.Voided ||
												oi?.status === OrderItemStatus.TransferOut
													? { paddingRight: '31px' }
													: null
											}
											primary={
												<>
													<Grid container>
														<Grid item xs={1}>
															<span className="xsTitle text-noflow">
																{`${(index + 1).toString().padStart(2, '0')}.`}
															</span>
														</Grid>
														<Grid item xs={8}>
															<span
																className="xsTitle text-noflow"
																style={
																	oi?.status === OrderItemStatus.Voided ||
																	oi?.status === OrderItemStatus.TransferOut
																		? { textDecoration: 'line-through' }
																		: null
																}
															>
																{oi?.openItemName ?? oi.menuItem?.name}&nbsp;
																&nbsp;
																{oi?.status === OrderItemStatus.Voided ||
																oi?.status === OrderItemStatus.TransferOut
																	? `x${Math.abs(oi.quantity)}`
																	: `x${oi?.quantity -
																			(state?.negOrderItem?.[oi?.ID]
																				?.quantity ?? 0)}`}
																{oi?.isTakeaway && (
																	<img
																		className="list-takeaway-logo"
																		src={PickupIcon}
																	/>
																)}
															</span>
														</Grid>

														<Grid item xs={3}>
															{hasItemDisc && (
																<span
																	className="mdDesc xsTitle right-info"
																	style={{
																		textDecoration: 'line-through ',
																		padding: '7px 0px 4px 4px',
																	}}
																>
																	{`${currencyCode}${amtStr(
																		Math.abs(
																			new BigNumber(baseItemAmountBill)
																				.minus(negBaseItemAmountBill ?? 0)
																				.toNumber(),
																		),
																	)}`}
																</span>
															)}
															<span
																className="highlight-text flex-space xsTitle right-info"
																style={
																	oi?.status === OrderItemStatus.Voided ||
																	oi?.status === OrderItemStatus.TransferOut
																		? {
																				padding: '7px 0px 4px 4px',
																				textDecoration: 'line-through',
																		  }
																		: { padding: '7px 0px 4px 4px' }
																}
															>
																{hasItemDisc
																	? `${currencyCode}${amtStr(
																			Math.abs(
																				new BigNumber(baseItemAmountBill)
																					.minus(negBaseItemAmountBill ?? 0)
																					.minus(discount ?? 0)
																					.toNumber(),
																			),
																	  )}`
																	: oi.status === OrderItemStatus.Voided ||
																	  oi?.status === OrderItemStatus.TransferOut
																	? `${currencyCode}${amtStr(
																			Math.abs(
																				new BigNumber(baseItemAmountBill)
																					.minus(negBaseItemAmountBill ?? 0)
																					.toNumber(),
																			),
																	  )}`
																	: `${currencyCode}${amtStr(
																			Math.abs(
																				new BigNumber(baseItemAmountBill)
																					.minus(negBaseItemAmountBill ?? 0)
																					.toNumber(),
																			),
																	  )}`}
															</span>
														</Grid>
													</Grid>
												</>
											}
											secondary={
												<>
													<Grid container>
														{oi?.orderItemOption?.length
															? oi?.orderItemOption?.map(oio => (
																	<>
																		{oio?.orderItemOptionItem?.map(
																			oioptitem => {
																				let totalTax = 0;
																				oioptitem?.orderItemOptionItemTax?.map(
																					x => {
																						totalTax += x?.taxAmount;
																					},
																				);

																				const baseOptItemAmountBill =
																					oioptitem?.basePrice + totalTax;

																				const negOptBaseItemAmountBill =
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.basePrice ?? 0) +
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.taxAmount ?? 0);

																				let totalOptItemOIDTax = 0;
																				oioptitem?.orderItemOptionItemDiscountTax?.map(
																					v => {
																						totalOptItemOIDTax += v?.taxAmount;
																					},
																				);

																				let totalOptItemODITax = 0;
																				oioptitem?.orderDiscountItemOptionTax?.map(
																					v => {
																						totalOptItemODITax += v?.taxAmount;
																					},
																				);

																				const discOptItemAmountBill =
																					oioptitem?.discountBaseAmount +
																					totalOptItemOIDTax +
																					totalOptItemODITax;

																				const negDiscOptBaseItemAmountBill =
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.discountBaseAmount ?? 0) +
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.itemDiscountTaxAmount ?? 0) +
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.discountItemTaxAmount ?? 0);

																				const optItemDiscount =
																					discOptItemAmountBill -
																					negDiscOptBaseItemAmountBill;
																				return (
																					<>
																						<Grid item xs={8}>
																							<span className="mdDesc">
																								{oioptitem?.optionItem?.name}
																							</span>
																						</Grid>
																						{
																							<Grid item xs={4}>
																								<span
																									className="mdDesc right-info"
																									style={{
																										marginTop: '4px',
																									}}
																								>
																									{`${currencyCode}${amtStr(
																										new BigNumber(
																											baseOptItemAmountBill,
																										)
																											.minus(
																												negOptBaseItemAmountBill ??
																													0,
																											)
																											.minus(
																												optItemDiscount ?? 0,
																											)
																											.toNumber(),
																									)}`}
																								</span>
																							</Grid>
																						}
																					</>
																				);
																			},
																		)}
																	</>
															  ))
															: oi?.orderItemSetMenuOption?.length
															? oi?.orderItemSetMenuOption?.map(v => (
																	<>
																		{v?.orderItemSetMenuOptionItem?.map(
																			oisetmenuoptitem => (
																				<Grid item xs={8}>
																					<span className="mdDesc">
																						{
																							oisetmenuoptitem
																								?.setMenuOptionItem?.menuItem
																								?.name
																						}
																					</span>
																				</Grid>
																			),
																		)}
																	</>
															  ))
															: null}
													</Grid>
												</>
											}
										/>

										{oi?.status === OrderItemStatus.Voided ||
										oi?.status === OrderItemStatus.TransferOut ? null : (
											<ListItemSecondaryAction
												style={{ top: '7px', transform: 'inherit' }}
											>
												<IconButton
													edge="end"
													aria-label="more"
													aria-controls="menu-list"
													aria-haspopup="true"
													onClick={e => {
														onClickVoidIcon(e, oi?.ID, index, oi);
													}}
													// disabled={handlePermDisabled({
													// 	outletID: outletID,
													// 	permEnum:
													// 		PosPermission.HomeTableLayoutAddEditOrderItemVoidOrderItemVoid,
													// })}
												>
													<img src={DeleteItem} />
												</IconButton>
											</ListItemSecondaryAction>
										)}
									</ListItem>
								);
							})}
						</List>

						<AmountFooter
							style={{ backgroundColor: 'white' }}
							currencyCode={getOutlet[0]?.currencyCode ?? ''}
							subtotal={amtStr(footerAmount.subtotal)}
							discount={amtStr(footerAmount.discount)}
							serviceTax={amtStr(footerAmount.tax)}
							total={amtStr(footerAmount.total)}
							rounding={amtStr(footerAmount.roundingAmount)}
							page={'confirmOrder'}
						/>
					</>
				)}
			</ContentWrapper>

			<ConfirmOrderFooter
				state={state}
				dispatch={dispatch}
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
					backgroundColor: '#F5F5F5',
				}}
			/>

			<VoidDialog
				state={state}
				dispatch={dispatch}
				menu={menu}
				setValue={setValue}
				watch={watch}
				control={control}
				register={register}
				isDesktop={isDesktop}
				getReason={getReason}
				handlePrinting={handlePrinting}
				voidOrderItemWithOptionsLoading={voidOrderItemWithOptionsLoading}
				voidOrderItemWithSetMenuOptionsLoading={
					voidOrderItemWithSetMenuOptionsLoading
				}
			/>

			<TransferItemDialog
				state={state}
				dispatch={dispatch}
				totalAmount={footerAmount?.total}
				reloadOrderItem={LoadOrderItem}
				backOrderID={orderID}
			/>

			<CloseTableDialog
				state={state}
				dispatch={dispatch}
				table={state?.selectedOrder?.table}
				getOrder={getOrder}
				orderItems={state?.itemArray}
			/>

			<CloseOrderDialog
				state={state}
				dispatch={dispatch}
				orderItems={state?.itemArray}
				order={state?.selectedOrder}
			/>
		</>
	);
};
