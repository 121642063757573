import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';

export const AuditDialog = (props: any) => {
	const {
		state,
		dispatch,
		getVoidedDiscount,
		currencyCode,
		getUsersByAccountAndSoftware,
	} = props;

	return (
		<>
			<CommonDialog
				open={state?.auditDialog}
				fullWidth
				onClose={() => {
					dispatch({ type: 'auditDialog', payload: false });
				}}
				sections={{
					header: {
						dynamic: (
							<>
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div
												className="title highlight-text flex-space"
												style={{ color: '#ff9800' }}
											>
												{'History'}
											</div>
										</div>
									</div>
								</div>
							</>
						),
					},
					body: () => (
						<>
							{getVoidedDiscount?.length === 0 ? (
								<EmptyList title="No item movement history found" />
							) : (
								<List className="core-list">
									{getVoidedDiscount?.map((el, index) => {
										return (
											<ListItem key={el?.ID}>
												<ListItemText
													primary={
														<>
															<span
																className="mdDesc"
																style={{ paddingRight: '5px' }}
															>
																{index + 1 + '.'}
															</span>
															<span className="xsTitle flex-space">
																{el?.name ? `${el.name} - ${el.type}` : el.type}
															</span>
															<span className="xsTitle right-info m-t-6">
																{el?.status}
															</span>
														</>
													}
													secondary={
														<>
															<span
																className="xsTitle flex-space"
																style={{ marginLeft: '20px' }}
															>
																{currencyCode + el?.amount}
																<span
																	className=" right-info"
																	style={{ marginLeft: '20px' }}
																>
																	{
																		getUsersByAccountAndSoftware?.filter(
																			x =>
																				x?.ID === el?.modBy ||
																				x?.ID === el?.createdBy,
																		)[0]?.name
																	}
																</span>
															</span>

															<span className="extra-row">
																<span
																	className="icon-text desc text-overflow flex-space"
																	style={{ marginLeft: '20px' }}
																>
																	{el?.reasonCode}
																</span>
															</span>

															<span className="extra-row">
																<span
																	className="icon-text desc text-overflow flex-space"
																	style={{ marginLeft: '20px' }}
																>
																	{el?.remark}
																</span>
															</span>
														</>
													}
												/>
											</ListItem>
										);
									})}
								</List>
							)}
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => {
										dispatch({ type: 'auditDialog', payload: false });
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
