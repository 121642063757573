import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import {
	CalcType,
	CommonStatus,
	GetAllOrderItemDocument,
	GetBillDiscountOrderDocument,
	GetOrderDiscountDocument,
	GetOrderDocument,
	GetOrderItemDiscountDocument,
	GetVoidedDiscountDocument,
	OrderDiscountStatus,
	OrderItemDiscountStatus,
	OrderItemStatus,
	OrderStatus,
	useCreateOrderDiscountMutation,
	useCreateOrderItemDiscountMutation,
	useDeleteOrderDiscountMutation,
	useDeleteOrderItemDiscountMutation,
	useGetAllOrderItemLazyQuery,
	useGetCurrentMealPeriodLazyQuery,
	useGetDiscountQuery,
	useGetFilteredDiscountLazyQuery,
	useGetHomeTableSummaryLazyQuery,
	useGetOrderLazyQuery,
	useGetOutletNameQuery,
	useGetReasonLazyQuery,
	useGetRoundingPolicyQuery,
	useGetUsersByAccountAndSoftwareQuery,
	useGetVoidedDiscountQuery,
} from 'generated/graphql';
import { formatTime } from 'helpers/hooks/formatTime';
import { IAction } from 'helpers/model';
import React, { Reducer, useContext, useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router';
import {
	FooterAmount,
	generateRoundingAmount,
	NameByCustomerType,
} from '../SharedFunction';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import AuditIcon from 'assets/icons/audit.svg';
import { amtStr } from 'helpers/numFormatter';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import {
	Checkbox,
	Grid,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	useMediaQuery,
} from '@material-ui/core';
import BigNumber from 'bignumber.js';
import PickupIcon from 'assets/icons/pickup_grey.svg';
import { AmountFooter } from '../AmountFooter';
import AppContext from 'containers/App/Store/AppContext';
import theme from '@ifca-root/react-component/src/assets/theme';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { OrderDiscountFooter } from './OrderDiscountFooter';
import { useForm } from 'react-hook-form';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'helpers/SystemMsg';
import {
	OrderDiscountDialog,
	OrderItemDiscountDialog,
	VoidDialog,
} from './CustomDialog';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import EditIcon from '@material-ui/icons/Edit';
import { AuditDialog } from './DiscountAuditDialog';
import {
	StyledMenu,
	StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu';
import { CloseTableDialog } from '../CloseTable/CloseTableDialog';
import { CloseOrderDialog } from '../CloseTable/CloseOrderDialog';

interface OrderItemDiscountProps {
	discountCode: string;
	discountID: string;
}
interface OrderDiscountProps {
	discountCode: string;
	discountID: string;
}
interface VoidDiscountProps {
	reasonCode: string;
	remark: string;
}

interface Props {
	outletID: any;
	tableID: any;
	orderID: any;
	currentMealPeriod: any;
	negOrderItem: any;
	itemArray: any;
	selectedOrder: any;
	OIDDialog: boolean;
	ODDialog: boolean;
	selectedOrderItem: any;
	voidDialog: boolean;
	partialPaid: boolean;
	auditDialog: boolean;
	closeTableDialog: boolean;
	closeOrderDialog: boolean;
}

export const Discount = () => {
	const history = useHistory();
	const { tableID, outletID, orderID } = useParams<Record<string, any>>();

	const { globalState }: any = useContext(AppContext as any);
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const {
		anchorEl: anchorElCO,
		menu: menuCO,
		handleClick: handleClickCO,
		handleClose: handleCloseCO,
	} = useMenuOption();

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const initialState: Props = {
		outletID: outletID,
		tableID: tableID,
		orderID: orderID,
		currentMealPeriod: null,
		negOrderItem: {},
		itemArray: [],
		selectedOrder: null,
		OIDDialog: false,
		ODDialog: false,
		selectedOrderItem: null,
		voidDialog: false,
		partialPaid: false,
		auditDialog: false,
		closeTableDialog: false,
		closeOrderDialog: false,
	};

	const reducer: Reducer<Props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	const [setVariables, name] = NameByCustomerType();

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const {
		register: OIDRegister,
		control: OIDControl,
		handleSubmit: OIDHandleSubmit,
	} = useForm<OrderItemDiscountProps>({
		defaultValues: {},
	});

	const {
		register: ODRegister,
		handleSubmit: ODHandleSubmit,
		control: ODControl,
	} = useForm<OrderDiscountProps>({
		defaultValues: {},
	});

	const {
		register: voidRegister,
		control: voidControl,
		handleSubmit: voidHandleSubmit,
		watch: voidWatch,
		errors: voidErrors,
	} = useForm<VoidDiscountProps>({
		defaultValues: {},
		mode: 'onSubmit',
	});

	// API
	const {
		loading: outletLoading,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
			loadHomeTableSummary({
				variables: {
					tableID: tableID,
				},
			});
			LoadOrder({
				variables: {
					tableID: state?.tableID,
					status: OrderStatus.Open,
					outletID: state?.outletID,
				},
			});
		},
	});

	const [
		LoadOrder,
		{ data: { getOrder } = { getOrder: [] }, loading: orderLoading },
	] = useGetOrderLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOrder }) => {
			dispatch({
				type: 'selectedOrder',
				payload: getOrder?.filter(order => order?.ID === state?.orderID)[0],
			});
			setVariables({
				order: getOrder?.filter(order => order?.ID === state?.orderID)[0],
				outletID: state?.outletID,
				hotelID: getOutlet[0]?.outletChannel?.hotelID,
			});
		},
	});

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			dispatch({
				type: 'currentMealPeriod',
				payload: getCurrentMealPeriod,
			});
		},
	});

	const [
		loadHomeTableSummary,
		{ loading: homeTableSummaryLoading, data: homeTableSummaryData },
	] = useGetHomeTableSummaryLazyQuery({
		fetchPolicy: 'network-only',
	});

	const {
		data: { getRoundingPolicy } = { getRoundingPolicy: [] },
		loading: getRoundingPolicyLoading,
	} = useGetRoundingPolicyQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	const [
		LoadOrderItem,
		{
			loading: useGetAllOrderItemLoading,
			called: useGetAllOrderItemCalled,
			data: { getAllOrderItem } = { getAllOrderItem: [] },
		},
	] = useGetAllOrderItemLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getAllOrderItem }) => {
			dispatch({
				type: 'partialPaid',
				payload:
					getAllOrderItem?.filter(item => item?.billID !== null)?.length !== 0,
			});

			let unpaidOrderItem = getAllOrderItem?.filter(
				item => item?.billID === null,
			);
			let temp = {};

			unpaidOrderItem?.map(el => {
				let totalTax = 0;
				el?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				const activeOID = el?.orderItemDiscount?.filter(
					i => i?.status === OrderItemDiscountStatus?.Active,
				)[0];

				let totalOIDTax = 0;
				activeOID?.orderItemDiscountTax?.map(v => {
					totalOIDTax += v?.taxAmount;
				});

				let totalODITax = 0;
				el?.orderDiscountItem?.orderDiscountItemTax?.map(v => {
					totalODITax += v?.taxAmount;
				});

				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID].quantity += el?.quantity * -1;
						temp[el?.refID].amount += el?.amount;
						temp[el?.refID].taxAmount += totalTax;

						temp[el?.refID].orderItemDiscBaseAmount += activeOID?.baseAmount;
						temp[el?.refID].orderItemDiscTaxAmount += totalOIDTax;

						temp[el?.refID].orderDiscountItemBaseAmount +=
							el?.orderDiscountItem?.baseAmount;
						temp[el?.refID].orderDiscountItemTaxAmount += totalODITax;
					} else {
						temp[el?.refID] = {
							quantity: el?.quantity * -1,
							amount: el?.amount * -1,
							taxAmount: totalTax * -1,

							orderItemDiscBaseAmount:
								isNaN(activeOID?.baseAmount * -1) === true
									? 0
									: activeOID?.baseAmount * -1,
							orderItemDiscTaxAmount:
								isNaN(totalOIDTax * -1) === true ? 0 : totalOIDTax * -1,

							orderDiscountItemBaseAmount:
								isNaN(el?.orderDiscountItem?.baseAmount * -1) === true
									? 0
									: el?.orderDiscountItem?.baseAmount * -1,
							orderDiscountItemTaxAmount:
								isNaN(totalODITax * -1) === true ? 0 : totalODITax * -1,

							orderItemOptions: [],
						};

						// Loop through the orderItemOptionArray
						for (let i = 0; i < el?.orderItemOption?.length; i++) {
							const option = el?.orderItemOption[i];
							const optionItem = el?.orderItemOption[i]?.orderItemOptionItem;

							let optionData;

							if (optionItem) {
								optionItem?.map(x => {
									let totalTax = 0;
									x?.orderItemOptionItemTax?.map(v => {
										totalTax += v?.taxAmount;
									});

									let totalOptItemOIDTax = 0;
									x?.orderItemOptionItemDiscountTax?.map(v => {
										totalOptItemOIDTax += v?.taxAmount;
									});

									let totalOptItemODITax = 0;
									x?.orderDiscountItemOptionTax?.map(v => {
										totalOptItemODITax += v?.taxAmount;
									});

									optionData = {
										optionID: option?.optionID,
										orderItemOptionItem: {
											basePrice: x?.basePrice * -1,
											taxAmount: totalTax * -1,
											discountBaseAmount: x?.discountBaseAmount * -1,
											itemDiscountTaxAmount: totalOptItemOIDTax * -1,
											discountItemTaxAmount: totalOptItemODITax * -1,
										},
									};
								});
								temp[el?.refID].orderItemOptions.push(optionData);
							}
						}
					}
				}
			});

			const filtered = unpaidOrderItem
				?.map(el => {
					if (el?.quantity - temp[el?.ID]?.quantity === 0) {
						return { ...el, void: true };
					} else {
						return { ...el, void: false };
					}
				})
				?.filter(list => list.void === false);

			dispatch({ type: 'negOrderItem', payload: { ...temp } });
			dispatch({ type: 'itemArray', payload: filtered });
		},
	});

	const [
		loadReason,
		{ loading: reasonLoading, data: { getReason } = { getReason: [] } },
	] = useGetReasonLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadFilteredDiscount,
		{
			loading: getFilteredDiscountLoading,
			data: { getFilteredDiscount } = { getFilteredDiscount: [] },
		},
	] = useGetFilteredDiscountLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: () => {},
	});

	const {
		data: { getDiscount } = { getDiscount: [] },
		loading: discountLoading,
	} = useGetDiscountQuery({
		fetchPolicy: 'network-only',
		onCompleted: () => {},
	});

	const {
		data: { getVoidedDiscount } = { getVoidedDiscount: [] },
		loading: voidedDiscountLoading,
	} = useGetVoidedDiscountQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderID: orderID,
			outletID: outletID,
		},
		onError: error => {
			console.log('ERROR', error);
		},
	});

	const {
		loading: userLoading,
		error: userError,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			status: CommonStatus.Active,
		},
	});

	const [
		createOrderItemDiscount,
		{
			data: createdOrderItemDiscount,
			loading: createdOrderItemDiscountLoading,
		},
	] = useCreateOrderItemDiscountMutation({
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());

			loadHomeTableSummary({
				variables: { tableID: tableID },
			});
		},
	});

	const [
		deleteOrderItemDiscount,
		{ loading: deleteOrderItemDiscountLoading },
	] = useDeleteOrderItemDiscountMutation({
		onError: error => {
			console.log('ERROR', error);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecordFail());
		},
		onCompleted: data => {
			dispatch({ type: 'selectedOrderItem', payload: null });
			dispatch({ type: 'selectedOrderItem', payload: null });
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecord());

			loadHomeTableSummary({
				variables: { tableID: tableID },
			});
		},
	});

	const discountable = getAllOrderItem?.filter(
		x => x?.menuItem?.isDiscountable === true,
	);

	const [
		createOrderDiscount,
		{ data: createdOrderDiscount, loading: createdOrderDiscountLoading },
	] = useCreateOrderDiscountMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			dispatch({
				type: 'selectedOrder',
				payload: getOrder?.filter(order => order?.ID === state?.orderID)[0],
			});

			loadHomeTableSummary({
				variables: { tableID: tableID },
			});

			if (discountable?.length === 0 && data?.createOrderDiscount === false) {
				setSnackBarMsg('Discount not applicable');
			} else {
				setSnackBarMsg(SystemMsgs.createNewRecord());
			}
		},
	});

	const [
		deleteOrderDiscount,
		{ loading: deleteOrderDiscountLoading },
	] = useDeleteOrderDiscountMutation({
		onError: error => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecordFail());
		},
		onCompleted: data => {
			dispatch({
				type: 'selectedOrder',
				payload: getOrder?.filter(order => order?.ID === state?.orderID)[0],
			});

			loadHomeTableSummary({
				variables: { tableID: tableID },
			});

			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecord());
		},
	});

	// useEffect
	useEffect(() => {
		if (!useGetAllOrderItemCalled) {
			LoadOrderItem({
				variables: {
					orderID: state?.orderID,
					outletID: state?.outletID,
				},
			});
		}
	}, [useGetAllOrderItemCalled]);

	useEffect(() => {
		LoadOrderItem({
			variables: {
				orderID: state?.orderID,
				outletID: state?.outletID,
			},
		});
	}, [state?.selectedOrder?.ID]);

	const closeAmount = homeTableSummaryData?.close?.closeAmount;
	const footerAmount = FooterAmount(
		getAllOrderItem,
		'discount',
		getRoundingPolicy,
	);

	useEffect(() => {
		if (footerAmount?.total <= 0) {
			if (closeAmount <= 0) {
				dispatch({ type: 'closeTableDialog', payload: true });
				dispatch({ type: 'closeOrderDialog', payload: false });
			} else if (closeAmount > 0) {
				dispatch({ type: 'closeOrderDialog', payload: true });
				dispatch({ type: 'closeTableDialog', payload: false });
			} else {
				dispatch({ type: 'closeTableDialog', payload: false });
				dispatch({ type: 'closeOrderDialog', payload: false });
			}
		} else {
			dispatch({ type: 'closeTableDialog', payload: false });
			dispatch({ type: 'closeOrderDialog', payload: false });
		}
	}, [closeAmount, footerAmount?.total]);

	// function
	const handleCheckBoxOID = (e: any, index: number, el: any) => {
		handleClick(e, el.ID, index, el);

		const tempNegOI = getAllOrderItem?.filter(
			orderItem => orderItem?.refID === el?.ID,
		);

		if (e.target.checked) {
			dispatch({ type: 'OIDDialog', payload: true });
			dispatch({ type: 'selectedOrderItem', payload: el });
			const oiAmt = el?.amount;
			const negOIAmt = tempNegOI.reduce(
				(previousValue, currentValue) => previousValue + currentValue?.amount,
				0,
			);
			loadFilteredDiscount({
				variables: {
					outletID: outletID,
					amount: new BigNumber(oiAmt).plus(negOIAmt ?? 0).toNumber(),
					isOrderItemDiscount: true,
				},
			});
		} else {
			dispatch({ type: 'selectedOrderItem', payload: el });
			dispatch({ type: 'voidDialog', payload: true });
			loadReason({
				variables: {
					outletID: outletID,
				},
			});
		}
	};

	const handleCheckBoxOD = (e: any) => {
		if (e.target.checked) {
			dispatch({ type: 'ODDialog', payload: true });
			loadFilteredDiscount({
				variables: {
					outletID: outletID,
					amount: footerAmount.subtotal,
					isOrderItemDiscount: false,
				},
			});
		} else {
			dispatch({ type: 'voidDialog', payload: true });
			loadReason();
		}
	};

	const onSubmitOID = (data: any) => {
		createOrderItemDiscount({
			variables: {
				input: {
					outletID: outletID,
					discountID: data.discountCode,
					orderItemID: state?.selectedOrderItem?.ID,
					status: OrderItemDiscountStatus.Active,
				},
				orderID: orderID,
			},
			refetchQueries: [
				{ query: GetOrderItemDiscountDocument, variables: { orderID } },
				{
					query: GetAllOrderItemDocument,
					variables: { orderID, outletID },
				},
				{
					query: GetBillDiscountOrderDocument,
					variables: { outletID: outletID, tableID, status: OrderStatus.Open },
				},
				{
					query: GetOrderDocument,
					variables: {
						tableID: tableID,
						status: OrderStatus.Open,
						outletID,
					},
				},
			],
		});

		dispatch({ type: 'OIDDialog', payload: false });
	};

	const onSubmitOD = (data: any) => {
		createOrderDiscount({
			variables: {
				input: {
					outletID: outletID,
					discountID: data.discountCode,
					orderID: orderID,
					businessDate: getOutlet[0]?.businessDate,
					status: OrderDiscountStatus.Active,
					amount:
						getDiscount?.filter(
							discount => discount?.ID === data?.discountCode,
						)[0]?.calcType === CalcType.Amount
							? getDiscount?.filter(
									discount => discount?.ID === data?.discountCode,
							  )[0]?.amount
							: (footerAmount?.total *
									getDiscount?.filter(
										discount => discount?.ID === data?.discountCode,
									)[0]?.amount) /
							  100,
				},
			},
			refetchQueries: [
				{ query: GetOrderDiscountDocument, variables: { orderID, outletID } },
				{
					query: GetAllOrderItemDocument,
					variables: { orderID, outletID },
				},
				{
					query: GetBillDiscountOrderDocument,
					variables: { outletID: outletID, tableID, status: OrderStatus.Open },
				},
				{
					query: GetOrderDocument,
					variables: {
						tableID: tableID,
						status: OrderStatus.Open,
						outletID,
					},
				},
			],
		});

		dispatch({ type: 'ODDialog', payload: false });
	};

	const onSubmitVoid = data => {
		if (state?.selectedOrderItem?.ID) {
			deleteOrderItemDiscount({
				variables: {
					input: {
						orderItemID: state?.selectedOrderItem?.ID,
						outletID: outletID,
						remark: data?.remark,
						reasonCode: data?.reasonCode,
					},
					orderID: orderID,
				},
				refetchQueries: [
					{ query: GetOrderItemDiscountDocument, variables: { orderID } },
					{
						query: GetAllOrderItemDocument,
						variables: { orderID, outletID },
					},
					{
						query: GetBillDiscountOrderDocument,
						variables: {
							outletID: outletID,
							tableID,
							status: OrderStatus.Open,
						},
					},
					{
						query: GetOrderDocument,
						variables: {
							tableID: tableID,
							status: OrderStatus.Open,
							outletID,
						},
					},
					{
						query: GetVoidedDiscountDocument,
						variables: {
							orderID: orderID,
							outletID: outletID,
						},
					},
				],
			});
		} else {
			deleteOrderDiscount({
				variables: {
					input: {
						orderID: orderID,
						outletID: outletID,
						remark: data?.remark,
						reasonCode: data?.reasonCode,
					},
				},
				refetchQueries: [
					{ query: GetOrderItemDiscountDocument, variables: { orderID } },
					{
						query: GetAllOrderItemDocument,
						variables: { orderID, outletID },
					},
					{
						query: GetBillDiscountOrderDocument,
						variables: {
							outletID: outletID,
							tableID,
							status: OrderStatus.Open,
						},
					},
					{
						query: GetOrderDocument,
						variables: {
							tableID: tableID,
							status: OrderStatus.Open,
							outletID,
						},
					},
					{
						query: GetVoidedDiscountDocument,
						variables: {
							orderID: orderID,
							outletID: outletID,
						},
					},
				],
			});
		}

		dispatch({ type: 'voidDialog', payload: false });
	};

	const handleChangeOrder = (e, obj) => {
		if (obj?.ID !== state?.orderID) {
			dispatch({
				type: 'selectedOrder',
				payload: getOrder?.filter(order => order?.ID === obj?.ID)[0],
			});
			dispatch({ type: 'orderID', payload: obj?.ID });
			history.push({
				pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/order/${state?.orderID}/discount`,
			});
		}
	};

	// const
	const currencyCode = getOutlet[0]?.currencyCode ?? '';
	const itemCount = homeTableSummaryData?.orderItemCount?.orderItem;
	const discountAmount =
		amtStr(
			Number(
				homeTableSummaryData?.billItemDiscount?.discountAmount || 0,
			).toFixed(2),
		) ?? 0;
	const roundingAmount =
		getRoundingPolicy[0]?.inUse === true
			? generateRoundingAmount(
					homeTableSummaryData?.close?.closeAmount,
					getRoundingPolicy[0]?.smallestDenomination,
					getRoundingPolicy,
			  )
			: 0;
	const totalAmount = amtStr(
		homeTableSummaryData?.orderItemCount?.totalAmount + roundingAmount,
	);
	const voidItemCount = homeTableSummaryData?.orderItemCount?.voidItem;
	const voidDiscountAmount = amtStr(
		getVoidedDiscount.reduce((sum, item) => sum + parseFloat(item?.amount), 0),
	);
	const hasOrderDiscount =
		state?.selectedOrder?.orderDiscount?.filter(
			disc => disc?.status === OrderDiscountStatus.Active,
		)?.length !== 0;

	return (
		<>
			{outletLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			{homeTableSummaryLoading && <Loading />}
			{getRoundingPolicyLoading && <Loading />}
			{useGetAllOrderItemLoading && <Loading />}
			{reasonLoading && <Loading />}
			{getFilteredDiscountLoading && <Loading />}
			{discountLoading && <Loading />}
			{createdOrderItemDiscountLoading && <Loading />}
			{deleteOrderItemDiscountLoading && <Loading />}
			{createdOrderDiscountLoading && <Loading />}
			{deleteOrderDiscountLoading && <Loading />}
			{orderLoading && <Loading />}

			<MainHeader
				onClick={() => {
					history.push({
						pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/order/${state?.orderID}/confirm-order`,
					});
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Discount', current: true },
				]}
				rightRouteSegments={[
					{
						name: state?.currentMealPeriod
							? `${state?.currentMealPeriod?.name}: ${formatTime(
									state?.currentMealPeriod?.startTime,
							  )} - ${formatTime(state?.currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>

			<DynamicSubHeader
				title={
					(state?.selectedOrder !== null ||
						state?.selectedOrder !== undefined) && (
						<>
							<span className="highlight" style={{ color: '#ff9800' }}>
								{`${state?.selectedOrder?.table?.prefix || ''}${state
									?.selectedOrder?.table?.tableNumber || ''}`}
							</span>
							<span style={{ paddingLeft: '4px' }}>
								{`${state?.selectedOrder?.docNo || ''}`}
							</span>
							<span style={{ paddingLeft: '6px' }}>
								{`${state?.selectedOrder?.patron?.patronClass || ''}`}
							</span>
						</>
					)
				}
				handleClick={e => handleClickCO(e, state?.orderID, 0)}
				dropDownOptions={
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorElCO}
						keepMounted
						open={Boolean(anchorElCO)}
						onClose={handleCloseCO}
						onClick={handleCloseCO}
					>
						{getOrder.map((order, index) => (
							<StyledMenuItem
								key={index}
								value={order.ID}
								defaultValue={order.ID}
								onClick={e => {
									handleChangeOrder(e, order);
								}}
							>
								<ListItemText primary={order?.docNo} />
							</StyledMenuItem>
						))}
					</StyledMenu>
				}
				rightText={
					state?.selectedOrder !== null && (
						<div>
							{`${state?.selectedOrder?.pax} Pax`}{' '}
							<span className="desc" onClick={() => {}}>
								<EditIcon
									style={{ fontSize: '15px' }}
									onClick={() => {
										history.push({
											pathname: `/menu/outlet-app/${outletID}/table/grid/${state?.selectedOrder?.table?.ID}/edit/${state?.selectedOrder?.ID}`,
											state: {
												table: state?.selectedOrder?.table,
												orders: getOrder,
												orderID: state?.orderID,
												page: 'edit-order-info',
											},
										});
									}}
								/>
							</span>
						</div>
					)
				}
				infoLine={
					<>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<span className="desc">{name}</span>
							<span className="desc right-info">
								{`Total Item: ${itemCount ||
									0} | Disc: (${discountAmount}) | ${totalAmount}`}
							</span>
						</div>
					</>
				}
				subInfoLine={
					<>
						<div>
							<span
								className="desc"
								onClick={() => {
									dispatch({ type: 'auditDialog', payload: true });
								}}
							>
								<img
									src={AuditIcon}
									alt="ifca"
									width={12}
									loading="lazy"
									style={{
										paddingLeft: '4px',
										width: '18px',
										height: '18px',
									}}
								/>
							</span>
							<span className="desc right-info">
								{`Void Item: ${voidItemCount ||
									0} | Void Disc: ${voidDiscountAmount}`}
							</span>
						</div>
					</>
				}
			/>

			<ContentWrapper singleDynamicInfo multiLineSubheader footer>
				{getAllOrderItem === undefined || getAllOrderItem?.length === 0 ? (
					<EmptyList
						title="There are no orders found"
						subtitle="Please make a new order in the Menu "
					/>
				) : (
					<>
						<List
							style={{
								backgroundColor: 'white',
							}}
						>
							{state?.itemArray?.map((oi, index) => {
								const hasItemDisc =
									oi?.orderItemDiscount?.filter(
										disc => disc?.status === OrderItemDiscountStatus.Active,
									)?.length !== 0 ||
									(oi?.orderDiscountItem &&
										oi?.orderDiscountItem?.amount !== 0) ||
									(oi?.orderDiscountItem?.baseAmount > 0 &&
										oi?.orderDiscountItem?.amount === 0);

								let totalTax = 0;
								oi?.orderItemTax?.map(x => {
									totalTax += x?.taxAmount;
								});

								const baseItemAmountBill = oi?.amount + totalTax;

								const negBaseItemAmountBill =
									(state?.negOrderItem?.[oi?.ID]?.amount ?? 0) +
									(state?.negOrderItem?.[oi?.ID]?.taxAmount ?? 0);

								const activeOID = oi?.orderItemDiscount?.filter(
									el => el?.status === OrderItemDiscountStatus.Active,
								)[0];

								let totalOIDTax = 0;
								activeOID?.orderItemDiscountTax?.map(x => {
									totalOIDTax += x?.taxAmount;
								});

								const oidBA = activeOID?.baseAmount;
								const oidTx = totalOIDTax;

								const oid = oidBA + oidTx;

								let totalODITax = 0;
								oi?.orderDiscountItemTax?.map(x => {
									totalODITax += x?.taxAmount;
								});

								const odiTx = totalODITax;
								const odi = oi?.orderDiscountItem?.baseAmount + odiTx;

								const negItemDisc =
									(state?.negOrderItem?.[oi?.ID]?.orderItemDiscBaseAmount ??
										0) +
									(state?.negOrderItem?.[oi?.ID]?.orderItemDiscTaxAmount ?? 0);

								const negOrderDisc =
									(state?.negOrderItem?.[oi?.ID]?.orderDiscountItemBaseAmount ??
										0) +
									(state?.negOrderItem?.[oi?.ID]?.orderDiscountItemTaxAmount ??
										0);

								const discount = oid ? oid - negItemDisc : odi - negOrderDisc;
								return (
									<ListItem>
										<ListItemText
											style={
												oi?.status === OrderItemStatus.Voided ||
												oi?.status === OrderItemStatus.TransferOut
													? { paddingRight: '31px' }
													: null
											}
											primary={
												<>
													<Grid container>
														<Grid item xs={1}>
															<span className="xsTitle text-noflow">
																{`${(index + 1).toString().padStart(2, '0')}.`}
															</span>
														</Grid>
														<Grid item xs={8}>
															<span
																className="xsTitle text-noflow"
																style={
																	oi?.status === OrderItemStatus.Voided ||
																	oi?.status === OrderItemStatus.TransferOut
																		? { textDecoration: 'line-through' }
																		: null
																}
															>
																{oi?.openItemName ?? oi.menuItem?.name}&nbsp;
																&nbsp;
																{oi?.status === OrderItemStatus.Voided ||
																oi?.status === OrderItemStatus.TransferOut
																	? `x${Math.abs(oi.quantity)}`
																	: `x${oi?.quantity -
																			(state?.negOrderItem?.[oi?.ID]
																				?.quantity ?? 0)}`}
																{oi?.isTakeaway && (
																	<img
																		className="list-takeaway-logo"
																		src={PickupIcon}
																	/>
																)}
															</span>
														</Grid>

														<Grid item xs={3}>
															{hasItemDisc && (
																<span
																	className="mdDesc xsTitle right-info"
																	style={{
																		textDecoration: 'line-through',
																		padding: '7px 0px 4px 4px',
																	}}
																>
																	{`${currencyCode}${amtStr(
																		Math.abs(
																			new BigNumber(baseItemAmountBill)
																				.minus(negBaseItemAmountBill ?? 0)
																				.toNumber(),
																		),
																	)}`}
																</span>
															)}
															<span
																className="highlight-text flex-space xsTitle right-info"
																style={
																	oi?.status === OrderItemStatus.Voided ||
																	oi?.status === OrderItemStatus.TransferOut
																		? {
																				padding: '7px 0px 4px 4px',
																				textDecoration: 'line-through',
																		  }
																		: { padding: '7px 0px 4px 4px' }
																}
															>
																{hasItemDisc
																	? `${currencyCode}${amtStr(
																			Math.abs(
																				new BigNumber(baseItemAmountBill)
																					.minus(negBaseItemAmountBill ?? 0)
																					.minus(discount ?? 0)
																					.toNumber(),
																			),
																	  )}`
																	: oi.status === OrderItemStatus.Voided ||
																	  oi?.status === OrderItemStatus.TransferOut
																	? `${currencyCode}${amtStr(
																			Math.abs(
																				new BigNumber(baseItemAmountBill)
																					.minus(negBaseItemAmountBill ?? 0)
																					.toNumber(),
																			),
																	  )}`
																	: `${currencyCode}${amtStr(
																			Math.abs(
																				new BigNumber(baseItemAmountBill)
																					.minus(negBaseItemAmountBill ?? 0)
																					.toNumber(),
																			),
																	  )}`}
															</span>
														</Grid>
													</Grid>
												</>
											}
											secondary={
												<>
													<Grid container>
														{oi?.orderItemOption?.length
															? oi?.orderItemOption?.map(oio => (
																	<>
																		{oio?.orderItemOptionItem?.map(
																			oioptitem => {
																				let totalTax = 0;
																				oioptitem?.orderItemOptionItemTax?.map(
																					x => {
																						totalTax += x?.taxAmount;
																					},
																				);

																				const baseOptItemAmountBill =
																					oioptitem?.basePrice + totalTax;

																				const negOptBaseItemAmountBill =
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.basePrice ?? 0) +
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.taxAmount ?? 0);

																				let totalOptItemOIDTax = 0;
																				oioptitem?.orderItemOptionItemDiscountTax?.map(
																					v => {
																						totalOptItemOIDTax += v?.taxAmount;
																					},
																				);

																				let totalOptItemODITax = 0;
																				oioptitem?.orderDiscountItemOptionTax?.map(
																					v => {
																						totalOptItemODITax += v?.taxAmount;
																					},
																				);

																				const discOptItemAmountBill =
																					oioptitem?.discountBaseAmount +
																					totalOptItemOIDTax +
																					totalOptItemODITax;

																				const negDiscOptBaseItemAmountBill =
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.discountBaseAmount ?? 0) +
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.itemDiscountTaxAmount ?? 0) +
																					(state?.negOrderItem?.[
																						oi?.ID
																					]?.orderItemOptions?.filter(
																						noi =>
																							noi.optionID === oio?.optionID,
																					)[0]?.orderItemOptionItem
																						?.discountItemTaxAmount ?? 0);

																				const optItemDiscount =
																					discOptItemAmountBill -
																					negDiscOptBaseItemAmountBill;
																				return (
																					<>
																						<Grid item xs={8}>
																							<span className="mdDesc">
																								{oioptitem?.optionItem?.name}
																							</span>
																						</Grid>
																						{
																							<Grid item xs={4}>
																								<span
																									className="mdDesc right-info"
																									style={{
																										marginTop: '4px',
																									}}
																								>
																									{`${currencyCode}${amtStr(
																										new BigNumber(
																											baseOptItemAmountBill,
																										)
																											.minus(
																												negOptBaseItemAmountBill ??
																													0,
																											)
																											.minus(
																												optItemDiscount ?? 0,
																											)
																											.toNumber(),
																									)}`}
																								</span>
																							</Grid>
																						}
																					</>
																				);
																			},
																		)}
																	</>
															  ))
															: oi?.orderItemSetMenuOption?.length
															? oi?.orderItemSetMenuOption?.map(v => (
																	<>
																		{v?.orderItemSetMenuOptionItem?.map(
																			oisetmenuoptitem => (
																				<Grid item xs={8}>
																					<span className="mdDesc">
																						{
																							oisetmenuoptitem
																								?.setMenuOptionItem?.menuItem
																								?.name
																						}
																					</span>
																				</Grid>
																			),
																		)}
																	</>
															  ))
															: null}
													</Grid>
												</>
											}
										/>

										{oi?.status === OrderItemStatus.Voided ||
										oi?.status === OrderItemStatus.TransferOut ? null : (
											<ListItemSecondaryAction
												style={{ top: '7px', transform: 'inherit' }}
											>
												<Checkbox
													edge="end"
													value={oi?.ID}
													disabled={
														// handlePermDisabled({
														//   outletID: outletID,
														//   permEnum:
														//     PosPermission.HomeTableLayoutBillItemDiscountCreateOrderItemDiscountCreate,
														// }) ||
														!oi?.menuItem?.isDiscountable || hasOrderDiscount
													}
													checked={
														oi?.orderItemDiscount?.filter(
															disc =>
																disc?.status === OrderItemDiscountStatus.Active,
														)?.length !== 0
													}
													onChange={(e: any) => {
														if (oi?.menuItem?.isDiscountable) {
															handleCheckBoxOID(e, index, oi);
														}
													}}
													color="primary"
													inputProps={{ 'aria-label': 'secondary checkbox' }}
												/>
											</ListItemSecondaryAction>
										)}
									</ListItem>
								);
							})}
						</List>
					</>
				)}
			</ContentWrapper>

			<div style={{ marginBottom: '90px' }}></div>

			<OrderDiscountFooter
				isDesktop={isDesktop}
				globalState={globalState}
				state={state}
				handleCheckBoxOD={handleCheckBoxOD}
				getDiscount={getDiscount}
				footerAmount={footerAmount}
			/>

			<AmountFooter
				style={{
					width: isDesktop && globalState.drawerOpen ? 'inherit' : '96.5%',
					backgroundColor: '#faf2e8',
					position: 'fixed',
				}}
				currencyCode={getOutlet[0]?.currencyCode ?? ''}
				subtotal={amtStr(footerAmount.subtotal)}
				discount={amtStr(footerAmount.discount)}
				serviceTax={amtStr(footerAmount.tax)}
				total={amtStr(footerAmount.total)}
				rounding={amtStr(footerAmount.roundingAmount)}
				page={'discount'}
			/>

			<OrderItemDiscountDialog
				state={state}
				dispatch={dispatch}
				register={OIDRegister}
				control={OIDControl}
				handleSubmit={OIDHandleSubmit}
				onSubmit={onSubmitOID}
				getFilteredDiscount={getFilteredDiscount}
				createdOrderItemDiscountLoading={createdOrderItemDiscountLoading}
			/>

			<OrderDiscountDialog
				state={state}
				dispatch={dispatch}
				register={ODRegister}
				control={ODControl}
				handleSubmit={ODHandleSubmit}
				onSubmit={onSubmitOD}
				getFilteredDiscount={getFilteredDiscount}
				createdOrderDiscountLoading={createdOrderDiscountLoading}
			/>

			<VoidDialog
				state={state}
				dispatch={dispatch}
				register={voidRegister}
				control={voidControl}
				errors={voidErrors}
				watch={voidWatch}
				handleSubmit={voidHandleSubmit}
				getReason={getReason}
				onSubmitVoid={onSubmitVoid}
				deleteOrderItemDiscountLoading={deleteOrderItemDiscountLoading}
				deleteOrderDiscountLoading={deleteOrderDiscountLoading}
			/>

			<AuditDialog
				state={state}
				dispatch={dispatch}
				getVoidedDiscount={getVoidedDiscount}
				currencyCode={getOutlet[0]?.currencyCode}
				getUsersByAccountAndSoftware={getUsersByAccountAndSoftware}
			/>

			<CloseTableDialog
				state={state}
				dispatch={dispatch}
				table={state?.selectedOrder?.table}
				getOrder={getOrder}
				orderItems={state?.itemArray}
			/>

			<CloseOrderDialog
				state={state}
				dispatch={dispatch}
				orderItems={state?.itemArray}
				order={state?.selectedOrder}
			/>
		</>
	);
};
