import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import {
	useAuditLogDetailsQuery,
	useGetOutletNameQuery,
} from 'generated/graphql';
import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { NameByCustomerType } from '../SharedFunction';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { AuditLogListing } from './AuditLogListing';

export const AuditLog = () => {
	const history = useHistory();
	const { outletID, orderID } = useParams<Record<string, any>>();
	let location = useLocation();

	let orders: any = location.state;

	const [limit, setLimit] = useState(20);

	const [setVariables, name] = NameByCustomerType();

	const [selectedValue, setSelectedValue] = useState({
		displayValue: 'Order Info',
		tableName: 'p_order',
		fieldID: orderID,
		subDisplayValue: '',
	});

	const [option, setOption] = useState([
		{
			displayValue: 'Order Info',
			tableName: 'p_order',
			fieldID: orderID,
			subDisplayValue: '',
		},
	]);

	// API
	const {
		loading: outletLoading,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			setVariables({
				order: orders?.filter(order => order?.ID === orderID)[0],
				outletID: outletID,
				hotelID: getOutlet[0]?.outletChannel?.hotelID,
			});
		},
	});

	const {
		fetchMore,
		loading: loadingAuditLogDetails,
		data: { auditLogDetails } = { auditLogDetails: [] },
	} = useAuditLogDetailsQuery({
		variables: {
			fieldID: selectedValue?.fieldID,
			tableName: selectedValue?.tableName,
			limit: 20,
			offset: 0,
		},
		fetchPolicy: 'network-only',
	});

	return (
		<>
			{outletLoading && <Loading />}

			<MainHeader
				mainBtn="back"
				onClick={() => history.goBack()}
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Payment' },
					{ name: 'Audit Log', current: true },
				]}
			/>

			<DynamicSubHeader
				title={
					(orders !== null || orders !== undefined) && (
						<>
							<span className="highlight" style={{ color: '#ff9800' }}>
								{`${orders[0]?.table?.prefix}${orders[0]?.table?.tableNumber}`}
							</span>
							<span style={{ paddingLeft: '4px' }}>
								{`${orders[0]?.docNo}`}
							</span>
							<span style={{ paddingLeft: '6px' }}>
								{`${orders[0]?.patron?.patronClass}`}
							</span>
						</>
					)
				}
				rightText={orders[0] !== null && <div>{`${orders[0]?.pax} Pax`} </div>}
				infoLine={
					<>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<span className="desc">{name}</span>
						</div>
					</>
				}
			/>

			<AuditLogListing
				dropdownOptions={option}
				queryData={auditLogDetails}
				queryLoading={loadingAuditLogDetails}
				dropdownValue={selectedValue}
				setDropdownValue={setSelectedValue}
				infiniteScroll={{
					next: () => {
						const currentLength = auditLogDetails?.length;
						fetchMore({
							variables: {
								fieldID: selectedValue?.fieldID,
								tableName: selectedValue?.tableName,
								limit: 10,
								offset: currentLength,
							},
							updateQuery: (prev: any, { fetchMoreResult }: any) => {
								if (!fetchMoreResult) return prev;
								return Object.assign({}, prev, {
									auditLogDetails: [
										...prev?.auditLogDetails,
										...fetchMoreResult?.auditLogDetails,
									],
								});
							},
						}).then(fetchMoreResult => {
							setLimit(
								currentLength + fetchMoreResult.data?.auditLogDetails?.length,
							);
						});
					},
				}}
				filterVariables={[
					'action',
					'actionDate',
					'createdByUserName',
					'fieldName',
					'modByUserName',
					'newValue',
					'oldValue',
					'tableName',
				]}
				hasSubheader={true}
			/>
		</>
	);
};
