import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { AddBox } from '@material-ui/icons';

export const VoidDialog = (props: any) => {
	const {
		state,
		dispatch,
		menu,
		setValue,
		watch,
		control,
		register,
		isDesktop,
		getReason,
		handlePrinting,
		voidOrderItemWithOptionsLoading,
		voidOrderItemWithSetMenuOptionsLoading,
	} = props;

	const clickButton = (mode: string) => {
		if (mode === 'add') {
			if (
				state?.deductionQuantity ===
				menu?.obj?.quantity -
					(state?.negOrderItem?.[menu?.obj?.ID]?.quantity ?? 0)
			) {
				dispatch({ type: 'deductionQuantity', payload: menu?.obj?.quantity });
				setValue('quantity', menu?.obj?.quantity);
			} else {
				dispatch({
					type: 'deductionQuantity',
					payload: state?.deductionQuantity + 1,
				});
				setValue('quantity', state?.deductionQuantity + 1);
			}
		} else {
			if (state?.deductionQuantity === 1) {
				dispatch({ type: 'deductionQuantity', payload: 1 });
				setValue('quantity', 1);
			} else {
				dispatch({
					type: 'deductionQuantity',
					payload: state?.deductionQuantity - 1,
				});
				setValue('quantity', state?.deductionQuantity - 1);
			}
		}
	};

	const getRoundedQty = qty1 => {
		let outstandingQuantity;
		if (state?.negOrderItem[menu?.obj?.ID]?.quantity === undefined) {
			outstandingQuantity = menu?.obj?.quantity;
		} else {
			outstandingQuantity =
				menu?.obj?.quantity - state?.negOrderItem[menu?.obj?.ID]?.quantity;
		}
		let roundedQty =
			qty1 > outstandingQuantity ? outstandingQuantity : qty1 < 1 ? 1 : qty1;
		return roundedQty;
	};

	const color = (fields, loading) => {
		let color: any = 'primary';

		if (fields === true && loading === false) {
			color = 'inherit';
		} else if (fields === false && loading === false) {
			color = 'primary';
		} else if (fields === false && loading === true) {
			color = 'inherit';
		}

		return color;
	};

	const disable = (fields, loading) => {
		let disable: any = false;

		if (fields === true && loading === false) {
			disable = true;
		} else if (fields === false && loading === false) {
			disable = false;
		} else if (fields === false && loading === true) {
			disable = true;
		}

		return disable;
	};

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={state?.voidDialog}
				onClose={() => {
					dispatch({ type: 'voidDialog', payload: false });
					dispatch({ type: 'deductionQuantity', payload: 1 });
					dispatch({ type: 'reasonCode', payload: null });
					dispatch({ type: 'remark', payload: null });
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Void Item</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="mdDesc">
								<Grid container className="mdDesc" style={{ marginTop: '5px' }}>
									<Grid item xs={6} className="flex-space">
										<span style={{ fontWeight: 'bold' }}>
											{`${menu?.obj?.menuItem?.name} x${
												state?.deductionQuantity === undefined
													? 0
													: state?.deductionQuantity
											}`}
										</span>
										{menu?.obj?.orderItemOption
											? menu?.obj?.orderItemOption?.map(v => (
													<>
														{v?.orderItemOptionItem?.map(e => (
															<div
																className="desc"
																style={{ paddingTop: '1px' }}
															>
																{e?.optionItem?.name}
															</div>
														))}
													</>
											  ))
											: menu?.obj?.orderItemSetMenuOption?.map(v => (
													<>
														{v?.orderItemSetMenuOptionItem?.map(e => (
															<div
																className="desc"
																style={{ paddingTop: '1px' }}
															>
																{e?.setMenuOptionItem?.menuItem?.name}
															</div>
														))}
													</>
											  ))}
									</Grid>

									<Grid
										item
										xs={6}
										style={{
											textAlign: 'right',
											paddingRight: '10px',
											fontSize: '15px',
										}}
									>
										<IndeterminateCheckBoxIcon
											style={{
												fontSize: '29px',
												paddingInline: '10px',
											}}
											className="order-minus"
											onClick={() => {
												clickButton('reduce');
											}}
										/>

										<Controller
											render={({ onChange, onBlur, value }) => (
												<NumberFormat
													customInput={TextField}
													autoComplete="off"
													isNumericString
													variant="standard"
													thousandSeparator
													fullWidth={false}
													decimalScale={0}
													allowNegative={false}
													className="void-quantity"
													InputProps={{
														disableUnderline: true,
													}}
													onValueChange={(value: any) => {
														dispatch({
															type: 'deductionQuantity',
															payload: getRoundedQty(value?.floatValue),
														});
														setValue(
															'quantity',
															getRoundedQty(value?.floatValue),
														);
													}}
													style={{
														width: isDesktop ? '25%' : '30%',
														borderRadius: '8px',
														border: 'solid',
														borderColor: 'orange',
														marginTop: '16px',
													}}
													defaultValue={state?.deductionQuantity}
													value={watch('quantity')}
												/>
											)}
											name="quantity"
											control={control}
											ref={register}
										/>

										<AddBox
											style={{
												fontSize: '29px',
												color: '#ff9800',
												paddingInline: '10px',
											}}
											className="order-add"
											onClick={() => {
												clickButton('add');
											}}
										/>
									</Grid>

									<Grid item xs={12}>
										<Controller
											as={
												<TextField select label="Reason">
													{getReason?.map((x, index) => (
														<MenuItem
															key={index}
															value={x?.name}
															onClick={e => {
																dispatch({
																	type: 'reasonCode',
																	payload: x?.name,
																});
															}}
														>
															{x?.name}
														</MenuItem>
													))}
												</TextField>
											}
											name="reasonCode"
											autoComplete="off"
											control={control}
											multiline
											fullWidth
											required
											margin="normal"
										></Controller>
									</Grid>

									<Grid item xs={12}>
										<Controller
											render={({ onChange, value }) => (
												<TextField
													name="remark"
													label="Remark"
													autoComplete="off"
													onChange={e =>
														dispatch({
															type: 'remark',
															payload: e.target.value,
														})
													}
													multiline
													fullWidth
													ref={register}
													margin="normal"
													required
												/>
											)}
											name="remark"
											control={control}
										/>
									</Grid>
								</Grid>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										dispatch({ type: 'voidDialog', payload: false });
										dispatch({ type: 'deductionQuantity', payload: 1 });
										dispatch({ type: 'reasonCode', payload: null });
										dispatch({ type: 'remark', payload: null });
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: async e => {
										dispatch({ type: 'isSubmitting', payload: true });
										!state?.isSubmitting && (await handlePrinting());
										dispatch({ type: 'deductionQuantity', payload: 1 });
										e.stopPropagation();
									},
									variant: 'contained',
									color: color(
										(state?.reasonCode === null && state?.remark === null) ||
											state?.reasonCode === null ||
											state?.remark === null,
										voidOrderItemWithOptionsLoading ||
											voidOrderItemWithSetMenuOptionsLoading,
									),
									disabled: disable(
										(state?.reasonCode === null && state?.remark === null) ||
											state?.reasonCode === null ||
											state?.remark === null,
										voidOrderItemWithOptionsLoading ||
											voidOrderItemWithSetMenuOptionsLoading,
									),
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
