import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import {
	AppBar,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Tab,
	Tabs,
	useMediaQuery,
} from '@material-ui/core';
import {
	GetOrderDocument,
	GetScheduledDailyMenuCategoryDocument,
	OrderItemStatus,
	OrderStatus,
	useAttachmentListingLazyQuery,
	useCreateOrderItemWithOptionsMutation,
	useCreateOrderItemWithSetMenuOptionsMutation,
	useGetCurrentMealPeriodLazyQuery,
	useGetOrderQuery,
	useGetOutletDailyMenuItemByIdLazyQuery,
	useGetOutletNameQuery,
	useGetScheduledDailyMenuCategoryLazyQuery,
	useGetUomQuery,
} from 'generated/graphql';
import { IAction } from 'helpers/model';
import React, {
	Reducer,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { FooterWithTotal } from '@ifca-root/react-component/src/components/Footer/FooterWithTotal';
import theme from '@ifca-root/react-component/src/assets/theme';
import AppContext from 'containers/App/Store/AppContext';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { NoImage, RecommendedIcon } from 'assets';
import { amtStr } from 'helpers/numFormatter';
import BigNumber from 'bignumber.js';
import './addEditOrder.scss';
import { AddBox } from '@material-ui/icons';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { MenuDialogAdd, SetMenuDialogAdd } from './CustomDialog';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'helpers/SystemMsg';

function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

interface OptionItem {
	remark: string;
	name: string;
	optionItem: String;
	openPrice: number;
	specialRequest: String;
	option: any[];
	quantity: number;
}

interface Props {
	selectedOrder: any;
	outletID: any;
	orderID: any;
	tableID: any;
	currentMealPeriod: any;
	tabValue: {
		tab: any;
		categoryID: any;
	};
	currentMenu: any;
	menuDialog: boolean;
	setMenuDialog: boolean;
	menuItemPatronPrice: any;
	quantity: any;
	weightName: any;
	takeAwayStatus: boolean;
	optionError: boolean;
	isSubmitting: boolean;
}

export const AddEditOrder = () => {
	const history = useHistory();
	let location = useLocation();

	let page: any = location.state;

	const { tableID, outletID, orderID } = useParams<Record<string, any>>();

	const currTs = new Date();
	const currTimeInt = currTs.getHours() * 60 + currTs.getMinutes();

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { globalState }: any = useContext(AppContext as any);

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	// state
	const initialState: Props = {
		selectedOrder: {},
		outletID: outletID,
		orderID: orderID,
		tableID: tableID,
		currentMealPeriod: null,
		tabValue: {
			tab: 0,
			categoryID: '',
		},
		currentMenu: {},
		menuDialog: false,
		setMenuDialog: false,
		menuItemPatronPrice: 0,
		quantity: 1,
		weightName: null,
		takeAwayStatus: false,
		optionError: false,
		isSubmitting: false,
	};

	const reducer: Reducer<Props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);
	const [audioInput, setAudioInput] = useState('');
	const [record, setRecord] = useState(false);

	const [selectedCategory, setSelectedCategory] = useState(null);

	const menuSearch = JSON.parse(localStorage.getItem('searchFilter'));

	let amount = 0;
	let totalAmountFooter = amtStr(state?.selectedOrder?.outstandingAmount);

	// form
	const yupSchema = yup.object().shape({
		openItem: state?.currentMenu?.isOpenItem
			? yup
					.string()
					.required('Open item name is required')
					.trim()
			: null,
		openPrice:
			state?.currentMenu?.isOpenPrice || state?.currentMenu?.isOpenItem
				? yup.number().required('Open item price is required')
				: null,
	});

	let {
		handleSubmit,
		register,
		control,
		watch,
		setValue,
		unregister,
		errors,
		reset,
		clearErrors,
	}: any = useForm<OptionItem>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	// API
	const {
		loading: orderLoading,
		data: { getOrder } = { getOrder: [] },
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: {
			tableID: state?.tableID,
			status: OrderStatus.Open,
			outletID,
		},
		onCompleted: ({ getOrder }) => {
			dispatch({
				type: 'selectedOrder',
				payload: getOrder?.filter(order => order?.ID === state?.orderID)[0],
			});
		},
	});

	const {
		loading: outletLoading,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: state?.outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: state?.outletID,
				},
			});
		},
	});

	const [
		loadScheduledMenuCategory,
		{
			loading: scheduledDailyMenuCategoryLoading,
			data: { getOutletDailyMenuCategory } = { getOutletDailyMenuCategory: [] },
		},
	] = useGetScheduledDailyMenuCategoryLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			dispatch({
				type: 'currentMealPeriod',
				payload: getCurrentMealPeriod,
			});
			loadScheduledMenuCategory({
				variables: {
					outletID: state?.outletID,
					mealPeriodID: getCurrentMealPeriod?.ID,
				},
			});
		},
	});

	const [
		loadOutletDailyMenuItemByID,
		{
			loading: getOutletDailyMenuItemByIDLoading,
			data: { getOutletDailyMenuItemByID } = { getOutletDailyMenuItemByID: [] },
		},
	] = useGetOutletDailyMenuItemByIdLazyQuery({
		fetchPolicy: 'network-only',
	});

	const {
		loading: uomLoading,
		data: { getUOM } = { getUOM: [] },
	} = useGetUomQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadAttachment,
		{ loading: attachmentLoading, data: attachmentData },
	] = useAttachmentListingLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		createOrderItemWithOptions,
		{ loading: createOrderItemWithOptionsLoading },
	] = useCreateOrderItemWithOptionsMutation({
		onError: error => {
			dispatch({ type: 'optionError', payload: true });
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			history.push(`/menu/outlet-app/${outletID}/table/grid`);
		},
		onCompleted: data => {
			dispatch({ type: 'menuDialog', payload: false });
			dispatch({ type: 'optionError', payload: false });
			dispatch({ type: 'isSubmitting', payload: false });
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const [
		createOrderItemWithSetMenuOptions,
		{ loading: createOrderItemWithSetMenuOptionsLoading },
	] = useCreateOrderItemWithSetMenuOptionsMutation({
		onError: error => {
			dispatch({ type: 'optionError', payload: true });
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			history.push(`/menu/outlet-app/${outletID}/table/grid`);
		},
		onCompleted: data => {
			dispatch({ type: 'setMenuDialog', payload: false });
			dispatch({ type: 'optionError', payload: false });
			dispatch({ type: 'isSubmitting', payload: false });
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	// useEffect
	useEffect(() => {
		dispatch({
			type: 'selectedOrder',
			payload: getOrder?.filter(order => order?.ID === state?.orderID)[0],
		});
	}, [getOrder?.length, state?.orderID]);

	useEffect(() => {
		if (state?.currentMenu?.promoChecking) {
			dispatch({
				type: 'menuItemPatronPrice',
				payload: new BigNumber(
					menuItemListPatronPrice(state?.currentMenu, true) ?? 0,
				).toNumber(),
			});
		} else {
			dispatch({
				type: 'menuItemPatronPrice',
				payload: new BigNumber(
					menuItemListPatronPrice(state?.currentMenu) ?? 0,
				).toNumber(),
			});
		}
	}, [state?.currentMenu]);

	useEffect(() => {
		loadAttachment({ variables: { refID: state?.currentMenu?.menuItem?.ID } });
	}, [state?.currentMenu]);

	useEffect(() => {
		dispatch({
			type: 'weightName',
			payload: getUOM?.filter(id => id?.ID === state?.currentMenu?.uomID)[0],
		});
	}, [getOutletDailyMenuItemByID[0]?.ID, state?.currentMealPeriod]);

	useEffect(() => {
		unregister(`option`);
		reset();
	}, [state?.menuDialog, state?.currentMenu]);

	useEffect(() => {
		if (state?.setMenuDialog) {
			state?.currentMenu?.menuItem?.setMenuOption
				?.filter(setMenuOption => setMenuOption?.maxSelections === 0)[0]
				?.setMenuOptionItem?.map((setMenuOptionItem, i) => {
					register(
						`option[0].${
							state?.currentMenu?.menuItem?.setMenuOption?.filter(
								setMenuOption => setMenuOption?.maxSelections === 0,
							)[0].ID
						}[${setMenuOptionItem?.ID}]`,
					);
					setValue(
						`option[0].${
							state?.currentMenu?.menuItem?.setMenuOption?.filter(
								setMenuOption => setMenuOption?.maxSelections === 0,
							)[0].ID
						}[${setMenuOptionItem?.ID}]`,
						{
							quantity: setMenuOptionItem?.quantity,
							menuItemID: setMenuOptionItem?.menuItem?.ID,
						},
					);
				});
		}
	}, [state?.setMenuDialog, state?.currentMenu]);

	// search
	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	const categoryID =
		state?.tabValue?.categoryID === ''
			? getOutletDailyMenuCategory[0]?.ID
			: state?.tabValue?.categoryID;

	useEffect(() => {
		setSelectedCategory(
			getOutletDailyMenuCategory?.filter(
				category => category?.ID === categoryID,
			)[0],
		);

		setOriginalListing(
			getOutletDailyMenuCategory
				?.filter(category => category?.ID === categoryID)[0]
				?.outletDailyMenuItem?.filter(
					item => item?.menuItem?.stopSales === null,
				),
		);

		localStorage.setItem(
			'searchFilter',
			JSON.stringify({
				category: getOutletDailyMenuCategory?.filter(
					category => category?.ID === categoryID,
				)[0]?.ID,
				searchString: menuSearch?.searchString ?? '',
			}),
		);
	}, [getOutletDailyMenuCategory, categoryID]);

	useEffect(() => {
		if (
			!scheduledDailyMenuCategoryLoading &&
			selectedCategory?.outletDailyMenuItem?.length > 0 &&
			!!menuSearch?.searchString
		) {
			handleSearch(`'"${menuSearch?.searchString ?? ''}"`, ['name']);
		}
	}, [scheduledDailyMenuCategoryLoading, selectedCategory]);

	// function
	const handleChange = (event, newValue) => {
		const categoryID = getOutletDailyMenuCategory?.filter(
			(x, index) => index === newValue,
		)[0]?.ID;

		dispatch({
			type: 'tabValue',
			payload: {
				tab: newValue,
				categoryID: categoryID,
			},
		});

		localStorage.setItem(
			'searchFilter',
			JSON.stringify({
				searchString: menuSearch?.searchString ?? '',
				category: event?.ID,
			}),
		);
	};

	const checkSetMenu = setMenuOption => {
		let outstanding: boolean;
		setMenuOption?.map(x => {
			x?.setMenuOptionItem?.map(y => {
				if (y?.menuItem?.dailyAvailability) {
					if (y?.menuItem?.dailyAvailability?.outstandingQty <= 0) {
						outstanding = true;
					} else if (y?.menuItem?.dailyAvailability?.outstandingQty > 0) {
						outstanding = false;
					}
				}
			});
		});

		return outstanding;
	};

	const allRange = [];
	for (var i = 0; i <= 1440; i++) {
		allRange.push(i);
	}

	const getTimeRange = range => {
		if (range.startTime < range.endTime) {
			let timeRange = [];
			for (var i = range.startTime; i <= range.endTime; i++) {
				timeRange.push(i);
			}
			return timeRange;
		} else {
			let timeRange = [];
			for (var i = range.endTime + 1; i <= range.startTime; i++) {
				timeRange.push(i);
			}
			return allRange.filter(val => !timeRange.includes(val));
		}
	};

	const menuItemListPatronPrice = (item, promo?: Boolean) => {
		let price;
		if (!promo) {
			price = item?.outletDailyMenuItemPatronPrice?.filter(
				itemprice => itemprice?.patronID === state?.selectedOrder?.patronID,
			)[0]?.price;
		} else {
			price = item?.outletDailyPromoMenuItem
				.filter(item => {
					const formattedExItem = {
						startTime:
							Number(item?.startTime.split(':')[0]) * 60 +
							Number(item?.startTime.split(':')[1]),
						endTime:
							Number(item?.endTime.split(':')[0]) * 60 +
							Number(item?.endTime.split(':')[1]),
					};
					//find if existing item overlap with current one
					if (getTimeRange(formattedExItem).includes(currTimeInt)) {
						return item;
					}
				})[0]
				?.outletDailyPromoMenuItemPrice?.filter(
					id => id?.patronID === state?.selectedOrder?.patronID,
				)[0]?.price;
		}
		return price;
	};

	const checkItem = menu => {
		if (menu?.isSetMenu) {
			dispatch({
				type: 'setMenuDialog',
				payload: menu,
			});
		} else {
			dispatch({
				type: 'menuDialog',
				payload: menu,
			});
		}
	};

	const AddMenuOrderItem = async (data: any) => {
		if (data?.getWeight) {
			amount = state?.menuItemPatronPrice * parseFloat(data?.getWeight);
		} else if (data?.openPrice) {
			amount = parseFloat(data?.openPrice) * state?.quantity;
		} else {
			amount = state?.menuItemPatronPrice * state?.quantity;
		}

		createOrderItemWithOptions({
			variables: {
				orderItemInput: {
					unitPrice: parseFloat(data?.openPrice) || state?.menuItemPatronPrice,
					name: state?.currentMenu?.name,
					description: state?.currentMenu?.description,
					outletID: state?.outletID,
					amount: amount,
					baseAmount: amount,
					quantity: data?.getWeight ? 1 : state?.quantity,
					menuItemID: state?.currentMenu?.menuItemID,
					orderID: state?.orderID,
					mealPeriodID: state?.currentMealPeriod?.ID,
					status: OrderItemStatus.Pending,
					specialRequest: audioInput,
					isTakeaway: state?.takeAwayStatus,
					openItemName: data?.openItem,
					weight: parseFloat(data?.getWeight) || 1,
					digitalMenuCategoryID:
						state?.tabValue?.categoryID === ''
							? getOutletDailyMenuCategory[0]?.digitalMenuCategoryID
							: getOutletDailyMenuCategory?.filter(
									x => x?.ID === state?.tabValue?.categoryID,
							  )[0]?.digitalMenuCategoryID,
				},
				orderItemOptionInput: data?.option?.map(orderItemOption => {
					let optionID = Object?.keys(orderItemOption)[0];
					return {
						outletID,
						optionID: optionID,
						orderItemOptionItems:
							typeof orderItemOption[optionID][0] === 'string'
								? [
										{
											outletID,
											optionItemID: JSON.parse(orderItemOption[optionID][0])
												?.itemID,
											price:
												JSON.parse(orderItemOption[optionID][0])?.price *
												state?.quantity,
											unitPrice: JSON.parse(orderItemOption[optionID][0])
												?.price,
										},
								  ]
								: Object.keys(orderItemOption[optionID])
										?.filter(item => item != null)
										?.map(optionItem => {
											return {
												outletID,
												optionItemID: optionItem,
												price:
													orderItemOption[optionID][optionItem] *
													state?.quantity,
												unitPrice: orderItemOption[optionID][optionItem],
											};
										}),
					};
				}),
			},
			refetchQueries: [
				{
					query: GetScheduledDailyMenuCategoryDocument,
					variables: {
						outletID: state?.outletID,
						mealPeriodID: state?.currentMealPeriod?.ID,
					},
				},
				{
					query: GetOrderDocument,
					variables: {
						tableID: state?.tableID,
						status: OrderStatus.Open,
						outletID,
					},
				},
			],
		});
		dispatch({ type: 'quantity', payload: 1 });
		dispatch({ type: 'takeAwayStatus', payload: false });
	};

	const AddSetMenuOrderItem = async (data: any) => {
		if (data?.getWeight) {
			amount = state?.menuItemPatronPrice * parseFloat(data?.getWeight);
		} else if (data?.openPrice) {
			amount = parseFloat(data?.openPrice) * state?.quantity;
		} else {
			amount = state?.menuItemPatronPrice * state?.quantity;
		}

		createOrderItemWithSetMenuOptions({
			variables: {
				orderItemInput: {
					unitPrice: parseFloat(data?.openPrice) || state?.menuItemPatronPrice,
					name: state?.currentMenu?.name,
					description: state?.currentMenu?.description,
					outletID: state?.outletID,
					amount: amount,
					baseAmount: amount,
					quantity: parseFloat(data?.getWeight) || state?.quantity,
					menuItemID: state?.currentMenu?.menuItemID,
					orderID: state?.orderID,
					mealPeriodID: state?.currentMealPeriod?.ID,
					status: OrderItemStatus.Pending,
					specialRequest: audioInput,
					isTakeaway: state?.takeAwayStatus,
					openItemName: data?.openItem,
					weight: parseFloat(data?.getWeight) || 1,
					digitalMenuCategoryID:
						state?.tabValue?.categoryID === ''
							? getOutletDailyMenuCategory[0]?.digitalMenuCategoryID
							: getOutletDailyMenuCategory?.filter(
									x => x?.ID === state?.tabValue?.categoryID,
							  )[0]?.digitalMenuCategoryID,
				},
				orderItemSetMenuOptionInput: data?.option?.map(setMenuOption => {
					let setMenuOptionID = Object?.keys(setMenuOption)[0];
					return {
						outletID,
						setMenuOptionID: setMenuOptionID,
						orderItemSetMenuOptionItemInput:
							typeof setMenuOption[setMenuOptionID][0] === 'string'
								? [
										{
											outletID,
											menuItemID: JSON.parse(setMenuOption[setMenuOptionID][0])
												?.menuItemID,
											setMenuOptionItemID: JSON.parse(
												setMenuOption[setMenuOptionID][0],
											)?.itemID,
											quantity: JSON.parse(setMenuOption[setMenuOptionID][0])
												?.quantity,
										},
								  ]
								: Object.keys(setMenuOption[setMenuOptionID])
										?.filter(setMenuOptionItemID => setMenuOptionItemID != null)
										?.map(setMenuOptionItemID => {
											return {
												outletID,
												setMenuOptionItemID: setMenuOptionItemID,
												menuItemID:
													setMenuOption[setMenuOptionID][setMenuOptionItemID]
														?.menuItemID,
												quantity:
													setMenuOption[setMenuOptionID][setMenuOptionItemID]
														?.quantity,
											};
										}),
					};
				}),
			},
			refetchQueries: [
				{
					query: GetScheduledDailyMenuCategoryDocument,
					variables: {
						outletID: state?.outletID,
						mealPeriodID: state?.currentMealPeriod?.ID,
					},
				},
				{
					query: GetOrderDocument,
					variables: {
						tableID: state?.tableID,
						status: OrderStatus.Open,
						outletID,
					},
				},
			],
		});
		dispatch({ type: 'quantity', payload: 1 });
		dispatch({ type: 'takeawayStatus', payload: false });
	};

	return (
		<>
			{orderLoading && <Loading />}
			{outletLoading && <Loading />}
			{scheduledDailyMenuCategoryLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			{getOutletDailyMenuItemByIDLoading && <Loading />}
			{uomLoading && <Loading />}
			{attachmentLoading && <Loading />}
			{createOrderItemWithOptionsLoading && <Loading />}
			{createOrderItemWithSetMenuOptionsLoading && <Loading />}

			<MainHeader
				onClick={() => {
					localStorage.removeItem(`searchFilter`);
					page?.page === 'new-order'
						? history.push({
								pathname: `/menu/outlet-app/${outletID}/table/grid`,
								state: {
									selectedTable: state?.selectedOrder?.table,
								},
						  })
						: history.push({
								pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}/confirm-order`,
								state: [state?.selectedOrder],
						  });
				}}
				mainBtn="back"
				smTitle="MenuX"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Open Order' },
					{ name: 'Add & Edit Order', current: true },
				]}
				rightRouteSegments={[
					{
						name: `Table: ${state?.selectedOrder?.table?.prefix}${state?.selectedOrder?.table?.tableNumber}`,
					},
				]}
			/>

			<div
				className="core-tab"
				style={{
					position: 'fixed',
					padding: '0px',
					width:
						isDesktop && globalState.drawerOpen
							? 'inherit'
							: '-webkit-fill-available',
				}}
			>
				<AppBar position="static">
					<Tabs
						aria-label="simple tabs example"
						indicatorColor="primary"
						textColor="primary"
						value={state?.tabValue?.tab}
						onChange={handleChange}
						variant={'scrollable'}
					>
						{getOutletDailyMenuCategory?.map((x, index) => (
							<Tab label={x?.name} {...a11yProps(index)} />
						))}
					</Tabs>
				</AppBar>

				<SearchHeader
					datePickerSubInfo
					title="Search here"
					value={`${filteredList?.filter(e => {
						if (selectedCategory?.outletDailyMenuItem) {
							return e;
						}
					})?.length ?? '0'}`}
					onChangeAction={e => {
						handleSearch(`'"${e?.target?.value}"`, ['name']);
						localStorage.setItem(
							'searchFilter',
							JSON.stringify({
								category: menuSearch?.category,
								searchString: e?.target?.value,
							}),
						);
					}}
					fixed
					search
					onCloseAction={() => {
						localStorage.setItem(
							'searchFilter',
							JSON.stringify({
								category: menuSearch?.category ?? '',
								searchString: '',
							}),
						);
						handleSearch('', []);
					}}
					clickText
				/>
			</div>

			<ContentWrapper
				float
				style={{
					marginTop: isDesktop && globalState.drawerOpen ? '149px' : '87px',
				}}
			>
				<List className="core-list" style={{ marginBottom: '100px' }}>
					{filteredList?.map(el => {
						const notAvailable =
							el?.menuItem?.stopSales ||
							(el?.menuItem?.dailyAvailability &&
								el?.menuItem?.dailyAvailability?.outstandingQty <= 0) ||
							checkSetMenu(el?.menuItem?.setMenuOption);

						return (
							<>
								<ListItem
									style={{
										padding: '0px 10px 0px',
										backgroundColor: notAvailable ? '#ffc0c0' : null,
									}}
									disabled={notAvailable ? true : false}
								>
									<div
										className="container"
										style={{
											paddingRight: '6px',
										}}
									>
										<ListItemIcon>
											{el?.attachments?.fileURL ? (
												<>
													<img
														className="img-owned"
														src={el?.attachments?.fileURL}
													/>
												</>
											) : (
												<img
													className="img-owned"
													style={{ border: 'none' }}
													src={NoImage}
												/>
											)}
										</ListItemIcon>
										{notAvailable && (
											<div className="img-sold-out-text">SOLD</div>
										)}
										{el?.promoChecking && (
											<div className="img-promo-text">PROMO</div>
										)}
									</div>

									<ListItemText
										primary={
											<>
												{el?.isOpenPrice || el?.isOpenItem ? (
													<span className="xsTitle">
														{el?.isOpenPrice == true ? (
															<span
																className="xsTitle"
																style={{
																	overflowWrap: 'break-word',
																	whiteSpace: 'normal',
																}}
															>
																{el?.name} (OP)
															</span>
														) : (
															<span
																className="xsTitle"
																style={{
																	overflowWrap: 'break-word',
																	whiteSpace: 'normal',
																}}
															>
																{el?.name} (OI)
															</span>
														)}
													</span>
												) : (
													<span
														className="xsTitle"
														style={{
															overflowWrap: 'break-word',
															whiteSpace: 'normal',
														}}
													>
														{el?.name}
													</span>
												)}
												{el?.isChefRecommended && (
													<img
														className="icon-svg"
														src={RecommendedIcon}
														style={{
															height: '1em',
															marginLeft: '4px',
															marginBottom: '4px',
														}}
													/>
												)}
											</>
										}
										secondary={
											<>
												<span className="flex-space desc">
													<div>{el?.description}</div>
													<div>
														{el?.isByWeight ? (
															<span className="highlight-text">{`${getOutlet[0]
																?.currencyCode ?? ''}${amtStr(
																new BigNumber(
																	menuItemListPatronPrice(el),
																).toNumber(),
															)}/${
																getUOM?.filter(id => id?.ID === el?.uomID)[0]
																	?.code
															}`}</span>
														) : el?.promoChecking ? (
															<>
																<span className="highlight-text">
																	{`${getOutlet[0]?.currencyCode ?? ''}${amtStr(
																		new BigNumber(
																			menuItemListPatronPrice(el, true),
																		).toNumber(),
																	)}`}
																</span>
																<span
																	style={{
																		marginLeft: '8px',
																		textDecoration: 'line-through',
																		fontWeight: 'bolder',
																	}}
																>
																	{`${getOutlet[0]?.currencyCode ?? ''}${amtStr(
																		new BigNumber(
																			menuItemListPatronPrice(el),
																		).toNumber(),
																	)}`}
																</span>
															</>
														) : (
															<span className="highlight-text">
																{getOutlet[0]?.currencyCode ?? ''}
																{amtStr(
																	new BigNumber(
																		menuItemListPatronPrice(el),
																	).toNumber(),
																)}
															</span>
														)}
													</div>
												</span>
											</>
										}
									/>

									<IconButton
										style={{ padding: '0px' }}
										disabled={notAvailable ? true : false}
									>
										<AddBox
											style={{
												fontSize: '32px',
												paddingTop: '28px',
												color: '#ff9800',
											}}
											onClick={() => {
												dispatch({
													type: 'currentMenu',
													payload: el,
												});
												checkItem(el);
											}}
										/>
									</IconButton>
								</ListItem>
							</>
						);
					})}
				</List>
			</ContentWrapper>

			<MenuDialogAdd
				state={state}
				dispatch={dispatch}
				currencyCode={getOutlet[0]?.currencyCode ?? ''}
				attachmentData={attachmentData}
				control={control}
				register={register}
				errors={errors}
				setValue={setValue}
				watch={watch}
				clearErrors={clearErrors}
				unregister={unregister}
				handleSubmit={handleSubmit}
				isDesktop={isDesktop}
				audioInput={audioInput}
				setAudioInput={setAudioInput}
				record={record}
				setRecord={setRecord}
				addOrderItem={AddMenuOrderItem}
				createOrderItemWithOptionsLoading={createOrderItemWithOptionsLoading}
			/>

			<SetMenuDialogAdd
				state={state}
				dispatch={dispatch}
				currencyCode={getOutlet[0]?.currencyCode ?? ''}
				attachmentData={attachmentData}
				setValue={setValue}
				watch={watch}
				control={control}
				register={register}
				handleSubmit={handleSubmit}
				isDesktop={isDesktop}
				addSetMenuOrderItem={AddSetMenuOrderItem}
				createOrderItemWithSetMenuOptions={
					createOrderItemWithSetMenuOptionsLoading
				}
			/>

			<FooterWithTotal
				label="Total"
				value={`${getOutlet[0]?.currencyCode ?? ''}${totalAmountFooter}`}
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
				}}
				option={{
					name: 'Order Summary',
					onClick: () => {
						history.push(
							`/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/order/${state?.orderID}/order-summary`,
						);
					},
					color: 'primary',
				}}
			/>
		</>
	);
};
