import takeawayRoutes from 'containers/OutletModule/Takeaway/TakeawayRoutes';
import React from 'react';
import billItemDiscountRoutes from '../TableSubmenu/BillItemDiscount/BillItemDiscountRoutes';
import billSettlementRoutes from '../TableSubmenu/BillSettlement/BillSettlementRoutes';
import { TableSubmenu } from '../TableSubmenu/TableSubmenu';
import { TableGrid } from './TableGrid';
import newOrderRoutes from 'containers/OutletModule/HomeNew/NewOrder/NewOrderRoutes';
import addEditOrderRoutes from 'containers/OutletModule/HomeNew/AddEditOrder/AddEditOrderRoutes';
import orderSummaryRoutes from 'containers/OutletModule/HomeNew/OrderSummary/OrderSummaryRoutes';
import confirmOrderRoutes from 'containers/OutletModule/HomeNew/ConfirmOrder.tsx/ConfirmOrderRoutes';
import discountRoutes from 'containers/OutletModule/HomeNew/Discount/DiscountRoutes';
import paymentRoutes from 'containers/OutletModule/HomeNew/Payment/PaymentRoutes';
import closeTableRoutes from 'containers/OutletModule/HomeNew/CloseTable/CloseTableRoutes';
import auditLogRoutes from 'containers/OutletModule/HomeNew/AuditLog/AuditLogRoutes';

const tableGridRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid`,
		},
		component: <TableGrid />,
	},
	// {
	// 	props: {
	// 		exact: true,
	// 		path: `/menu/outlet-app/:outletID/table/grid/:tableID`,
	// 	},
	// 	component: <TableSubmenu />,
	// },
	...newOrderRoutes,
	...addEditOrderRoutes,
	...orderSummaryRoutes,
	...confirmOrderRoutes,
	...discountRoutes,
	...paymentRoutes,
	...closeTableRoutes,
	...auditLogRoutes,

	...billItemDiscountRoutes,
	...billSettlementRoutes,
	...takeawayRoutes,
];

export default tableGridRoutes;
