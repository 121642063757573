import React from 'react';
import { AuditLog } from './AuditLog';

const auditLogRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/order/:orderID/payment/audit-log`,
		},
		component: <AuditLog />,
	},
];

export default auditLogRoutes;
