import React from 'react';
import { NewOrder } from './NewOrder';

const newOrderRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/add`,
		},
		component: <NewOrder mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/edit/:orderID`,
		},
		component: <NewOrder mode="edit" />,
	},
];

export default newOrderRoutes;
