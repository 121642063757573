import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import {
	Checkbox,
	Grid,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { amtStr } from 'helpers/numFormatter';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { AddBox } from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import {
	GetHomeTableSummaryDocument,
	GetOrderDocument,
	GetTableSubmenuTableDocument,
	OrderItemDiscountStatus,
	OrderStatus,
	useGetAllOrderItemLazyQuery,
	useGetOrderQuery,
	useGetTableSubmenuTableQuery,
	useTransferItemByQuantityMutation,
} from 'generated/graphql';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'helpers/SystemMsg';
import { useHistory } from 'react-router';
import { ConfirmationDialog } from './ConfirmationDialog';

interface TransferItemProps {
	orderNo: string;
	orderItem: any;
	targetTable: string;
	targetOrder: string;
	name: string;
	quantity: number;
}

export const TransferItemDialog = (props: any) => {
	const { state, dispatch, totalAmount, reloadOrderItem, backOrderID } = props;
	const history = useHistory();

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const { register, handleSubmit, control, watch, setValue } = useForm<
		TransferItemProps
	>({
		defaultValues: { orderNo: state?.selectedOrder?.ID },
	});

	const [selectedOrderItems, setSelectedOrderItems] = useState({});

	const [selectedOrderID, setSelectedOrderID] = useState({
		selectedID: state?.selectedOrder?.ID,
	});

	const [updateConfirm, setUpdateConfirm] = useState(false);
	const [disable, setDisable] = useState(false);
	const [orderItemIDs, setOrderItemIDs] = useState([]);

	const {
		data: { getTable } = { getTable: [] },
		loading: getTableLoading,
	} = useGetTableSubmenuTableQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: state?.outletID,
			orderByAsc: 'sequence',
			isActive: true,
		},
		onCompleted: ({ getTable }) => {},
	});

	const {
		data: { getOrder } = { getOrder: [] },
		loading: orderLoading,
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: {
			tableID: state?.tableID,
			status: OrderStatus.Open,
			outletID: state?.outletID,
		},
	});

	const [
		LoadOrderItem,
		{
			loading: useGetAllOrderItemLoading,
			called: useGetAllOrderItemCalled,
			data: { getAllOrderItem } = { getAllOrderItem: [] },
		},
	] = useGetAllOrderItemLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getAllOrderItem }) => {},
	});

	const [
		orderTransferItemByQuantity,
		{ data: orderTransferItemByQuantityData, loading: transferItemLoading },
	] = useTransferItemByQuantityMutation({
		onError: error => {
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			history.push(`/menu/outlet-app/${state?.outletID}/table/grid`);
		},
		onCompleted: () => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.transferRecord());

			reloadOrderItem({
				variables: {
					orderID: backOrderID,
					outletID: state?.outletID,
				},
			});
		},
	});

	const selectedOI = Object.fromEntries(
		Object.entries(selectedOrderItems).filter(
			([, value]) =>
				(value as { showQuantity: boolean })?.showQuantity === true,
		),
	);

	const selectedOrderItemIDs = Object?.keys(selectedOI);

	const transferInput = selectedOrderItemIDs?.map(oi => {
		return {
			orderItemID: oi,
			removeQty: selectedOrderItems[oi].selectedQuantity,
		};
	});

	const onSubmitTransferItemByQuantity = async (data: any) => {
		orderTransferItemByQuantity({
			variables: {
				transferInput: transferInput?.filter(oi => oi.removeQty !== 0),
				targetOrderID: watch('targetOrder'),
				sourceOrderID: watch('orderNo'),
			},
			refetchQueries: [
				{
					query: GetOrderDocument,
					variables: {
						tableID: state?.tableID,
						outletID: state?.outletID,
						status: OrderStatus.Open,
					},
				},
				{
					query: GetHomeTableSummaryDocument,
					variables: { tableID: state?.tableID },
				},
				{
					query: GetTableSubmenuTableDocument,
					variables: {
						outletID: state?.outletID,
						orderByAsc: 'sequence',
						isActive: true,
					},
				},
			],
		});
		dispatch({ type: 'transferItemDialog', payload: false });
	};

	const handleSelectedOrderID = id => {
		setSelectedOrderID({
			selectedID: id,
		});
	};

	const handleCheckBoxPopUpQuantity = (e, ID, quantity) => {
		if (e.target.checked) {
			setSelectedOrderItems({
				...selectedOrderItems,
				[ID]: {
					showQuantity: true,
					selectedQuantity: 1,
					maxQuantity: quantity,
				},
			});
		} else {
			setSelectedOrderItems({
				...selectedOrderItems,
				[ID]: {
					showQuantity: false,
					selectedQuantity: 1,
					maxQuantity: quantity,
				},
			});
			// selectedOrderItems[ID] = {
			// 	showQuantity: false,
			// 	selectedQuantity: 1,
			// 	maxQuantity: quantity,
			// };
		}
	};

	const clickButton = (mode: string, ID: any, quantity: number) => {
		if (mode === 'add') {
			if (
				selectedOrderItems[ID]?.selectedQuantity <
				selectedOrderItems[ID]?.maxQuantity
			) {
				setSelectedOrderItems({
					...selectedOrderItems,
					[ID]: {
						showQuantity: true,
						selectedQuantity: selectedOrderItems[ID]?.selectedQuantity + 1,
						maxQuantity: quantity,
					},
				});
			} else {
				setSelectedOrderItems({
					...selectedOrderItems,
					[ID]: {
						showQuantity: true,
						selectedQuantity: selectedOrderItems[ID]?.selectedQuantity,
						maxQuantity: quantity,
					},
				});
			}
		} else if (mode === 'reduce') {
			if (selectedOrderItems[ID]?.selectedQuantity === 1) {
				selectedOrderItems[ID].selectedQuantity = 1;
			} else {
				setSelectedOrderItems({
					...selectedOrderItems,
					[ID]: {
						showQuantity: true,
						selectedQuantity: selectedOrderItems[ID]?.selectedQuantity - 1,
						maxQuantity: quantity,
					},
				});
			}
		}
	};

	const filteredArray =
		selectedOrderID.selectedID === undefined ||
		selectedOrderID.selectedID === null
			? state?.itemArray?.filter(list => list?.quantity > 0)
			: state?.itemTransferArray?.filter(list => list?.quantity > 0);

	const ItemList = () => {
		return (
			<>
				<List style={{ backgroundColor: 'white' }}>
					<span className="mdDesc">
						{`Bill Items (${filteredArray?.length ?? 0})`}
					</span>

					{filteredArray?.map(oi => {
						return (
							<ListItem style={{ paddingLeft: '0px', paddingRight: '0px' }}>
								<Checkbox
									onChange={e => {
										handleCheckBoxPopUpQuantity(
											e,
											oi?.ID,
											oi?.quantity -
												(state?.negOrderItem?.[oi?.ID]?.quantity ?? 0),
										);
									}}
									name="orderItemID"
									color="primary"
									checked={selectedOrderItems[oi?.ID]?.showQuantity}
								/>

								<ListItemText
									primary={
										<>
											<Grid container>
												<Grid item xs={9}>
													<span className="mdDesc text-noflow">
														{oi?.openItemName ?? oi.menuItem?.name}&nbsp;
														{'x'}
														{oi?.quantity -
															(state?.negOrderItem?.[oi?.ID]?.quantity ?? 0)}
													</span>
												</Grid>

												<Grid item xs={3}></Grid>
											</Grid>
										</>
									}
									secondary={
										<>
											<Grid container>
												{oi?.orderItemOption?.length
													? oi?.orderItemOption?.map(oio => (
															<>
																{oio?.orderItemOptionItem?.map(oioptitem => {
																	return (
																		<>
																			<Grid item xs={8}>
																				<span className="mdDesc">
																					{oioptitem?.optionItem?.name}
																				</span>
																			</Grid>
																		</>
																	);
																})}
															</>
													  ))
													: oi?.orderItemSetMenuOption?.length
													? oi?.orderItemSetMenuOption?.map(v => (
															<>
																{v?.orderItemSetMenuOptionItem?.map(
																	oisetmenuoptitem => (
																		<Grid item xs={8}>
																			<span className="mdDesc">
																				{
																					oisetmenuoptitem?.setMenuOptionItem
																						?.menuItem?.name
																				}
																			</span>
																		</Grid>
																	),
																)}
															</>
													  ))
													: null}
											</Grid>
										</>
									}
								/>

								<ListItemSecondaryAction
									style={{
										textAlign: 'right',
										top: '-5px',
										transform: 'inherit',
									}}
								>
									<>
										<IndeterminateCheckBoxIcon
											style={{ fontSize: '20px' }}
											className="order-minus"
											onClick={() =>
												clickButton('reduce', oi?.ID, oi?.quantity)
											}
										/>
										<NumberFormat
											customInput={TextField}
											autoComplete="off"
											isNumericString
											fullWidth={false}
											margin="dense"
											defaultValue={1}
											onValueChange={(value: any) => {
												const { formattedValue, floatValue } = value;

												return (
													formattedValue === '' ||
													floatValue ===
														setSelectedOrderItems({
															...selectedOrderItems,
															[oi?.ID]: {
																showQuantity: true,
																selectedQuantity: value?.floatValue,
																maxQuantity:
																	selectedOrderItems[oi?.ID]?.maxQuantity,
															},
														})
												);
											}}
											isAllowed={values => {
												const { formattedValue, floatValue } = values;
												return (
													formattedValue === '' ||
													floatValue <= selectedOrderItems[oi?.ID]?.maxQuantity
												);
											}}
											InputProps={{
												disableUnderline: true,
											}}
											style={{
												width: '10%',
												justifyItems: 'center',
												marginTop: '17px',
												marginBottom: '4px',
											}}
											value={selectedOrderItems[oi?.ID]?.selectedQuantity}
										/>

										<AddBox
											style={{ fontSize: '20px', color: '#ff9800' }}
											className="order-add"
											onClick={() =>
												clickButton(
													'add',
													oi?.ID,
													oi?.quantity -
														state?.negOrderItem[oi?.ID]?.quantity ||
														oi?.quantity,
												)
											}
										/>
									</>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
				</List>
			</>
		);
	};

	useEffect(() => {
		if (selectedOrderID.selectedID) {
			LoadOrderItem({
				variables: {
					orderID: selectedOrderID.selectedID,
					outletID: state?.outletID,
				},
			});
		}
	}, [selectedOrderID.selectedID]);

	useEffect(() => {
		if (getAllOrderItem?.length > 0) {
			let unpaidOrderItem = getAllOrderItem?.filter(
				item => item?.billID === null,
			);
			let temp = {};

			unpaidOrderItem?.map(el => {
				let totalTax = 0;
				el?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				const activeOID = el?.orderItemDiscount?.filter(
					i => i?.status === OrderItemDiscountStatus?.Active,
				)[0];

				let totalOIDTax = 0;
				activeOID?.orderItemDiscountTax?.map(v => {
					totalOIDTax += v?.taxAmount;
				});

				let totalODITax = 0;
				el?.orderDiscountItem?.orderDiscountItemTax?.map(v => {
					totalODITax += v?.taxAmount;
				});

				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID].quantity += el?.quantity * -1;
						temp[el?.refID].amount += el?.amount;
						temp[el?.refID].taxAmount += totalTax;

						temp[el?.refID].orderItemDiscBaseAmount += activeOID?.baseAmount;
						temp[el?.refID].orderItemDiscTaxAmount += totalOIDTax;

						temp[el?.refID].orderDiscountItemBaseAmount +=
							el?.orderDiscountItem?.baseAmount;
						temp[el?.refID].orderDiscountItemTaxAmount += totalODITax;
					} else {
						temp[el?.refID] = {
							quantity: el?.quantity * -1,
							amount: el?.amount * -1,
							taxAmount: totalTax * -1,

							orderItemDiscBaseAmount:
								isNaN(activeOID?.baseAmount * -1) === true
									? 0
									: activeOID?.baseAmount * -1,
							orderItemDiscTaxAmount:
								isNaN(totalOIDTax * -1) === true ? 0 : totalOIDTax * -1,

							orderDiscountItemBaseAmount:
								isNaN(el?.orderDiscountItem?.baseAmount * -1) === true
									? 0
									: el?.orderDiscountItem?.baseAmount * -1,
							orderDiscountItemTaxAmount:
								isNaN(totalODITax * -1) === true ? 0 : totalODITax * -1,

							orderItemOptions: [],
						};

						// Loop through the orderItemOptionArray
						for (let i = 0; i < el?.orderItemOption?.length; i++) {
							const option = el?.orderItemOption[i];
							const optionItem = el?.orderItemOption[i]?.orderItemOptionItem;

							let optionData;

							if (optionItem) {
								optionItem?.map(x => {
									let totalTax = 0;
									x?.orderItemOptionItemTax?.map(v => {
										totalTax += v?.taxAmount;
									});

									let totalOptItemOIDTax = 0;
									x?.orderItemOptionItemDiscountTax?.map(v => {
										totalOptItemOIDTax += v?.taxAmount;
									});

									let totalOptItemODITax = 0;
									x?.orderDiscountItemOptionTax?.map(v => {
										totalOptItemODITax += v?.taxAmount;
									});

									optionData = {
										optionID: option?.optionID,
										orderItemOptionItem: {
											basePrice: x?.basePrice * -1,
											taxAmount: totalTax * -1,
											discountBaseAmount: x?.discountBaseAmount * -1,
											itemDiscountTaxAmount: totalOptItemOIDTax * -1,
											discountItemTaxAmount: totalOptItemODITax * -1,
										},
									};
								});
								temp[el?.refID].orderItemOptions.push(optionData);
							}
						}
					}
				}
			});

			const filtered = unpaidOrderItem
				?.map(el => {
					if (el?.quantity - temp[el?.ID]?.quantity <= 0) {
						return { ...el, void: true };
					} else {
						return { ...el, void: false };
					}
				})
				?.filter(list => list.void === false);

			dispatch({ type: 'itemTransferArray', payload: filtered });
		}
	}, [getAllOrderItem?.length]);

	const checkedOrderItemsIDs = selectedOrderItemIDs
		?.map(x => selectedOrderItems[x]?.showQuantity)
		?.filter(x => x === true);

	useEffect(() => {
		let oi = checkedOrderItemsIDs[0];
		if (
			oi === true &&
			watch('orderNo') !== undefined &&
			filteredArray?.length > 0 &&
			watch('targetOrder') !== undefined &&
			watch('targetTable') !== undefined
		) {
			return setDisable(false);
		} else {
			setDisable(true);
		}
	});

	const targetPatron = getTable
		?.find(x => x?.ID === watch('targetTable'))
		?.order?.find(y => y?.ID === watch('targetOrder'))?.patron?.ID;

	const currPatron = getOrder?.filter(x => x?.ID === watch('orderNo'))[0]
		?.patronID;

	const discount = getOrder?.filter(x => x?.ID === watch('orderNo'))[0]
		?.totalDiscountAmount;

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={state?.transferItemDialog}
				onClose={() => {
					dispatch({ type: 'transferItemDialog', payload: false });
					setSelectedOrderItems({});
					setSelectedOrderID({ selectedID: null });
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Item Transfer</div>
									</div>
								</div>
								<div className="infoline-content desc">
									<span className="xsTitle infoline">
										{`${state?.selectedOrder?.table?.prefix}${state?.selectedOrder?.table?.tableNumber}`}
									</span>
									<span
										className="xsTitle flex-space infoline"
										style={{ paddingLeft: '4px' }}
									>
										{`${state?.selectedOrder?.docNo}`}
									</span>
									<div className="xsTitle infoline rightText">{`Total Item: ${
										filteredArray?.length
									} | ${amtStr(totalAmount)}`}</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="rm-padding table-wrap sample">
								<Controller
									as={
										<TextField
											select
											required
											defaultValue={state?.selectedOrder?.ID}
										>
											{getOrder?.map(el => (
												<MenuItem
													key={el.ID}
													value={el.ID}
													onClick={e => {
														handleSelectedOrderID(el?.ID);
													}}
													selected={selectedOrderID.selectedID === el?.ID}
												>
													<span>{el.docNo} &nbsp;</span>
													<span style={{ color: '#ff9800' }}>
														{el.patronName}
													</span>
												</MenuItem>
											))}
										</TextField>
									}
									label={'Order No.'}
									name={'orderNo'}
									autoComplete="off"
									control={control}
									multiline={true}
									fullWidth
									margin="normal"
									required={true}
									ref={register}
									defaultValue={state?.selectedOrder?.ID}
								/>

								<Controller
									as={<ItemList />}
									name={'orderItem'}
									autoComplete="off"
									control={control}
									multiline={true}
									fullWidth
									margin="normal"
									ref={register}
								/>

								<Controller
									as={
										<TextField select>
											{getTable
												?.filter(
													x =>
														x?.ID === state?.tableID ||
														x?.status === 'OCCUPIED',
												)
												?.map(el => (
													<MenuItem key={el.ID} value={el.ID}>
														<span>{el.prefix}</span>
														<span>{el.tableNumber}</span>
													</MenuItem>
												))}
										</TextField>
									}
									label={'To Table'}
									name={'targetTable'}
									autoComplete="off"
									control={control}
									multiline={true}
									fullWidth
									margin="normal"
									required={true}
									ref={register}
								/>

								<Controller
									as={
										<TextField select>
											{getTable
												?.filter(x => x?.ID === watch('targetTable'))[0]
												?.order?.filter(y => {
													return (
														y?.status === OrderStatus.Open &&
														y?.ID !== watch('orderNo')
													);
												})
												?.map(el => (
													<MenuItem key={el.ID} value={el.ID}>
														<span>{el.docNo} &nbsp;</span>
														<span style={{ color: '#ff9800' }}>
															{el.patronName}
														</span>
													</MenuItem>
												))}
										</TextField>
									}
									label={'To Order No.'}
									name={'targetOrder'}
									autoComplete="off"
									control={control}
									multiline={true}
									fullWidth
									margin="normal"
									required={true}
									ref={register}
									disabled={!watch('targetTable') ? true : false}
								/>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () =>
										dispatch({ type: 'transferItemDialog', payload: false }),
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										if (currPatron === targetPatron && discount === 0) {
											handleSubmit(onSubmitTransferItemByQuantity)();
										} else if (currPatron !== targetPatron) {
											setUpdateConfirm(true);
										} else if (discount !== 0) {
											setUpdateConfirm(true);
										} else {
											setUpdateConfirm(true);
										}
									},
									variant: 'contained',
									color: disable === false ? 'primary' : 'inherit',
									disabled: disable,
								},
							},
						],
					},
				}}
			/>

			{updateConfirm && (
				<ConfirmationDialog
					updateConfirm={updateConfirm}
					setUpdateConfirm={setUpdateConfirm}
					handleSubmit={handleSubmit}
					onSubmit={onSubmitTransferItemByQuantity}
					targetPatron={targetPatron}
					currPatron={currPatron}
					discount={discount}
				/>
			)}
		</>
	);
};
