import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	StyledMenu,
	StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu';
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	useMediaQuery,
} from '@material-ui/core';
import { NoImage } from 'assets';
import {
	GetBillDiscountOrderDocument,
	GetOrderDocument,
	GetOrderItemDocument,
	GetOrderSummaryDocument,
	OrderItemStatus,
	OrderStatus,
	useConfirmOrderItemMutation,
	useGetCurrentMealPeriodLazyQuery,
	useGetOrderLazyQuery,
	useGetOrderSummaryQuery,
	useGetOutletNameQuery,
	useGetUomQuery,
	usePostPrintDataMutation,
	useUpdateOrderItemWithOptionsMutation,
	useUpdateOrderItemWithSetMenuOptionsMutation,
} from 'generated/graphql';
import { formatTime } from 'helpers/hooks/formatTime';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { IAction } from 'helpers/model';
import React, {
	Reducer,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import { useHistory, useParams } from 'react-router';
import PickupIcon from 'assets/icons/pickup_grey.svg';
import { amtStr } from 'helpers/numFormatter';
import BigNumber from 'bignumber.js';
import EditIcon from '@material-ui/icons/Edit';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import './orderSummary.scss';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import { MenuDialogSummary, SetMenuDialogSummary } from './CustomDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'helpers/SystemMsg';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { AmountFooter } from '../AmountFooter';
import { FooterAmount, NameByCustomerType } from '../SharedFunction';
import AppContext from 'containers/App/Store/AppContext';

interface Props {
	outletID: any;
	tableID: any;
	orderID: any;
	currentMealPeriod: any;
	selectedOrder: any;
	menuDialog: boolean;
	setMenuDialog: boolean;
	selectedMenu: any;
	quantity: any;
	takeAwayStatus: boolean;
	isSubmitting: boolean;
	weightName: any;
	optionErr: boolean;
	uniqueKpArray: any;
	kpObject: any;
	kitchenPrinterObject: any;
	printerName: any;
}

interface OptionItem {
	remark: string;
	name: string;
	openItem: String;
	openPrice: number;
	specialRequest: String;
	option: any[];
	qty: number;
}

export const OrderSummary = () => {
	const history = useHistory();

	const { tableID, outletID, orderID } = useParams<Record<string, any>>();

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const { globalState }: any = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const initialState: Props = {
		outletID: outletID,
		tableID: tableID,
		orderID: orderID,
		currentMealPeriod: null,
		selectedOrder: null,
		menuDialog: false,
		setMenuDialog: false,
		selectedMenu: null,
		quantity: 1,
		takeAwayStatus: false,
		isSubmitting: false,
		weightName: null,
		optionErr: false,
		uniqueKpArray: [],
		kpObject: {},
		kitchenPrinterObject: null,
		printerName: [],
	};

	const reducer: Reducer<Props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);
	const [setVariables, name] = NameByCustomerType();

	const [audioInput, setAudioInput] = useState('');
	const [record, setRecord] = useState(false);

	let amount = 0;

	// form
	const yupSchema = yup.object().shape({
		openItem: state?.selectedMenu?.isOpenItem
			? yup
					.string()
					.required('Open item name is required')
					.trim()
			: null,
		openPrice: state?.selectedMenu?.isOpenItem
			? yup.number().required('Open item price is required')
			: null,
	});

	const {
		handleSubmit,
		control,
		register,
		watch,
		setValue,
		unregister,
		reset,
		clearErrors,
		errors,
	} = useForm<OptionItem>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	// API
	const {
		loading: outletLoading,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
			LoadOrder({
				variables: {
					tableID: state?.tableID,
					status: OrderStatus.Open,
					outletID: state?.outletID,
				},
			});
		},
	});

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			dispatch({
				type: 'currentMealPeriod',
				payload: getCurrentMealPeriod,
			});
		},
	});

	const [
		LoadOrder,
		{ data: { getOrder } = { getOrder: [] }, loading: orderLoading },
	] = useGetOrderLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOrder }) => {
			dispatch({
				type: 'selectedOrder',
				payload: getOrder?.filter(order => order?.ID === state?.orderID)[0],
			});
			setVariables({
				order: getOrder?.filter(order => order?.ID === state?.orderID)[0],
				outletID: state?.outletID,
				hotelID: getOutlet[0]?.outletChannel?.hotelID,
			});
		},
	});

	const {
		data: { getOrderItem } = { getOrderItem: [] },
		loading: orderItemLoading,
	} = useGetOrderSummaryQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderID: state?.orderID,
			status: OrderItemStatus.Pending,
		},
		onCompleted: ({ getOrderItem }) => {
			if (getOrderItem?.length > 0) {
        generateReceiptData();
      }
		},
	});

	const {
		data: { getUOM } = { getUOM: [] },
		loading: uomLoading,
	} = useGetUomQuery({
		fetchPolicy: 'network-only',
	});

	const [
		updateOrderItemWithOptions,
		{ loading: orderItemWithOptionsLoading },
	] = useUpdateOrderItemWithOptionsMutation({
		onError: error => {
			dispatch({ type: 'optionError', payload: true });
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			history.push(`/menu/outlet-app/${outletID}/table/grid`);
		},
		onCompleted: ({ updateOrderItemWithOptions }) => {
			dispatch({ type: 'optionError', payload: false });
			dispatch({ type: 'menuDialog', payload: false });
			dispatch({ type: 'isSubmitting', payload: false });
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const [
		updateOrderItemWithSetMenuOptions,
		{ loading: orderItemWithSetMenuOptionsLoading },
	] = useUpdateOrderItemWithSetMenuOptionsMutation({
		onError: error => {
			dispatch({ type: 'optionError', payload: true });
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			history.push(`/menu/outlet-app/${outletID}/table/grid`);
		},
		onCompleted: ({ updateOrderItemWithSetMenuOptions }) => {
			dispatch({ type: 'setMenuDialog', payload: false });
			dispatch({ type: 'optionError', payload: false });
			dispatch({ type: 'isSubmitting', payload: false });
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const [
		confirmOrderItem,
		{ loading: confirmOrderLoading },
	] = useConfirmOrderItemMutation({
		onError: error => {
			dispatch({ type: 'optionError', payload: true });
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			history.push(`/menu/outlet-app/${outletID}/table/grid`);
		},
		onCompleted: data => {
			history.push(`/menu/outlet-app/${outletID}/table/grid`);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.confirmRecord());
			dispatch({ type: 'isSubmitting', payload: false });
		},
	});

	const [
		postPrintData,
		{ loading: getpostPrintLoading },
	] = usePostPrintDataMutation({
		fetchPolicy: 'network-only',
	});

	// function
	const handleChangeOrder = (e, obj) => {
		if (obj?.ID !== state?.orderID) {
			dispatch({
				type: 'selectedOrder',
				payload: getOrder?.filter(order => order?.ID === obj?.ID)[0],
			});
			dispatch({ type: 'orderID', payload: obj?.ID });
			history.push(
				`/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/order/${state?.orderID}/order-summary`,
			);
		}
	};

	const checkItem = item => {
		if (item?.menuItem?.isSetMenu && item?.status === 'PENDING') {
			dispatch({
				type: 'setMenuDialog',
				payload: true,
			});
		} else if (!item?.menuItem?.isSetMenu && item?.status === 'PENDING') {
			dispatch({
				type: 'menuDialog',
				payload: true,
			});
		}
		setAudioInput(item?.specialRequest);
		setValue('specialRequest', item?.specialRequest);
	};

	const UpdateMenuOrderItem = (data: any) => {
		if (data?.getWeight) {
			amount = parseFloat(data?.getWeight) * state?.selectedMenu?.unitPrice;
		} else if (data?.openPrice) {
			amount = parseFloat(data?.openPrice);
		} else {
			amount = state?.selectedMenu?.unitPrice * state?.quantity;
		}

		updateOrderItemWithOptions({
			variables: {
				orderItemInput: {
					ID: state?.selectedMenu?.ID,
					outletID: outletID,
					amount: amount,
					baseAmount: amount,
					quantity: data?.getWeight ? 1 : state?.quantity,
					unitPrice:
						parseFloat(data?.openPrice) || state?.selectedMenu?.unitPrice,
					menuItemID: state?.selectedMenu?.menuItemID,
					orderID: orderID,
					mealPeriodID: state?.currentMealPeriod?.ID,
					status: OrderItemStatus.Pending,
					specialRequest: audioInput,
					isTakeaway: state?.takeAwayStatus,
					openItemName: data?.openItem || state?.selectedMenu?.openItemName,
					weight: parseFloat(data?.getWeight) || state?.selectedMenu?.weight,
				},
				orderItemOptionInput: data?.option?.map(orderItemOption => {
					let optionID = Object.keys(orderItemOption)[0];
					return {
						outletID,
						optionID: optionID,
						orderItemOptionItems:
							typeof orderItemOption[optionID][0] === 'string'
								? [
										{
											outletID,
											optionItemID: JSON.parse(orderItemOption[optionID][0])
												?.itemID,
											price:
												JSON.parse(orderItemOption[optionID][0])?.price *
												state?.quantity,
											basePrice:
												JSON.parse(orderItemOption[optionID][0])?.price *
												state?.quantity,
										},
								  ]
								: Object.keys(orderItemOption[optionID])
										?.filter(item => item !== null)
										?.map(optionItem => {
											return {
												outletID,
												optionItemID: optionItem,
												price:
													orderItemOption[optionID][optionItem] *
													state?.quantity,
												basePrice:
													orderItemOption[optionID][optionItem] *
													state?.quantity,
											};
										}),
					};
				}),
			},
			refetchQueries: [
				{
					query: GetOrderSummaryDocument,
					variables: {
						orderID: state?.orderID,
						status: OrderItemStatus.Pending,
					},
				},
				{
					query: GetOrderDocument,
					variables: {
						tableID: state?.tableID,
						status: OrderStatus.Open,
						outletID: state?.outletID,
					},
				},
			],
		});
		dispatch({ type: 'quantity', payload: state?.selectedMenu?.quantity });
		dispatch({ type: 'takeAwayStatus', payload: false });
	};

	const UpdateSetMenuOrderItem = (data: any) => {
		if (data?.getWeight) {
			amount = parseFloat(data?.getWeight) * state?.selectedMenu?.unitPrice;
		} else if (data?.openPrice) {
			amount = parseFloat(data?.openPrice);
		} else {
			amount = state?.selectedMenu?.unitPrice * state?.quantity;
		}

		updateOrderItemWithSetMenuOptions({
			variables: {
				orderItemInput: {
					ID: state?.selectedMenu?.ID,
					outletID: state?.outletID,
					amount: amount,
					baseAmount: amount,
					quantity: data?.getWeight ? 1 : state?.quantity,
					unitPrice:
						parseFloat(data?.openPrice) || state?.selectedMenu?.unitPrice,
					menuItemID: state?.selectedMenu?.menuItemID,
					orderID: state?.orderID,
					mealPeriodID: state?.currentMealPeriod?.ID,
					status: OrderItemStatus.Pending,
					specialRequest: audioInput,
					isTakeaway: state?.takeAwayStatus,
					openItemName: data?.openItem || state?.selectedMenu?.openItemName,
					weight: parseFloat(data?.getWeight) || state?.selectedMenu?.weight,
				},
				orderItemSetMenuOptionInput: data?.option?.map(setMenuOption => {
					let setMenuOptionID = Object.keys(setMenuOption)[0];
					return {
						outletID,
						setMenuOptionID: setMenuOptionID,
						orderItemSetMenuOptionItemInput:
							typeof setMenuOption[setMenuOptionID][0] === 'string'
								? [
										{
											outletID,
											menuItemID: JSON.parse(setMenuOption[setMenuOptionID][0])
												?.menuItemID,
											setMenuOptionItemID: JSON.parse(
												setMenuOption[setMenuOptionID][0],
											)?.itemID,
											quantity: JSON.parse(setMenuOption[setMenuOptionID][0])
												?.quantity,
										},
								  ]
								: Object.keys(setMenuOption[setMenuOptionID])
										?.filter(setMenuOptionItemID => setMenuOptionItemID != null)
										?.map(setMenuOptionItemID => {
											return {
												outletID,
												setMenuOptionItemID: setMenuOptionItemID,
												menuItemID:
													setMenuOption[setMenuOptionID][setMenuOptionItemID]
														?.menuItemID,
												quantity:
													setMenuOption[setMenuOptionID][setMenuOptionItemID]
														?.quantity,
											};
										}),
					};
				}),
			},
			refetchQueries: [
				{
					query: GetOrderSummaryDocument,
					variables: {
						orderID: orderID,
						status: OrderItemStatus.Pending,
					},
				},
				{
					query: GetOrderDocument,
					variables: {
						tableID: tableID,
						status: OrderStatus.Open,
						outletID,
					},
				},
			],
		});
		dispatch({ type: 'quantity', payload: state?.selectedMenu?.quantity });
		dispatch({ type: 'takeAwayStatus', payload: false });
	};

	const crossCheckOptionItem = ID => {
		const defaultOrderItemOptionItems = state?.selectedMenu?.orderItemOption
			?.map(orderItemOption =>
				orderItemOption?.orderItemOptionItem?.map(
					optionItem => optionItem?.optionItemID,
				),
			)
			.flat();
		return defaultOrderItemOptionItems?.includes(ID);
	};

	const crossCheckSetMenuOptionItem = ID => {
		const defaultOrderItemSetMenuOptionItems = state?.selectedMenu?.orderItemSetMenuOption
			?.map(orderItemSetMenuOption =>
				orderItemSetMenuOption?.orderItemSetMenuOptionItem?.map(
					orderItemSetMenuOptionItem =>
						orderItemSetMenuOptionItem?.setMenuOptionItemID,
				),
			)
			.flat();
		return defaultOrderItemSetMenuOptionItems?.includes(ID);
	};

	const orderStatus = (data: any) => {
		confirmOrderItem({
			variables: {
				orderItemIDs: getOrderItem?.map(e => {
					return e?.ID;
				}),
				orderID: orderID,
			},
			refetchQueries: [
				{
					query: GetOrderSummaryDocument,
					variables: {
						orderID: orderID,
						status: OrderItemStatus.Confirmed,
						outletID,
					},
				},
				{
					query: GetOrderItemDocument,
					variables: { orderID, status: OrderItemStatus.Confirmed, outletID },
				},
				{
					query: GetBillDiscountOrderDocument,
					variables: {
						tableID: tableID,
						status: OrderStatus.Open,
						outletID,
					},
				},
			],
		});
	};

	const generateReceiptData = () => {
		let uniqueprinterName = new Set(
			getOrderItem?.filter(
				oi =>
					oi?.menuItem?.kitchenPrinterID !== null &&
					oi?.menuItem?.kitchenPrinterID !== undefined,
			),
		);
		dispatch({ type: 'uniqueKpArray', payload: [...uniqueprinterName!] });

		let temporaryObject = {};

		getOrderItem?.map(oi => {
			if (!(oi?.menuItem?.kitchenPrinter?.name in temporaryObject)) {
				temporaryObject[oi?.menuItem?.kitchenPrinter?.name] = [oi];
			} else {
				temporaryObject[oi?.menuItem?.kitchenPrinter?.name].push(oi);
			}
		});
		dispatch({ type: 'kpObject', payload: temporaryObject });
	};

	const handlePrinting = async () => {
		if (state?.uniqueKpArray?.length > 0) {
			await state?.uniqueKpArray?.map(async (el, index) => {
				dispatch({
					type: 'kitchenPrinterObject',
					payload: state?.kpObject[el?.menuItem?.kitchenPrinter?.name],
				});

				dispatch({
					type: 'printerName',
					payload: el?.menuItem?.kitchenPrinter?.name,
				});
			});
		} else {
			!state?.isSubmitting && handleSubmit(orderStatus)();
		}
		return true;
	};

	// useEffect
	useEffect(() => {
		dispatch({
			type: 'weightName',
			payload: getUOM?.filter(
				id => id?.ID === state?.selectedMenu?.menuItem?.uomID,
			)[0],
		});
	}, [state?.selectedMenu]);

	useEffect(() => {
		if (state?.selectedMenu?.specialRequest) {
			setValue('specialRequest', state?.selectedMenu?.specialRequest);
		}
	}, [state?.selectedMenu]);

	useEffect(() => {
		if (state?.menuDialog) {
			state?.selectedMenu?.menuItem?.menuItemOptions?.map(
				(menuItemOption, optionIndex) => {
					let maxSelections = menuItemOption?.maxSelections;
					menuItemOption?.menuItemOptionItem?.map(
						(optionItem, optionItemIndex) => {
							if (crossCheckOptionItem(optionItem?.optionItemID)) {
								if (maxSelections === 1) {
									register(
										`option[${optionIndex}].${menuItemOption?.optionID}[${0}]`,
									);
									setValue(
										`option[${optionIndex}].${menuItemOption?.optionID}[${0}]`,
										JSON.stringify({
											itemID: optionItem?.optionItemID,
											price: optionItem?.price,
										}),
									);
								} else {
									register(
										`option[${optionIndex}].${menuItemOption?.optionID}[${optionItem?.optionItemID}]`,
									);
									setValue(
										`option[${optionIndex}].${menuItemOption?.optionID}[${optionItem?.optionItemID}]`,
										optionItem?.price,
									);
								}
							}
						},
					);
				},
			);
		} else {
			unregister(`option`);
			reset();
		}
	}, [state?.menuDialog]);

	useEffect(() => {
		if (state?.setMenuDialog) {
			state?.selectedMenu?.menuItem?.setMenuOption?.map(
				(setMenuOption, setMenuOptionIndex) => {
					let maxSelections = setMenuOption?.maxSelections;
					setMenuOption?.setMenuOptionItem?.map(setMenuOptionItem => {
						if (crossCheckSetMenuOptionItem(setMenuOptionItem?.ID)) {
							if (maxSelections === 0) {
								register(
									`option[0].${setMenuOption?.ID}[${setMenuOptionItem?.ID}]`,
								);
								setValue(
									`option[0].${setMenuOption?.ID}[${setMenuOptionItem?.ID}]`,
									{
										quantity: setMenuOptionItem?.quantity || 1,
										menuItemID: setMenuOptionItem?.menuItem?.ID,
									},
								);
							} else if (maxSelections === 1) {
								register(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${0}]`,
								);
								setValue(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${0}]`,
									JSON.stringify({
										itemID: setMenuOptionItem?.ID,
										quantity: setMenuOptionItem?.quantity || 1,
										menuItemID: setMenuOptionItem?.menuItem?.ID,
									}),
								);
							} else if (maxSelections > 1) {
								register(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${setMenuOptionItem?.ID}]`,
								);
								setValue(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${setMenuOptionItem?.ID}]`,
									{
										quantity: setMenuOptionItem?.quantity || 1,
										menuItemID: setMenuOptionItem?.menuItem?.ID,
									},
								);
							}
						}
					});
				},
			);
		} else {
			unregister(`option`);
			reset();
		}
	}, [state?.setMenuDialog]);

	// useEffect(() => {
  //   console.log('1 >>>')
	// 	if (!orderLoading && getOrderItem?.length > 0) {
	// 		generateReceiptData();
	// 	}
	// }, [getOrderItem]);

	useEffect(() => {
		const orderItemIDs = getOrderItem
			?.filter(
				oi =>
					oi?.menuItem?.kitchenPrinterID !== null &&
					oi?.menuItem?.kitchenPrinterID !== undefined,
			)
			?.map(x => x?.ID);

		if (state?.kitchenPrinterObject?.length > 0) {
			handleSubmit(orderStatus)();

			postPrintData({
				variables: {
					printData: {
						outletID,
						printerName: state?.printerName,
						outletName: getOutlet[0]?.name,
						orderID: state?.selectedOrder?.ID,
						orderItemIDs: orderItemIDs,
						orderMode: 'confirm',
					},
				},
			});
		}
	}, [state?.kitchenPrinterObject?.length, state?.printerName]);

	let footerAmount = FooterAmount(getOrderItem, 'orderSummary', []);

	return (
		<>
			{outletLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			{orderLoading && <Loading />}
			{orderItemLoading && <Loading />}
			{uomLoading && <Loading />}
			{orderItemWithOptionsLoading && <Loading />}
			{orderItemWithSetMenuOptionsLoading && <Loading />}

			<MainHeader
				onClick={() => {
					history.push({
						pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/order/${state?.orderID}/add-edit-order`,
					});
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Add & Edit Order' },
					{ name: 'Order Summary', current: true },
				]}
				rightRouteSegments={[
					{
						name: state?.currentMealPeriod
							? `${state?.currentMealPeriod?.name}: ${formatTime(
									state?.currentMealPeriod?.startTime,
							  )} - ${formatTime(state?.currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>

			<DynamicSubHeader
				title={
					state?.selectedOrder !== null && (
						<>
							<span
								className="highlight"
								style={{ color: '#ff9800', paddingRight: '4px' }}
							>
								{`${state?.selectedOrder?.table?.prefix}${state?.selectedOrder?.table?.tableNumber}`}
							</span>
							<span>{`${state?.selectedOrder?.docNo}`}</span>
						</>
					)
				}
				rightText={
					state?.selectedOrder !== null && (
						<div>{`${state?.selectedOrder?.pax} Pax`}</div>
					)
				}
				handleClick={e => handleClick(e, state?.orderID, 0)}
				dropDownOptions={
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
						onClick={handleClose}
					>
						{getOrder.map((order, index) => (
							<StyledMenuItem
								key={index}
								value={order.ID}
								defaultValue={order.ID}
								onClick={e => {
									handleChangeOrder(e, order);
								}}
							>
								<ListItemText primary={order?.docNo} />
							</StyledMenuItem>
						))}
					</StyledMenu>
				}
				infoLine={
					<>
						<span className="desc">{name}</span>
						<span className="desc" style={{ float: 'right' }}>
							{state?.selectedOrder?.patron?.patronClass}
						</span>
					</>
				}
			/>

			<ContentWrapper
				footer
				float
				style={{ marginTop: isDesktop ? '125px' : '55px' }}
			>
				<List
					className="core-list"
					style={{ zIndex: 40, position: 'relative' }}
				>
					{getOrderItem === undefined || getOrderItem?.length === 0 ? (
						<EmptyList
							title="There are no orders found"
							subtitle="Please make a new order in the Menu "
						/>
					) : (
						getOrderItem?.map((v, i) => (
							<ListItem style={{ padding: '0px 10px 7px' }}>
								<ListItemIcon>
									{v?.menuItem?.attachments?.fileURL ? (
										<img
											className="img-owned"
											src={v?.menuItem?.attachments?.fileURL}
										/>
									) : (
										<img
											className="img-owned"
											style={{ border: 'none' }}
											src={NoImage}
										/>
									)}
									{v?.isTakeaway && (
										<img className="img-takeaway-logo" src={PickupIcon} />
									)}
								</ListItemIcon>

								<ListItemText
									primary={
										<>
											<span className="xsTitle flex-space">
												{v?.menuItem?.name} x{v.quantity}
											</span>
											<span className="xsTitle highlight-text">
												{`${getOutlet[0]?.currencyCode ?? ''}${amtStr(
													new BigNumber(v?.totalInfo?.totalBaseAmount),
												)}`}
											</span>
										</>
									}
									secondary={
										<>
											<span className="flex-space desc">
												<div
													style={{
														overflowWrap: 'break-word',
														whiteSpace: 'normal',
													}}
												>
													{v?.menuItem?.description}
												</div>
												<div>
													{v?.orderItemOption?.map(x =>
														x?.orderItemOptionItem?.map(x => (
															<div>{x?.optionItem?.name}</div>
														)),
													)}
												</div>
												<div>{v.specialRequest}</div>
											</span>

											<EditIcon
												style={
													v?.status === 'PENDING'
														? {
																fontSize: '23px',
																color: 'white',
																marginTop: '15px',
																backgroundColor: '#ff9800',
																borderRadius: '3px',
														  }
														: {
																fontSize: '23px',
																color: 'white',
																marginTop: '15px',
																backgroundColor: 'grey',
																borderRadius: '3px',
														  }
												}
												onClick={() => {
													checkItem(v);
													dispatch({ type: 'selectedMenu', payload: { ...v } });
													dispatch({ type: 'quantity', payload: v?.quantity });
													dispatch({
														type: 'takeAwayStatus',
														payload: v?.isTakeaway,
													});
												}}
											/>
										</>
									}
								/>
							</ListItem>
						))
					)}
				</List>
			</ContentWrapper>

			<div style={{ marginBottom: '50px' }}></div>

			<AmountFooter
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(95% - 240px)' : '95%',
					zIndex: 50,
					backgroundColor: '#F5F5F5',
					position: 'fixed',
				}}
				currencyCode={getOutlet[0]?.currencyCode ?? ''}
				subtotal={amtStr(footerAmount.subtotal)}
				discount={amtStr(footerAmount.discount)}
				serviceTax={amtStr(footerAmount.tax)}
				total={amtStr(footerAmount.total)}
				rounding={amtStr(footerAmount.roundingAmount)}
				page={'orderSummary'}
			/>

			<MenuDialogSummary
				state={state}
				dispatch={dispatch}
				audioInput={audioInput}
				setAudioInput={setAudioInput}
				record={record}
				setRecord={setRecord}
				currencyCode={getOutlet[0]?.currencyCode ?? ''}
				control={control}
				register={register}
				errors={errors}
				watch={watch}
				setValue={setValue}
				unregister={unregister}
				clearErrors={clearErrors}
				handleSubmit={handleSubmit}
				isDesktop={isDesktop}
				updateOrderItem={UpdateMenuOrderItem}
				orderItemWithOptionsLoading={orderItemWithOptionsLoading}
			/>

			<SetMenuDialogSummary
				state={state}
				dispatch={dispatch}
				currencyCode={getOutlet[0]?.currencyCode ?? ''}
				isDesktop={isDesktop}
				watch={watch}
				setValue={setValue}
				control={control}
				register={register}
				unregister={unregister}
				handleSubmit={handleSubmit}
				updateSetMenuOrderItem={UpdateSetMenuOrderItem}
				orderItemWithSetMenuOptionsLoading={orderItemWithSetMenuOptionsLoading}
			/>

			<Footer
				options={[
					{
						name: 'Confirm',
						onClick: e => {
							dispatch({ type: 'isSubmitting', payload: true });
							if (getOrderItem?.length > 0) {
								!state?.isSubmitting && handlePrinting();
							}
							e.stopPropagation();
						},
						color: getOrderItem?.length > 0 ? 'primary' : '#faf2e8',
						disabled: getOrderItem?.length === 0,
						props: { type: 'submit' },
					},
				]}
			/>
		</>
	);
};
