import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetTableDocument,
	OrderStatus,
	useCloseTableStatusMutation,
} from 'generated/graphql';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

interface CloseTableProps {
	tableID?: String;
	status?: OrderStatus;
}

export const CloseTableDialog = (props: any) => {
	const { state, dispatch, table, getOrder, orderItems } = props;

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const history = useHistory();
	const { handleSubmit } = useForm<CloseTableProps>({
		defaultValues: {},
	});

	const [
		closeTableStatus,
		{ data: closeTable, loading: closeTableLoading },
	] = useCloseTableStatusMutation({
		onError: error => {
			console.log('Suberror', error);
			setOpenSnackBar(true);
			setSnackBarMsg('Failed to close table');
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg('Closed table successfully');
			history.push({
				pathname: `/menu/outlet-app/${state?.outletID}/table/grid`,
				state: { success: true, msgMode: 'create' },
			});

			dispatch({ type: 'closeTableDialog', payload: false });
		},
	});

	const getOrderID = getOrder?.filter(
		x => x?.table?.orderInfo?.totalAmount === 0,
	);
	const billInputArr = () => {
		let array: any[] = [];
		getOrderID?.map(x => {
			array.push({
				orderID: x?.ID,
				outletID: x?.outletID,
				orderItemIDs: x?.orderItem?.map(x => x?.ID),
			});
		});
		return array;
	};

	const billInput = billInputArr();
	// function
	const onCloseSubmit = async (data: any) => {
		closeTableStatus({
			variables: {
				tableID: state?.tableID,
				billInput: billInput || [],
			},
			refetchQueries: [
				{
					query: GetTableDocument,
					variables: { outletID: state?.outletID },
				},
			],
		});
	};

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={state?.closeTableDialog}
				onClose={() => dispatch({ type: 'closeTableDialog', payload: false })}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title highlight-text">Close Table</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="xsTitle infoline">
										{table?.prefix}
										{table?.tableNumber}
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="content-container mdDesc">
								Close table {table?.prefix}
								{table?.tableNumber}?
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										if (orderItems?.length > 0)
											history.push({
												pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/close-table`,
												state: {
													close: 'table',
													table: table,
												},
											});
										dispatch({ type: 'closeTableDialog', payload: false });
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => handleSubmit(onCloseSubmit)(),
									variant: 'contained',
									form: 'close-form',
									color: closeTableLoading ? 'inherit' : 'primary',
									disabled: closeTableLoading ? true : false,
									type: 'submit',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
