import MultipleItemsCarousel from '@ifca-root/react-component/src/components/Carousel/MultipleItemsCarousel';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import {
	Checkbox,
	FormControl,
	Grid,
	InputAdornment,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import { amtStr } from 'helpers/numFormatter';
import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { AddBox } from '@material-ui/icons';
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/VoiceTextField';
import './addEditOrder.scss';

const CheckedRadioCondition = (
	optionIndex: number,
	option: any,
	optionItemID: string,
	watch: any,
) => {
	let optionItemIDandPrice: any = watch(
		`option[${optionIndex}].${option?.ID}[0]`,
	);
	if (optionItemIDandPrice && JSON.parse(optionItemIDandPrice)?.itemID) {
		return optionItemID === JSON.parse(optionItemIDandPrice)?.itemID;
	} else return false;
};

export const customLabel = (data: any, checked: boolean) => {
	return (
		<>
			{checked ? (
				<div className="xsTitle custom-label-checked">{data}</div>
			) : (
				<div className="xsTitle custom-label">{data}</div>
			)}
		</>
	);
};

export const CustomRadio = (
	option: any,
	optionIndex: number,
	menuItemOptionItem: any,
	control: any,
	optionErr: any,
	register: any,
	watch: any,
) => {
	return (
		<>
			<span>
				{menuItemOptionItem?.map(optionItem => (
					<Controller
						as={
							<RadioGroup>
								<Radio
									checked={CheckedRadioCondition(
										optionIndex,
										option,
										optionItem.optionItemID,
										watch,
									)}
									value={JSON.stringify({
										itemID: optionItem?.optionItemID,
										price: optionItem?.price,
									})}
									name={optionItem?.optionItem?.name}
									checkedIcon={customLabel(optionItem?.optionItem?.name, true)}
									icon={customLabel(optionItem?.optionItem?.name, false)}
									ref={register}
								/>
							</RadioGroup>
						}
						name={`option[${optionIndex}].${option?.ID}[0]`}
						control={control}
						ref={register}
					/>
				))}
			</span>

			<div>
				<TextField
					InputProps={{ disableUnderline: true }}
					disabled
					style={{ marginTop: '-35px' }}
					helperText={
						optionErr &&
						watch(`option[${optionIndex}].${option?.ID}[0]`) === undefined
							? 'Must select at least 1 option'
							: null
					}
					error={optionErr}
				/>
			</div>
		</>
	);
};

export const CustomCheckbox = (
	option: any,
	optionIndex: number,
	menuItemOptionItem: any,
	optionErr: any,
	register: any,
	setValue: any,
	unregister: any,
	watch: any,
) => {
	return (
		<>
			<div>
				{menuItemOptionItem?.map((optionItem: any, optionItemIndex: number) => (
					<FormControl component="fieldset">
						<Checkbox
							checkedIcon={customLabel(optionItem?.optionItem?.name, true)}
							icon={customLabel(optionItem?.optionItem?.name, false)}
							onChange={e => {
								register(
									`option[${optionIndex}].${option?.ID}[${e.target.value}]`,
								);
								if (e.target.checked) {
									setValue(
										`option[${optionIndex}].${option?.ID}[${e.target.value}]`,
										optionItem?.price,
									);
								} else {
									unregister(
										`option[${optionIndex}].${option?.ID}[${e.target.value}]`,
									);
								}
							}}
							color="primary"
							inputProps={{ 'aria-label': 'secondary checkbox' }}
							ref={register}
							value={optionItem?.optionItemID}
							name={`option[${optionIndex}].${option?.ID}[${optionItemIndex}]`}
						/>
					</FormControl>
				))}
			</div>
			<div>
				<TextField
					InputProps={{ disableUnderline: true }}
					disabled
					style={{ marginTop: '-35px' }}
					helperText={
						optionErr &&
						watch(`option[${optionIndex}].${option?.ID}`) === undefined
							? 'Must select at least 1 option'
							: null
					}
					error={optionErr}
				/>
			</div>
		</>
	);
};

export const CustomSetMenuRadio = (
	setMenuOptionItem: any,
	setMenuOption: any,
	setMenuOptionIndex: number,
	control: any,
	optionErr: any,
	register: any,
	watch: any,
) => {
	return (
		<>
			<span>
				{setMenuOptionItem?.map(setMenuOptionItem => (
					<Controller
						as={
							<RadioGroup>
								<Radio
									checked={CheckedRadioCondition(
										setMenuOptionIndex,
										setMenuOption,
										setMenuOptionItem.ID,
										watch,
									)}
									value={JSON.stringify({
										itemID: setMenuOptionItem?.ID,
										quantity: setMenuOptionItem?.quantity || 1,
										menuItemID: setMenuOptionItem?.menuItem?.ID,
									})}
									name={setMenuOptionItem?.menuItem?.name}
									checkedIcon={customLabel(
										setMenuOptionItem?.menuItem?.name,
										true,
									)}
									icon={customLabel(setMenuOptionItem?.menuItem?.name, false)}
									ref={register}
								/>
							</RadioGroup>
						}
						name={`option[${setMenuOptionIndex}].${setMenuOption?.ID}[0]`}
						control={control}
						ref={register}
					/>
				))}
			</span>
			<div>
				<TextField
					InputProps={{ disableUnderline: true }}
					disabled
					style={{ marginTop: '-35px' }}
					helperText={
						optionErr &&
						watch(`option[${setMenuOptionIndex}].${setMenuOption?.ID}[0]`) ===
							undefined
							? 'Must select at least 1 option'
							: null
					}
					error={optionErr}
				/>
			</div>
		</>
	);
};

export const CustomSetMenuCheckbox = (
	setMenuOptionItem: any,
	setMenuOption: any,
	setMenuOptionIndex: number,
	optionErr: any,
	register: any,
	setValue: any,
	unregister: any,
	watch: any,
) => {
	return (
		<>
			<div>
				{setMenuOptionItem?.map(
					(setMenuOptionItem: any, setMenuOptionItemIndex: number) => (
						<FormControl component="fieldset">
							<Checkbox
								checkedIcon={customLabel(
									setMenuOptionItem?.menuItem?.name,
									true,
								)}
								icon={customLabel(setMenuOptionItem?.menuItem?.name, false)}
								onChange={e => {
									register(
										`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
									);
									if (e.target.checked) {
										setValue(
											`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
											{
												quantity: setMenuOptionItem?.quantity || 1,
												menuItemID: setMenuOptionItem?.menuItem?.ID,
											},
										);
									} else {
										unregister(
											`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
										);
									}
								}}
								color="primary"
								inputProps={{ 'aria-label': 'secondary checkbox' }}
								ref={register}
								value={setMenuOptionItem?.ID}
								name={`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${setMenuOptionItemIndex}]`}
							/>
						</FormControl>
					),
				)}
			</div>
			<div>
				<TextField
					InputProps={{ disableUnderline: true }}
					disabled
					style={{ marginTop: '-35px' }}
					helperText={
						optionErr &&
						watch(`option[${setMenuOptionIndex}].${setMenuOption?.ID}`) ===
							undefined
							? 'Must select at least 1 option'
							: null
					}
					error={optionErr}
				/>
			</div>
		</>
	);
};

export const MenuDialogAdd = (props: any) => {
	const {
		state,
		dispatch,
		currencyCode,
		attachmentData,
		control,
		register,
		errors,
		setValue,
		watch,
		clearErrors,
		unregister,
		handleSubmit,
		isDesktop,
		audioInput,
		setAudioInput,
		record,
		setRecord,
		addOrderItem,
		createOrderItemWithOptionsLoading,
	} = props;

	const clickButton = (mode: string) => {
		if (mode === 'add') {
			if (
				state?.quantity ===
				state?.currentMenu?.menuItem?.dailyAvailability?.outstandingQty
			) {
				dispatch({ type: 'quantity', payload: state?.quantity });
				setValue('quantity', state?.quantity);
			} else {
				dispatch({ type: 'quantity', payload: state?.quantity + 1 });
				setValue('quantity', state?.quantity + 1);
			}
		} else {
			if (state?.quantity === 1) {
				dispatch({ type: 'quantity', payload: 1 });
				setValue('quantity', 1);
			} else {
				dispatch({ type: 'quantity', payload: state?.quantity - 1 });
				setValue('quantity', state?.quantity - 1);
			}
		}
	};

	const getRoundedQty = qty1 => {
		let outstandingQuantity =
			state?.currentMenu?.menuItem?.dailyAvailability?.outstandingQty;
		let roundedQty =
			qty1 > outstandingQuantity ? outstandingQuantity : qty1 < 1 ? 1 : qty1;
		return roundedQty;
	};

	const checkMenuOptionError = () => {
		const boolArr = state?.currentMenu?.menuItem?.menuItemOptions?.map(
			menuItemOption => {
				const maxSelections = menuItemOption?.maxSelections;
				const currSelectedOption = watch(`option`)?.filter(
					selectedOption =>
						Object?.keys(selectedOption)[0] === menuItemOption?.optionID,
				)[0];

				if (maxSelections === 0) {
					return true;
				} else if (
					(maxSelections !== 1 && currSelectedOption === undefined) ||
					(maxSelections === 1 && currSelectedOption === undefined)
				) {
					return false;
				} else if (
					maxSelections === 1 &&
					typeof currSelectedOption[menuItemOption?.optionID][0] === 'string'
				) {
					return true;
				} else if (
					maxSelections > 1 &&
					Object.keys(currSelectedOption[menuItemOption?.optionID]).length <=
						maxSelections
				) {
					return true;
				} else {
					return false;
				}
			},
		);

		return boolArr;
	};

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={state?.menuDialog}
				onClose={() => {
					dispatch({ type: 'menuDialog', payload: false });
					dispatch({ type: 'quantity', payload: 1 });
					dispatch({ type: 'takeAwayStatus', payload: false });
					dispatch({ type: 'optionError', payload: false });
					dispatch({ type: 'currentMenu', payload: null });
					dispatch({ type: 'isSubmitting', payload: false });
					setAudioInput('');
				}}
				sections={{
					header: {
						dynamic: (
							// <div className="">
							<>
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">
											{`${state?.selectedOrder?.table?.prefix}${state?.selectedOrder?.table?.tableNumber} ${state?.selectedOrder?.docNo}`}
										</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div
										className="title flex-space infoline"
										style={{
											overflowWrap: 'break-word',
											whiteSpace: 'normal',
										}}
									>
										{state?.currentMenu?.name}
									</div>
									<div className="title rightText infoline">
										{`${currencyCode}${amtStr(state?.menuItemPatronPrice)}`}
									</div>
								</div>
							</>
							// </div>
						),
					},
					body: () => (
						<>
							<MultipleItemsCarousel>
								{attachmentData?.attachmentListing?.map((el, index) => (
									<div className="img-container">
										<img src={el.fileURL} alt="" />
									</div>
								))}
							</MultipleItemsCarousel>

							<div className="orig-padding" style={{ marginTop: '10px' }}>
								<span className="mdDesc text-noflow">
									{state?.currentMenu?.description}
								</span>

								<hr className="line-description" />

								<div>
									{/* OPEN ITEM */}
									{state?.currentMenu?.isOpenItem && (
										<div className="" style={{ marginBottom: '10px' }}>
											<span>
												<Controller
													as={TextField}
													fullWidth
													label="Menu Item"
													variant="standard"
													size="small"
													autoComplete="off"
													multiline={true}
													name="openItem"
													control={control}
													ref={register}
													required
													helperText={errors?.openItem?.message}
													error={errors?.openItem ? true : false}
												/>
											</span>
										</div>
									)}

									{/* OPEN PRICE */}
									{(state?.currentMenu?.isOpenItem ||
										state?.currentMenu?.isOpenPrice) && (
										<div className="" style={{ marginBottom: '20px' }}>
											<span>
												<Controller
													as={
														<NumberFormat
															customInput={TextField}
															fullWidth
															label="Price"
															autoComplete="off"
															isNumericString
															decimalScale={2}
															fixedDecimalScale
															style={{
																marginBottom: '0px',
																marginTop: '0px',
															}}
															margin="dense"
															required
															allowNegative={false}
														/>
													}
													name="openPrice"
													control={control}
													ref={register}
													helperText={errors?.openPrice?.message}
													error={errors?.openPrice ? true : false}
												/>
											</span>
										</div>
									)}

									<Grid container style={{ marginBottom: '15px' }}>
										{!state?.currentMenu?.isByWeight ||
										(!state?.currentMenu?.isByWeight &&
											state?.currentMenu?.isOpenItem) ? (
											// <QUANTITY>
											<Grid item xs={7}>
												<span>
													<IndeterminateCheckBoxIcon
														className="order-minus"
														onClick={() => clickButton('reduce')}
													/>

													<Controller
														render={({ onChange, onBlur, value }) => (
															<NumberFormat
																customInput={TextField}
																autoComplete="off"
																isNumericString
																variant="standard"
																thousandSeparator
																fullWidth={false}
																margin="dense"
																className="quantity-input"
																InputProps={{
																	disableUnderline: true,
																}}
																onValueChange={(value: any) => {
																	dispatch({
																		type: 'quantity',
																		payload: getRoundedQty(value?.floatValue),
																	});
																	setValue(
																		'quantity',
																		getRoundedQty(value?.floatValue),
																	);
																}}
																style={{
																	width: isDesktop ? '25%' : '45%',
																	borderRadius: '8px',
																	border: 'solid',
																	borderColor: 'orange',
																}}
																defaultValue={state?.quantity}
																value={watch('quantity')}
															/>
														)}
														name="quantity"
														control={control}
														ref={register}
													/>

													<AddBox
														className="order-add"
														onClick={() => clickButton('add')}
													/>
												</span>
											</Grid>
										) : (
											// <WEIGHT>
											<>
												<Grid
													item
													xs={7}
													className="mdDesc"
													style={{ alignSelf: 'center' }}
												>
													<span style={{ float: 'left', marginTop: '12px' }}>
														{`Weight `}
													</span>

													<Controller
														as={
															<NumberFormat
																customInput={TextField}
																autoComplete="off"
																isNumericString
																fullWidth={false}
																decimalScale={1}
																fixedDecimalScale
																margin="dense"
																defaultValue={1}
																className="weight-input"
																allowNegative
																isAllowed={value => {
																	const { floatValue } = value;
																	return floatValue % 0.5 === 0;
																}}
															/>
														}
														name="getWeight"
														size="small"
														fullWidth={false}
														style={{ marginLeft: '8px', width: '50%' }}
														variant="outlined"
														control={control}
														ref={register}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	{` ${state?.weightName?.code}`}
																</InputAdornment>
															),
														}}
													/>
												</Grid>
											</>
										)}

										<Grid
											item
											xs={5}
											style={{
												float: 'right',
												alignSelf: 'center',
												textAlign: 'end',
											}}
										>
											<Checkbox
												value={state?.currentMenu?.ID}
												onChange={e =>
													e.target.checked
														? dispatch({
																type: 'takeAwayStatus',
																payload: true,
														  })
														: dispatch({
																type: 'takeAwayStatus',
																payload: false,
														  })
												}
												color="primary"
												style={{ padding: '5px' }}
												inputProps={{
													'aria-label': 'secondary checkbox',
												}}
												ref={register}
											/>

											<span className="mdDesc">Takeaway</span>
										</Grid>
									</Grid>

									{state?.currentMenu?.menuItem?.menuItemOptions?.map(
										(v, i) => (
											<>
												<div
													className="option-radio"
													style={{ paddingTop: '10px' }}
												>
													<div className="mdDesc" key={v?.menuItemID}>
														{v?.maxSelections === 1
															? `${v?.option?.name}* (Require 1 option)`
															: v?.maxSelections > 1
															? `${v?.option?.name}* (Choose at most ${v?.maxSelections} option)`
															: `${v?.option?.name}`}
													</div>

													{v?.maxSelections === 1
														? CustomRadio(
																v?.option,
																i,
																v?.menuItemOptionItem,
																control,
																state?.optionError,
																register,
																watch,
														  )
														: CustomCheckbox(
																v?.option,
																i,
																v?.menuItemOptionItem,
																state?.optionError,
																register,
																setValue,
																unregister,
																watch,
														  )}
												</div>
											</>
										),
									)}

									<div className="mdDesc" style={{ padding: '10px 0px' }}>
										{'Special Request'}
									</div>

									<VoiceTextField
										name="specialRequest"
										mounted={true}
										label="We will try our best to fulfill your request..."
										required
										value={audioInput}
										record={record}
										setRecord={setRecord}
										setValue={setValue}
										audioInput={audioInput}
										setAudioInput={setAudioInput}
										openDialog={state?.menuDialog}
										customOnchange
										register={register}
										control={control}
										clearErrors={clearErrors}
										handleCustomOnChange={e => {
											setAudioInput(e.target.value);
										}}
									/>
								</div>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										dispatch({ type: 'menuDialog', payload: false });
										dispatch({ type: 'quantity', payload: 1 });
										dispatch({ type: 'takeAwayStatus', payload: false });
										dispatch({ type: 'optionError', payload: false });
										dispatch({ type: 'currentMenu', payload: null });
										setAudioInput('');
									},
									variant: 'contained',
									color: 'secondary',
								},
							},
							{
								displayText: 'Add',
								props: {
									onClick: e => {
										dispatch({ type: 'isSubmitting', payload: false });
										if (state?.quantity !== 0) {
											if (
												state?.currentMenu?.menuItem?.menuItemOptions?.length >
												0
											) {
												if (!checkMenuOptionError()?.includes(false)) {
													!state?.isSubmitting && handleSubmit(addOrderItem)();
												} else {
													dispatch({ type: 'optionError', payload: true });
												}
											} else {
												!state?.isSubmitting && handleSubmit(addOrderItem)();
											}
										}
										e.stopPropagation();
										setAudioInput('');
									},
									color: createOrderItemWithOptionsLoading
										? 'inherit'
										: 'primary',
									disabled: createOrderItemWithOptionsLoading ? true : false,
									variant: 'contained',
									type: 'submit',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const SetMenuDialogAdd = (props: any) => {
	const {
		state,
		dispatch,
		currencyCode,
		attachmentData,
		setValue,
		watch,
		control,
		register,
		unregister,
		handleSubmit,
		isDesktop,
		addSetMenuOrderItem,
		createOrderItemWithSetMenuOptionsLoading,
	} = props;

	const clickButton = (mode: string) => {
		if (mode === 'add') {
			if (
				state?.quantity ===
				state?.currentMenu?.menuItem?.dailyAvailability?.outstandingQty
			) {
				dispatch({ type: 'quantity', payload: state?.quantity });
				setValue('quantity', state?.quantity);
			} else {
				dispatch({ type: 'quantity', payload: state?.quantity + 1 });
				setValue('quantity', state?.quantity + 1);
			}
		} else {
			if (state?.quantity === 1) {
				dispatch({ type: 'quantity', payload: 1 });
				setValue('quantity', 1);
			} else {
				dispatch({ type: 'quantity', payload: state?.quantity - 1 });
				setValue('quantity', state?.quantity - 1);
			}
		}
	};

	const getRoundedQty = qty1 => {
		let outstandingQuantity =
			state?.currentMenu?.menuItem?.dailyAvailability?.outstandingQty;
		let roundedQty =
			qty1 > outstandingQuantity ? outstandingQuantity : qty1 < 1 ? 1 : qty1;
		return roundedQty;
	};

	const checkSetMenuOptionError = () => {
		const boolArr = state?.currentMenu?.menuItem?.setMenuOption?.map(
			setMenuOption => {
				const maxSelections = setMenuOption?.maxSelections;
				const currSelectedOption = watch(`option`).filter(
					selectedOption =>
						Object.keys(selectedOption)[0] === setMenuOption?.ID,
				)[0];

				if (maxSelections === 0) {
					return true;
				} else if (
					(maxSelections !== 1 && currSelectedOption === undefined) ||
					(maxSelections === 1 &&
						currSelectedOption[setMenuOption?.ID][0] === undefined)
				) {
					return false;
				} else if (
					maxSelections === 1 &&
					typeof currSelectedOption[setMenuOption?.ID][0] === 'string'
				) {
					return true;
				} else if (
					maxSelections > 1 &&
					Object.keys(currSelectedOption[setMenuOption?.ID]).length <
						maxSelections
				) {
					return true;
				} else {
					return false;
				}
			},
		);

		return boolArr;
	};

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={state?.setMenuDialog}
				onClose={() => {
					dispatch({ type: 'setMenuDialog', payload: false });
					dispatch({ type: 'quantity', payload: 1 });
					dispatch({ type: 'takeAwayStatus', payload: false });
					dispatch({ type: 'optionError', payload: false });
					dispatch({ type: 'currentMenu', payload: null });
					dispatch({ type: 'isSubmitting', payload: false });
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">
											{`${state?.selectedOrder?.table?.prefix}${state?.selectedOrder?.table?.tableNumber} ${state?.selectedOrder?.docNo}`}
										</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div
										className="title flex-space infoline"
										style={{
											overflowWrap: 'break-word',
											whiteSpace: 'normal',
										}}
									>
										{state?.currentMenu?.name}
									</div>
									<div className="title rightText infoline">
										{`${currencyCode}${amtStr(state?.menuItemPatronPrice)}`}
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<MultipleItemsCarousel>
								{attachmentData?.attachmentListing?.map((el, index) => (
									<div className="img-container">
										<img src={el.fileURL} alt="" />
									</div>
								))}
							</MultipleItemsCarousel>

							<div className="orig-padding" style={{ marginTop: '10px' }}>
								<span className="mdDesc text-noflow">
									{state?.currentMenu?.description}
								</span>

								<hr className="line-description" />

								<div>
									{(!state?.current?.isByWeight ||
										(!state?.current?.isByWeight &&
											state?.current?.isOpenItem)) && (
										<span>
											<IndeterminateCheckBoxIcon
												className="order-minus"
												onClick={() => clickButton('reduce')}
											/>

											<Controller
												render={({ onChange, onBlur, value }) => (
													<NumberFormat
														customInput={TextField}
														autoComplete="off"
														isNumericString
														thousandSeparator
														fullWidth={false}
														margin="dense"
														className="quantity-input"
														variant="standard"
														InputProps={{
															disableUnderline: true,
														}}
														onValueChange={(value: any) => {
															dispatch({
																type: 'quantity',
																payload: getRoundedQty(value?.floatValue),
															});
															setValue(
																'quantity',
																getRoundedQty(value?.floatValue),
															);
														}}
														style={{
															width: isDesktop ? '25%' : '45%',
															borderRadius: '8px',
															border: 'solid',
															borderColor: 'orange',
														}}
														defaultValue={state?.quantity}
														value={watch('quantity')}
													/>
												)}
												name="quantity"
												control={control}
												ref={register}
											/>

											<AddBox
												className="order-add"
												onClick={() => clickButton('add')}
											/>
										</span>
									)}

									<span
										style={{
											float: 'right',
											position: 'relative',
											paddingTop: '10px',
										}}
									>
										<Checkbox
											value={state?.currentMenu?.ID}
											onChange={e =>
												e.target.checked
													? dispatch({
															type: 'takeAwayStatus',
															payload: true,
													  })
													: dispatch({
															type: 'takeAwayStatus',
															payload: false,
													  })
											}
											color="primary"
											style={{ padding: '5px' }}
											inputProps={{
												'aria-label': 'secondary checkbox',
											}}
											ref={register}
										/>

										<span className="mdDesc">Takeaway</span>
									</span>

									<div style={{ padding: '10px 0px' }}>
										<div style={{ paddingBottom: '5px' }} className="desc">
											{`Included in ${state?.currentMenu?.name}`}
										</div>
										{state?.currentMenu?.menuItem?.setMenuOption
											?.filter(option => option?.maxSelections === 0)[0]
											?.setMenuOptionItem?.map((optionItem, i) => (
												<div
													style={{
														paddingBottom: '5px',
														overflowWrap: 'break-word',
														whiteSpace: 'normal',
													}}
													className="xsTitle"
												>
													{`${optionItem?.menuItem?.name} x${optionItem?.quantity}`}
												</div>
											))}
									</div>

									{state?.currentMenu?.menuItem?.setMenuOption
										?.filter(
											setMenuOption => setMenuOption?.maxSelections !== 0,
										)
										?.map((setMenuOption, i) => (
											<div className="option-radio">
												<div className="mdDesc" key={setMenuOption?.ID}>
													{setMenuOption?.maxSelections === 1
														? `${setMenuOption?.name}* (Require 1 option)`
														: setMenuOption?.maxSelections > 1
														? `${setMenuOption?.name}* (Choose at most ${setMenuOption?.maxSelections} option)`
														: `${setMenuOption?.name}`}
												</div>
												{setMenuOption?.maxSelections === 1
													? CustomSetMenuRadio(
															setMenuOption?.setMenuOptionItem,
															setMenuOption,
															i + 1,
															control,
															state?.optionErr,
															register,
															watch,
													  )
													: setMenuOption?.maxSelections > 1
													? CustomSetMenuCheckbox(
															setMenuOption?.setMenuOptionItem,
															setMenuOption,
															i + 1,
															state?.optionErr,
															register,
															setValue,
															unregister,
															watch,
													  )
													: null}
											</div>
										))}
								</div>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										dispatch({ type: 'setMenuDialog', payload: false });
										dispatch({ type: 'quantity', payload: 1 });
										dispatch({ type: 'takeAwayStatus', payload: false });
										dispatch({ type: 'optionError', payload: false });
										dispatch({ type: 'currentMenu', payload: null });
									},
									variant: 'contained',
									color: 'secondary',
								},
							},
							{
								displayText: 'Add',
								props: {
									onClick: e => {
										dispatch({ type: 'isSubmitting', payload: true });
										if (state?.quantity !== 0) {
											if (
												state?.currentMenu?.menuItem?.setMenuOption?.length > 0
											) {
												if (!checkSetMenuOptionError()?.includes(false)) {
													!state?.isSubmitting &&
														handleSubmit(addSetMenuOrderItem)();
												} else {
													dispatch({ type: 'optionError', payload: true });
												}
											} else {
												!state?.isSubmitting &&
													handleSubmit(addSetMenuOrderItem)();
											}
										}
										e.stopPropagation();
									},
									color: createOrderItemWithSetMenuOptionsLoading
										? 'inherit'
										: 'primary',
									variant: 'contained',
									type: 'submit',
									disabled: createOrderItemWithSetMenuOptionsLoading
										? true
										: false,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
