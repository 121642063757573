import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';

export const OrderItemDiscountDialog = (props: any) => {
	const {
		state,
		dispatch,
		register,
		control,
		handleSubmit,
		onSubmit,
		getFilteredDiscount,
		createdOrderItemDiscountLoading,
	} = props;

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={state?.OIDDialog}
				onClose={() => dispatch({ type: 'OIDDialog', payload: false })}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Item Discount</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="xsTitle infoline">
										{state?.selectedOrderItem?.menuItem?.name}
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div
								className="content-container "
								style={{ overflow: 'hidden' }}
							>
								<Controller
									as={
										<TextField name="discountCode" ref={register}>
											{getFilteredDiscount?.map(x => (
												<MenuItem key={x.ID} value={x.ID}>
													{x.code}
												</MenuItem>
											))}
										</TextField>
									}
									name="discountCode"
									margin="normal"
									label="Discount Code.*"
									fullWidth
									autoComplete="off"
									multiline={true}
									select
									control={control}
									ref={register}
									style={{ paddingLeft: '5px' }}
								/>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () =>
										dispatch({ type: 'OIDDialog', payload: false }),
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => handleSubmit(onSubmit)(),
									variant: 'contained',
									form: 'submitOID-form',
									color: createdOrderItemDiscountLoading
										? 'inherit'
										: 'primary',
									disabled: createdOrderItemDiscountLoading ? true : false,
									type: 'submit',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const OrderDiscountDialog = (props: any) => {
	const {
		state,
		dispatch,
		register,
		control,
		handleSubmit,
		getFilteredDiscount,
		onSubmit,
		createdOrderDiscountLoading,
	} = props;

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={state?.ODDialog}
				onClose={() => dispatch({ type: 'ODDialog', payload: false })}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Order Discount</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="xsTitle infoline">
										{state?.selectedOrder?.docNo}
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="content-container">
								<Controller
									as={
										<TextField name="discountCode" ref={register}>
											{getFilteredDiscount?.map(x => (
												<MenuItem key={x.ID} value={x.ID}>
													{x.code}
												</MenuItem>
											))}
										</TextField>
									}
									name="discountCode"
									margin="normal"
									label="Discount Code.*"
									fullWidth
									autoComplete="off"
									multiline={true}
									select
									control={control}
									ref={register}
								/>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => dispatch({ type: 'ODDialog', payload: false }),
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => handleSubmit(onSubmit)(),
									variant: 'contained',
									form: 'submitOD-form',
									color: createdOrderDiscountLoading ? 'inherit' : 'primary',
									disabled: createdOrderDiscountLoading ? true : false,
									type: 'submit',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const VoidDialog = (props: any) => {
	const {
		state,
		dispatch,
		control,
		register,
		errors,
		watch,
		handleSubmit,
		getReason,
		onSubmitVoid,
		deleteOrderItemDiscountLoading,
		deleteOrderDiscountLoading,
	} = props;

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={state?.voidDialog}
				onClose={() => dispatch({ type: 'voidDialog', payload: false })}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">{`Void ${
											state?.selectedOrderItem?.ID
												? 'Item Discount'
												: 'Discount'
										}`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="mdDesc">
								<Grid container className="mdDesc">
									<Grid item xs={12} style={{ marginBlockStart: '10px' }}>
										<Controller
											as={
												<TextField select size="small" label="Reason">
													{getReason?.map((x, index) => (
														<MenuItem key={index} value={x?.name}>
															{x?.name}
														</MenuItem>
													))}
												</TextField>
											}
											name="reasonCode"
											autoComplete="off"
											control={control}
											ref={register}
											multiline
											fullWidth
											required
											margin="normal"
											helperText={errors?.reasonCode?.message}
											error={errors?.reasonCode ? true : false}
										></Controller>
									</Grid>
									<Grid item xs={12}>
										<Controller
											as={<TextField />}
											name="remark"
											label="Remark"
											autoComplete="off"
											control={control}
											ref={register}
											multiline
											required
											fullWidth
											margin="normal"
											helperText={errors?.remark?.message}
											error={errors?.remark ? true : false}
										></Controller>
									</Grid>
								</Grid>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										dispatch({ type: 'voidDialog', payload: false });
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										handleSubmit(onSubmitVoid)();
									},
									variant: 'contained',
									type: 'submit',
									color:
										watch('reasonCode') === undefined ||
										watch('reasonCode') === null ||
										watch('remark') === undefined ||
										watch('remark') === null ||
										watch('remark') === '' ||
										deleteOrderItemDiscountLoading ||
										deleteOrderDiscountLoading
											? 'inherit'
											: 'primary',
									disabled:
										watch('reasonCode') === undefined ||
										watch('reasonCode') === null ||
										watch('remark') === undefined ||
										watch('remark') === null ||
										watch('remark') === '' ||
										deleteOrderItemDiscountLoading ||
										deleteOrderDiscountLoading,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
