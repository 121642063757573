import React from 'react';
import MultipleItemsCarousel from '@ifca-root/react-component/src/components/Carousel/MultipleItemsCarousel';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import {
	Checkbox,
	FormControl,
	Grid,
	InputAdornment,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import { amtStr } from 'helpers/numFormatter';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { AddBox } from '@material-ui/icons';
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/VoiceTextField';

const CustomLabel = (data: any, checked: boolean) => {
	return (
		<>
			{checked ? (
				<div className="xsTitle custom-label-checked">{data}</div>
			) : (
				<div className="xsTitle custom-label">{data}</div>
			)}
		</>
	);
};

const CheckedRadioCondition = (
	watch: any,
	optionIndex: number,
	option: any,
	optionItemID: string,
) => {
	let optionItemIDandPrice: any = watch(
		`option[${optionIndex}].${option?.ID}[${0}]`,
	);
	if (optionItemIDandPrice && JSON.parse(optionItemIDandPrice)?.itemID) {
		return optionItemID === JSON.parse(optionItemIDandPrice)?.itemID;
	} else return false;
};

const CheckedRadioCondiWithDefaultValue = (
	currCheck,
	watch,
	currData,
	option,
	optionItem,
	fieldName,
) => {
	if (watch(fieldName)) {
		return CheckedRadioCondition(
			watch,
			currCheck.optionIndex,
			currCheck.option,
			currCheck.optionItem.optionItemID,
		);
	} else {
		return (
			currData?.orderItemOption
				?.filter(id => id?.optionID === option?.ID)[0]
				?.orderItemOptionItem?.filter(
					itemid => itemid?.optionItemID === optionItem?.optionItemID,
				).length > 0
		);
	}
};

export const CustomRadio = (
	data: any,
	option: any,
	optionIndex: number,
	menuItemOptionItem: any,
	control: any,
	optionErr: any,
	register: any,
	selectedMenu: any,
	watch: any,
) => {
	return (
		<>
			<span>
				{menuItemOptionItem?.map(optionItem => (
					<Controller
						as={
							<RadioGroup>
								<Radio
									checked={CheckedRadioCondiWithDefaultValue(
										{
											optionIndex: optionIndex,
											option: option,
											optionItem: optionItem,
										},
										watch,
										selectedMenu,
										option,
										optionItem,
										`option[${optionIndex}].${option?.ID}[0]`,
									)}
									value={JSON.stringify({
										itemID: optionItem?.optionItemID,
										price: optionItem?.price,
									})}
									name={
										data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
											?.name
									}
									defaultChecked={
										selectedMenu?.orderItemOption
											?.filter(id => id?.optionID === option?.ID)[0]
											?.orderItemOptionItem?.filter(
												e => e?.optionItemID === optionItem?.optionItemID,
											).length > 0
											? true
											: false
									}
									checkedIcon={CustomLabel(
										data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
											?.name,
										true,
									)}
									icon={CustomLabel(
										data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
											?.name,
										false,
									)}
									ref={register}
								/>
							</RadioGroup>
						}
						name={`option[${optionIndex}].${option?.ID}[0]`}
						control={control}
						ref={register}
					/>
				))}
			</span>

			<div>
				<TextField
					InputProps={{ disableUnderline: true }}
					disabled
					style={{ marginTop: '-35px' }}
					helperText={
						optionErr &&
						watch(`option[${optionIndex}].${option?.ID}[0]`) === undefined
							? 'Must select at least 1 option'
							: null
					}
					error={optionErr}
				/>
			</div>
		</>
	);
};

export const CustomCheckbox = (
	data: any,
	option: any,
	optionIndex: number,
	menuItemOptionItem: any,
	optionErr: any,
	register: any,
	selectedMenu: any,
	setValue: any,
	unregister: any,
	watch: any,
) => {
	return (
		<>
			<div>
				{menuItemOptionItem?.map((optionItem, optionItemIndex) => (
					<FormControl component="fieldset">
						<Checkbox
							checkedIcon={CustomLabel(
								data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
									?.name,
								true,
							)}
							icon={CustomLabel(
								data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
									?.name,
								false,
							)}
							onChange={e => {
								register(
									`option[${optionIndex}].${option?.ID}[${e.target.value}]`,
								);
								if (e.target.checked) {
									let a = setValue(
										`option[${optionIndex}].${option?.ID}[${e.target.value}]`,
										optionItem?.price,
									);
								} else if (!e.target.checked) {
									unregister(
										`option[${optionIndex}].${option?.ID}[${e.target.value}]`,
									);
								}
							}}
							defaultChecked={
								selectedMenu?.orderItemOption
									?.filter(id => id?.optionID === option?.ID)[0]
									?.orderItemOptionItem?.filter(
										e => e?.optionItemID === optionItem?.optionItemID,
									).length > 0
									? true
									: false
							}
							color="primary"
							inputProps={{ 'aria-label': 'secondary checkbox' }}
							ref={register}
							value={optionItem?.optionItemID}
							name={`option[${optionIndex}].${option?.ID}[${optionItemIndex}]`}
						/>
					</FormControl>
				))}
			</div>

			<div>
				<TextField
					InputProps={{ disableUnderline: true }}
					disabled
					style={{ marginTop: '-35px' }}
					helperText={
						optionErr &&
						watch(`option[${optionIndex}].${option?.ID}`) === undefined
							? 'Must select at least 1 option'
							: null
					}
					error={optionErr}
				/>
			</div>
		</>
	);
};

export const CustomSetMenuRadio = (
	setMenuOptionItem: any,
	setMenuOption: any,
	setMenuOptionIndex: number,
	control: any,
	register: any,
	watch: any,
) => {
	console.log('setMenuOptionItem >>>', setMenuOptionItem);
	return (
		<span>
			{setMenuOptionItem?.map(setMenuOptionItem => (
				<Controller
					as={
						<RadioGroup>
							<Radio
								checked={CheckedRadioCondition(
									watch,
									setMenuOptionIndex,
									setMenuOption,
									setMenuOptionItem.ID,
								)}
								value={JSON.stringify({
									itemID: setMenuOptionItem?.ID,
									quantity: setMenuOptionItem?.quantity || 1,
									menuItemID: setMenuOptionItem?.menuItem?.ID,
								})}
								name={setMenuOptionItem?.menuItem?.name}
								checkedIcon={CustomLabel(
									setMenuOptionItem?.menuItem?.name,
									true,
								)}
								icon={CustomLabel(setMenuOptionItem?.menuItem?.name, false)}
								ref={register}
							/>
						</RadioGroup>
					}
					name={`option[${setMenuOptionIndex}].${setMenuOption?.ID}[0]`}
					control={control}
					ref={register}
				/>
			))}
		</span>
	);
};

export const CustomSetMenuCheckbox = (
	setMenuOptionItem: any,
	setMenuOption: any,
	setMenuOptionIndex: number,
	register: any,
	selectedMenu: any,
	setValue: any,
	unregister: any,
) => {
	return (
		<div>
			{setMenuOptionItem?.map(
				(setMenuOptionItem: any, setMenuOptionItemIndex: number) => (
					<FormControl component="fieldset">
						<Checkbox
							checkedIcon={CustomLabel(setMenuOptionItem?.menuItem?.name, true)}
							icon={CustomLabel(setMenuOptionItem?.menuItem?.name, false)}
							onChange={e => {
								register(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
								);
								if (e.target.checked) {
									setValue(
										`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
										{
											quantity: setMenuOptionItem?.quantity || 1,
											menuItemID: setMenuOptionItem?.menuItem?.ID,
										},
									);
								} else {
									unregister(
										`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
									);
								}
							}}
							defaultChecked={
								selectedMenu?.orderItemSetMenuOption
									?.filter(
										orderItemSetMenuOption =>
											orderItemSetMenuOption?.setMenuOptionID ===
											setMenuOption?.ID,
									)[0]
									?.orderItemSetMenuOptionItem?.filter(
										orderItemSetMenuOptionItem =>
											orderItemSetMenuOptionItem?.setMenuOptionItemID ===
											setMenuOptionItem?.ID,
									).length > 0
									? true
									: false
							}
							color="primary"
							inputProps={{ 'aria-label': 'secondary checkbox' }}
							ref={register}
							value={setMenuOptionItem?.ID}
							name={`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${setMenuOptionItemIndex}]`}
						/>
					</FormControl>
				),
			)}
		</div>
	);
};

export const MenuDialogSummary = (props: any) => {
	const {
		state,
		dispatch,
		audioInput,
		setAudioInput,
		record,
		setRecord,
		currencyCode,
		control,
		register,
		errors,
		watch,
		unregister,
		clearErrors,
		handleSubmit,
		isDesktop,
		setValue,
		updateOrderItem,
		orderItemWithOptionsLoading,
	} = props;

	const changeOrderQuantity = mode => {
		if (mode === 'add') {
			if (
				state?.quantity >=
				state?.selectedMenu?.menuItem?.dailyAvailability?.outstandingQty +
					state?.selectedMenu?.quantity
			) {
				dispatch({ type: 'quantity', paylaod: state?.quantity });
				setValue('qty', state?.quantity);
			} else {
				dispatch({ type: 'quantity', paylaod: state?.quantity + 1 });
				setValue('qty', state?.quantity + 1);
			}
		} else {
			if (state?.quantity === 0) {
				dispatch({ type: 'quantity', paylaod: 0 });
				setValue('qty', state?.quantity);
			} else {
				dispatch({ type: 'quantity', paylaod: state?.quantity - 1 });
				setValue('qty', state?.quantity - 1);
			}
		}
	};

	const getRoundedQty = qty1 => {
		let outstandingQuantity =
			state?.selectedMenu?.menuItem?.dailyAvailability?.outstandingQty +
			state?.selectedMenu?.quantity;
		let roundedQty =
			qty1 > outstandingQuantity ? outstandingQuantity : qty1 < 1 ? 0 : qty1;
		return roundedQty;
	};

	const CheckMenuOptionError = () => {
		const boolArr = state?.selectedMenu?.menuItem?.menuItemOptions?.map(
			menuItemOption => {
				const maxSelections = menuItemOption?.maxSelections;
				const currSelectedOption = watch(`option`).filter(
					selectedOption =>
						Object.keys(selectedOption)[0] === menuItemOption?.optionID,
				)[0];

				if (
					(maxSelections !== 1 && currSelectedOption === undefined) ||
					(maxSelections === 1 &&
						currSelectedOption[menuItemOption?.optionID][0] === undefined)
				) {
					return false;
				} else if (
					maxSelections === 1 &&
					typeof currSelectedOption[menuItemOption?.optionID][0] === 'string'
				) {
					return true;
				} else if (maxSelections === 0) {
					return true;
				} else if (
					maxSelections > 1 &&
					Object.keys(currSelectedOption[menuItemOption?.optionID]).length <
						maxSelections
				) {
					return true;
				} else {
					return false;
				}
			},
		);

		return boolArr;
	};
	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={state?.menuDialog}
				onClose={() => {
					dispatch({ type: 'menuDialog', payload: false });
					dispatch({ type: 'quantity', payload: 1 });
					dispatch({ type: 'takeAwayStatus', payload: false });
					dispatch({ type: 'selectedMenu', payload: null });
					dispatch({ type: 'isSubmitting', payload: false });
					setAudioInput('');
				}}
				sections={{
					header: {
						dynamic: (
							<>
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">
											{`${state?.selectedOrder?.table?.prefix}${state?.selectedOrder?.table?.tableNumber} ${state?.selectedOrder?.docNo}`}
										</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="title flex-space infoline">
										{state?.selectedMenu?.menuItem?.name}
									</div>
									<div className="title rightText infoline">
										{`${currencyCode}${amtStr(
											state?.selectedMenu?.unitPrice * state?.quantity,
										)}`}
									</div>
								</div>
							</>
						),
					},
					body: () => (
						<>
							<MultipleItemsCarousel>
								<div className="img-container">
									<img
										src={state?.selectedMenu?.menuItem?.attachments?.fileURL}
										alt=""
									/>
								</div>
							</MultipleItemsCarousel>

							<div className="orig-padding" style={{ marginTop: '10px' }}>
								<span className="mdDesc text-noflow">
									{state?.selectedMenu?.menuItem?.description}
								</span>

								<hr className="line-description" />

								<div>
									{/* OPEN ITEM */}
									{state?.selectedMenu?.menuItem?.isOpenItem && (
										<div className="" style={{ marginBottom: '10px' }}>
											<span>
												<Controller
													as={TextField}
													fullWidth
													label="Menu Item"
													variant="standard"
													size="small"
													autoComplete="off"
													multiline={true}
													name="openItem"
													defaultValue={state?.selectedMenu?.openItemName}
													control={control}
													ref={register}
													helperText={errors?.openItem?.message}
													error={errors?.openItem ? true : false}
												/>
											</span>
										</div>
									)}

									{/* OPEN PRICE */}
									{(state?.selectedMenu?.menuItem?.isOpenItem ||
										state?.selectedMenu?.menuItem?.isOpenPrice) && (
										<div className="" style={{ marginBottom: '20px' }}>
											<span>
												<Controller
													as={
														<NumberFormat
															customInput={TextField}
															fullWidth
															label="Price"
															autoComplete="off"
															isNumericString
															decimalScale={2}
															fixedDecimalScale
															style={{
																marginBottom: '0px',
																marginTop: '0px',
															}}
															margin="dense"
															allowNegative={false}
															defaultValue={state?.selectedMenu?.unitPrice}
														/>
													}
													name="openPrice"
													control={control}
													ref={register}
													helperText={errors?.openPrice?.message}
													error={errors?.openPrice ? true : false}
												/>
											</span>
										</div>
									)}

									{/* WIEGHT */}
									<Grid container style={{ marginBottom: '15px' }}>
										{!state?.selectedMenu?.menuItem?.isByWeight ||
										(!state?.selectedMenu?.menuItem?.isByWeight &&
											state?.selectedMenu?.menuItem?.isOpenItem) ? (
											// <QUANTITY>
											<Grid item xs={7}>
												<span>
													<IndeterminateCheckBoxIcon
														className="order-minus"
														onClick={() => changeOrderQuantity('reduce')}
													/>
													<Controller
														render={({ onChange, onBlur, value }) => (
															<NumberFormat
																customInput={TextField}
																autoComplete="off"
																isNumericString
																thousandSeparator
																fullWidth={false}
																variant="standard"
																margin="dense"
																className="quantity-input"
																InputProps={{
																	disableUnderline: true,
																}}
																onValueChange={(value: any) => {
																	dispatch({
																		type: 'quantity',
																		payload: getRoundedQty(value?.floatValue),
																	});
																	setValue(
																		'qty',
																		getRoundedQty(value?.floatValue),
																	);
																}}
																style={{
																	width: isDesktop ? '25%' : '45%',
																	borderRadius: '8px',
																	border: 'solid',
																	borderColor: 'orange',
																}}
																defaultValue={state?.quantity}
																value={watch('qty')}
															/>
														)}
														name="qty"
														control={control}
														ref={register}
													/>
													<AddBox
														className="order-add"
														onClick={() => changeOrderQuantity('add')}
													/>
												</span>
											</Grid>
										) : (
											// <WEIGHT>
											<>
												<Grid
													item
													xs={7}
													className="mdDesc"
													style={{ alignSelf: 'center' }}
												>
													<span
														style={{ float: 'left', marginTop: '12px' }}
													>{`Weight `}</span>
													<Controller
														as={
															<NumberFormat
																customInput={TextField}
																autoComplete="off"
																isNumericString
																fullWidth={false}
																decimalScale={1}
																fixedDecimalScale
																margin="dense"
																defaultValue={1}
																className="weight-input"
																allowNegative
																isAllowed={value => {
																	const { floatValue } = value;
																	return floatValue % 0.5 === 0;
																}}
															/>
														}
														name="getWeight"
														size="small"
														fullWidth={false}
														// label="Weight"
														style={{ marginLeft: '8px', width: '50%' }}
														variant="outlined"
														control={control}
														ref={register}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	{` ${state?.weightName?.code}`}
																</InputAdornment>
															),
														}}
													/>
												</Grid>
											</>
										)}

										<Grid
											item
											xs={5}
											style={{
												float: 'right',
												alignSelf: 'center',
												textAlign: 'end',
											}}
										>
											<Checkbox
												value={state?.selectedMenu?.ID}
												defaultChecked={state?.selectedMenu?.isTakeaway}
												onChange={e =>
													e.target.checked
														? dispatch({
																type: 'takeAwayStatus',
																payload: true,
														  })
														: dispatch({
																type: 'takeAwayStatus',
																payload: false,
														  })
												}
												color="primary"
												style={{ padding: '5px' }}
												inputProps={{
													'aria-label': 'secondary checkbox',
												}}
												ref={register}
											/>

											<span className="mdDesc">Takeaway</span>
										</Grid>
									</Grid>

									{state?.selectedMenu?.menuItem?.menuItemOptions?.map(
										(ele, i) => (
											<>
												<div className="option-radio">
													<div className="mdDesc" key={ele?.ID}>
														{ele?.maxSelections === 1
															? `${ele?.option?.name}* (Require 1 option)`
															: ele?.maxSelections === 1
															? `${ele?.option?.name}* (Choose at most ${ele?.maxSelections} option)`
															: `${ele?.option?.name}`}
													</div>

													{ele?.maxSelections >= 1
														? CustomRadio(
																ele?.option.optionItem,
																ele?.option,
																i,
																ele?.menuItemOptionItem,
																control,
																state?.optionErr,
																register,
																state?.selectedMenu,
																watch,
														  )
														: CustomCheckbox(
																ele?.option.optionItem,
																ele?.option,
																i,
																ele?.menuItemOptionItem,
																state?.optionErr,
																register,
																state?.selectedMenu,
																setValue,
																unregister,
																watch,
														  )}
												</div>
											</>
										),
									)}

									<div className="mdDesc" style={{ padding: '10px 0px' }}>
										{'Special Request'}
									</div>

									<VoiceTextField
										name="specialRequest"
										mounted={true}
										label="We will try our best to fulfill your request..."
										fullWidth
										variant="outlined"
										size="small"
										autoComplete="off"
										multiline={true}
										required
										record={record}
										setRecord={setRecord}
										setValue={setValue}
										audioInput={audioInput}
										setAudioInput={setAudioInput}
										openDialog={state?.menuDialog}
										customOnchange
										register={register}
										control={control}
										clearErrors={clearErrors}
										handleCustomOnChange={e => {
											setAudioInput(e?.target?.value);
										}}
									/>
								</div>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										dispatch({ type: 'menuDialog', payload: false });
										dispatch({
											type: 'quantity',
											payload: state?.selectedMenu?.quantity,
										});
										dispatch({ type: 'takeAwayStatus', payload: false });
										dispatch({ type: 'optionError', payload: false });
										setAudioInput('');
									},
									variant: 'contained',
									color: 'secondary',
								},
							},
							{
								displayText: 'Save',
								props: {
									onClick: e => {
										dispatch({ type: 'isSubmitting', payload: true });
										if (
											state?.selectedMenu?.menuItem?.menuItemOptions?.length > 0
										) {
											if (!CheckMenuOptionError()?.includes(false)) {
												!state?.isSubmitting && handleSubmit(updateOrderItem)();
											} else {
												dispatch({ type: 'optionError', payload: true });
											}
										} else {
											!state?.isSubmitting && handleSubmit(updateOrderItem)();
										}
										e.stopPropagation();
										setAudioInput('');
									},
									color:
										orderItemWithOptionsLoading === true
											? 'inherit'
											: 'primary',
									disabled: orderItemWithOptionsLoading === true ? true : false,
									variant: 'contained',
									type: 'submit',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const SetMenuDialogSummary = (props: any) => {
	const {
		state,
		dispatch,
		currencyCode,
		isDesktop,
		setValue,
		watch,
		control,
		register,
		unregister,
		handleSubmit,
		checkSetMenuOptionError,
		updateSetMenuOrderItem,
		orderItemWithSetMenuOptionsLoading,
	} = props;

	const changeOrderQuantity = mode => {
		if (mode === 'add') {
			if (
				state?.quantity >=
				state?.selectedMenu?.menuItem?.dailyAvailability?.outstandingQty +
					state?.selectedMenu?.quantity
			) {
				dispatch({ type: 'quantity', payload: state?.quantity });
				setValue('quantity', state?.quantity);
			} else {
				dispatch({ type: 'quantity', payload: state?.quantity + 1 });
				setValue('quantity', state?.quantity + 1);
			}
		} else {
			if (state?.quantity === 0) {
				dispatch({ type: 'quantity', payload: 0 });
				setValue('quantity', state?.quantity);
			} else {
				dispatch({ type: 'quantity', payload: state?.quantity - 1 });
				setValue('quantity', state?.quantity - 1);
			}
		}
	};

	const getRoundedQty = qty1 => {
		let outstandingQuantity =
			state?.selectedMenu?.menuItem?.dailyAvailability?.outstandingQty +
			state?.selectedMenu?.quantity;
		let roundedQty =
			qty1 > outstandingQuantity ? outstandingQuantity : qty1 < 1 ? 0 : qty1;
		return roundedQty;
	};

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={state?.setMenuDialog}
				onClose={() => {
					dispatch({ type: 'setMenuDialog', payload: false });
					dispatch({
						type: 'quantity',
						payload: state?.selectedMenu?.quantity,
					});
					dispatch({ type: 'takeAwayStatus', payload: false });
					dispatch({ type: 'selectedMenu', payload: null });
					dispatch({ type: 'isSubmitting', payload: false });
				}}
				sections={{
					header: {
						dynamic: (
							<>
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">
											{`${state?.selectedOrder?.table?.prefix}${state?.selectedOrder?.table?.tableNumber} ${state?.selectedOrder?.docNo}`}
										</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="title flex-space infoline">
										{state?.selectedMenu?.menuItem?.name}
									</div>
									<div className="title rightText infoline">
										{`${currencyCode}${amtStr(
											state?.selectedMenu?.unitPrice * state?.quantity,
										)}`}
									</div>
								</div>
							</>
						),
					},
					body: () => (
						<>
							<MultipleItemsCarousel>
								<div className="img-container">
									<img
										src={state?.selectedMenu?.menuItem?.attachments?.fileURL}
										alt=""
									/>
								</div>
							</MultipleItemsCarousel>

							<div className="orig-padding" style={{ marginTop: '10px' }}>
								<span className="mdDesc text-noflow">
									{state?.selectedMenu?.menuItem?.description}
								</span>

								<hr className="line-description" />

								<div>
									{/* QUANTITY */}
									{(!state?.selectedMenu?.menuItem?.isByWeight ||
										(!state?.selectedMenu?.menuItem?.isByWeight &&
											state?.selectedMenu?.menuItem?.isOpenItem)) && (
										<span>
											<IndeterminateCheckBoxIcon
												className="order-minus"
												onClick={() => changeOrderQuantity('reduce')}
											/>

											<Controller
												render={({ onChange, onBlur, value }) => (
													<NumberFormat
														customInput={TextField}
														autoComplete="off"
														isNumericString
														thousandSeparator
														fullWidth={false}
														margin="dense"
														className="quantity-input"
														variant="standard"
														InputProps={{
															disableUnderline: true,
														}}
														onValueChange={(value: any) => {
															dispatch({
																type: 'quantity',
																payload: getRoundedQty(value?.floatValue),
															});
															setValue(
																'quantity',
																getRoundedQty(value?.floatValue),
															);
														}}
														style={{
															width: isDesktop ? '25%' : '45%',
															borderRadius: '8px',
															border: 'solid',
															borderColor: 'orange',
														}}
														defaultValue={state?.quantity}
														value={watch('quantity')}
													/>
												)}
												name="quantity"
												control={control}
												ref={register}
											/>
											<AddBox
												className="order-add"
												onClick={() => changeOrderQuantity('add')}
											/>
										</span>
									)}

									<span
										style={{
											float: 'right',
											position: 'relative',
											paddingTop: '10px',
										}}
									>
										<Checkbox
											value={state?.selectedMenu?.menuItem?.ID}
											onChange={e =>
												e.target.checked
													? dispatch({
															type: 'takeAwayStatus',
															payload: true,
													  })
													: dispatch({
															type: 'takeAwayStatus',
															payload: false,
													  })
											}
											color="primary"
											style={{ padding: '5px' }}
											inputProps={{
												'aria-label': 'secondary checkbox',
											}}
											ref={register}
										/>

										<span className="mdDesc">Takeaway</span>
									</span>

									<div style={{ padding: '10px 0px' }}>
										<div style={{ paddingBottom: '5px' }} className="desc">
											{`Included in ${state?.selectedMenu?.menuItem?.name}`}
										</div>

										{state?.selectedMenu?.menuItem?.setMenuOption
											?.filter(option => option?.maxSelections === 0)[0]
											?.setMenuOptionItem?.map((optionItem, i) => (
												<div
													style={{ paddingBottom: '5px' }}
													className="xsTitle"
												>
													{`${optionItem?.menuItem?.name} x${optionItem?.quantity}`}
												</div>
											))}

										{state?.selectedMenu?.menuItem?.setMenuOption
											?.filter(
												setMenuOption => setMenuOption?.maxSelections !== 0,
											)
											?.map((setMenuOption, i) => (
												<div className="option-radio">
													<div className="mdDesc" key={setMenuOption?.ID}>
														{setMenuOption?.maxSelections === 1
															? `${setMenuOption?.name}* (Require 1 option)`
															: setMenuOption?.maxSelections === 1
															? `${setMenuOption?.name}* (Choose at most ${setMenuOption?.maxSelections} option)`
															: `${setMenuOption?.name}`}
													</div>
													{setMenuOption?.maxSelections === 1
														? CustomSetMenuRadio(
																setMenuOption?.setMenuOptionItem,
																setMenuOption,
																i + 1,
																control,
																register,
																watch,
														  )
														: setMenuOption?.maxSelections > 1
														? CustomSetMenuCheckbox(
																setMenuOption?.setMenuOptionItem,
																setMenuOption,
																i + 1,
																//extra
																register,
																state?.selectedMenu,
																setValue,
																unregister,
														  )
														: null}
												</div>
											))}
									</div>
								</div>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										dispatch({ type: 'setMenuDialog', payload: false });
										dispatch({
											type: 'quantity',
											payload: state?.selectedMenu?.quantity,
										});
										dispatch({ type: 'takeAwayStatus', payload: false });
									},
									variant: 'contained',
									color: 'secondary',
								},
							},
							{
								displayText: 'Save',
								props: {
									onClick: e => {
										dispatch({ type: 'isSubmitting', payload: true });
										if (
											state?.selectedMenu?.mnenuItem?.setMenuOption?.length > 0
										) {
											if (!checkSetMenuOptionError()?.includes(false)) {
												!state?.isSubmitting &&
													handleSubmit(updateSetMenuOrderItem)();
											} else {
												dispatch({ type: 'optionErr', payload: true });
											}
										} else {
											!state?.isSubmitting &&
												handleSubmit(updateSetMenuOrderItem)();
										}
										e.stopPropagation();
									},
									variant: 'contained',
									type: 'submit',
									color:
										orderItemWithSetMenuOptionsLoading === true
											? 'inherit'
											: 'primary',
									disabled:
										orderItemWithSetMenuOptionsLoading === true ? true : false,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
