import React from 'react';
import { Payment } from './Payment';

const paymentRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/order/:orderID/payment`,
		},
		component: <Payment />,
	},
];

export default paymentRoutes;
