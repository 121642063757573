import { Grid, IconButton } from '@material-ui/core';
import React from 'react';
import AddEditItemIcon from 'assets/icons/AddItemColor.svg';
import DiscountIcon from 'assets/icons/DiscountColor.svg';
import PaymentIcon from 'assets/icons/PaymentColor.svg';
import TransferIcon from 'assets/icons/TransferColor.svg';
import NewOrderIcon from 'assets/icons/NewOrderColor.svg';
import { useHistory } from 'react-router';

export const ConfirmOrderFooter = (props: any) => {
	const { state, dispatch, style } = props;

	const history = useHistory();

	return (
		<div className="confirm-footer" style={{ ...style }}>
			<Grid container style={{ textAlign: 'center' }}>
				<Grid item xs={3} style={{ textAlign: 'center' }}>
					<span className="tooltip">
						<IconButton
							onClick={() => {
								history.push({
									pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/order/${state?.orderID}/add-edit-order`,
									state: {
										page: 'confirm-order',
									},
								});
							}}
						>
							<img src={AddEditItemIcon} />
						</IconButton>

						<span className="tooltiptext">{'Add Item'}</span>
					</span>
				</Grid>
				<Grid item xs={2} style={{ textAlign: 'center' }}>
					<span className="tooltip">
						<IconButton
							onClick={() => {
								history.push({
									pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/order/${state?.orderID}/discount`,
									state: state?.orders,
								});
							}}
						>
							<img src={DiscountIcon} />
						</IconButton>
						<span className="tooltiptext">{'Discount'}</span>
					</span>
				</Grid>
				<Grid item xs={2} style={{ textAlign: 'center' }}>
					<span className="tooltip">
						<IconButton
							onClick={() => {
								history.push({
									pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/order/${state?.orderID}/payment`,
									state: state?.orders,
								});
							}}
						>
							<img src={PaymentIcon} />
						</IconButton>
						<span className="tooltiptext">{'Payment'}</span>
					</span>
				</Grid>
				<Grid item xs={2} style={{ textAlign: 'center' }}>
					<span className="tooltip">
						<IconButton
							onClick={() => {
								dispatch({ type: 'transferItemDialog', payload: true });
							}}
						>
							<img src={TransferIcon} />
						</IconButton>
						<span className="tooltiptext">{'Transfer'}</span>
					</span>
				</Grid>
				<Grid item xs={3} style={{ textAlign: 'center' }}>
					<span className="tooltip">
						<IconButton
							onClick={() => {
								history.push({
									pathname: `/menu/outlet-app/${state?.outletID}/table/grid/${state?.tableID}/add`,
									state: {
										table: state?.selectedOrder?.table,
										page: 'confirm-order',
									},
								});
							}}
						>
							<img src={NewOrderIcon} />
						</IconButton>
						<span className="tooltiptext">{'New Order'}</span>
					</span>
				</Grid>
			</Grid>
		</div>
	);
};
