import { useEffect, useState } from 'react';
import {
	OrderItemDiscountStatus,
	useGetMenuXInhouseListQuery,
	useGetOfficerQuery,
	useGetStaffQuery,
} from 'generated/graphql';

interface OrderItemTax {
	taxAmount: number;
	taxSchemeType: string;
}

interface OrderItemDiscountTax {
	taxAmount: number;
}

interface OrderItemDiscount {
	orderItemID?: string;
	baseAmount: number;
	status: string;
	orderItemDiscountTax: OrderItemDiscountTax[];
}

interface OrderDiscountItemTax {
	taxAmount: number;
}

interface OrderDiscountItem {
	orderItemID?: string;
	baseAmount: number;
	orderDiscountItemTax?: OrderDiscountItemTax[];
}

interface OrderItemOptionItemTax {
	taxAmount: number;
}

interface OrderItemOptionItemDiscountTax {
	taxAmount: number;
}

interface OrderDiscountItemOptionTax {
	taxAmount: number;
}

interface OrderItemOptionItem {
	optionItemID: string;
	price: number;
	basePrice: number;
	unitPrice: number;
	orderItemOptionItemTax: OrderItemOptionItemTax[];
	discountBaseAmount: number;
	orderItemOptionItemDiscountTax: OrderItemOptionItemDiscountTax[];
	orderDiscountItemOptionTax: OrderDiscountItemOptionTax[];
}

interface OrderItemOption {
	optionID: string;
	orderItemOptionItem: OrderItemOptionItem[];
}

interface Item {
	ID: string;
	amount: number;
	orderItemTax: OrderItemTax[];
	orderItemDiscount?: OrderItemDiscount;
	orderDiscountItem?: OrderDiscountItem;
	orderItemOption?: OrderItemOption[];
}

export const NameByCustomerType = () => {
	const [variables, setVariables]: any = useState();

	const {
		loading: MenuXInhouseListLoading,
		data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
	} = useGetMenuXInhouseListQuery({
		fetchPolicy: 'network-only',
		variables: { hotelID: variables?.hotelID, outletID: variables?.outletID },
	});

	const {
		loading: getStaffLoading,
		data: { getStaff } = { getStaff: [] },
	} = useGetStaffQuery({
		fetchPolicy: 'network-only',
		variables: { isActive: true },
	});

	const {
		loading: getOfficerLoading,
		data: { getOfficer } = { getOfficer: [] },
	} = useGetOfficerQuery({
		fetchPolicy: 'network-only',
		variables: { isActive: true },
	});

	let name;
	const patronClass = variables?.order?.patron?.patronClass;

	if (patronClass === 'Visitor') {
		name = variables?.order?.patronName;
	} else if (patronClass === 'Hotel Guest') {
		const selectedGuest = getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
			x => x?.RegistrationID === variables?.order?.patronAccountID,
		)[0];
		name = selectedGuest
			? `${selectedGuest?.RoomNo ? `${selectedGuest?.RoomNo} |` : ''} ${
					selectedGuest?.GuestName
			  }`
			: 'Hotel Guest';
	} else if (patronClass === 'Staff') {
		name = getStaff?.filter(x => x?.ID === variables?.order?.patronAccountID)[0]
			?.name;
	} else if (patronClass === 'Officer') {
		name = getOfficer?.filter(
			x => x?.ID === variables?.order?.patronAccountID,
		)[0]?.name;
	} else if (patronClass === 'Member') {
		name = '';
	}

	return [setVariables, name];
};

export const FooterAmount = (orderItems, page, getRoundingPolicy) => {
	let subtotal,
		finalTax,
		total,
		discount,
		totalOptItm,
		discOptItm,
		optTaxAmount,
		discOptTaxAmount,
		roundingAmount;

	if (orderItems?.length > 0) {
		const itemArray = orderItems;

		const filterItemArray: {
			ID: string;
			amount: number;
			orderItemTax: OrderItemTax[];
			orderItemDiscount?: OrderItemDiscount;
			orderDiscountItem?: OrderDiscountItem;
			orderItemOption?: OrderItemOption[];
		}[] = itemArray.map(
			({
				ID,
				amount,
				orderItemTax,
				orderItemDiscount,
				orderDiscountItem,
				orderItemOption,
			}) => {
				const activeOID = orderItemDiscount?.filter(
					x => x?.status === OrderItemDiscountStatus.Active,
				)[0];

				return {
					ID,
					amount,
					orderItemTax: orderItemTax?.map(({ taxAmount, taxSchemeType }) => ({
						taxAmount,
						taxSchemeType,
					})),
					orderItemDiscount: {
						baseAmount: activeOID?.baseAmount ?? 0,
						status: activeOID?.status ?? '',
						orderItemID: ID,
						orderItemDiscountTax: activeOID
							? activeOID?.orderItemDiscountTax?.map(({ taxAmount }) => ({
									taxAmount,
							  }))
							: [{ taxAmount: 0 }],
					},
					orderDiscountItem: {
						baseAmount: orderDiscountItem?.baseAmount ?? 0,
						orderItemID: ID,
						orderDiscountItemTax: orderDiscountItem?.orderDiscountItemTax
							? orderDiscountItem?.orderDiscountItemTax?.map(
									({ taxAmount }) => ({
										taxAmount,
									}),
							  )
							: [{ taxAmount: 0 }],
					},
					orderItemOption: orderItemOption?.map(
						({ optionID, orderItemOptionItem }) => ({
							optionID,
							orderItemOptionItem: orderItemOptionItem.map(
								({
									optionItemID,
									price,
									basePrice,
									unitPrice,
									orderItemOptionItemTax,
									discountBaseAmount,
									orderItemOptionItemDiscountTax,
									orderDiscountItemOptionTax,
								}) => ({
									optionItemID,
									price,
									basePrice,
									unitPrice,
									orderItemOptionItemTax: orderItemOptionItemTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									discountBaseAmount,
									orderItemOptionItemDiscountTax: orderItemOptionItemDiscountTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									orderDiscountItemOptionTax: orderDiscountItemOptionTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
								}),
							),
						}),
					),
				};
			},
		);

		//subtotal amount
		const sumAmount = (items: Item[]): number => {
			let totalBaseAmount = 0;
			for (const item of items) {
				totalBaseAmount += item.amount;
			}
			return totalBaseAmount;
		};

		const totalAmount = sumAmount(filterItemArray);

		//subtotal tax amount

		const sumTaxAmount = (items: Item[]): number => {
			let totalTax = 0;
			for (const item of items) {
				for (const tax of item?.orderItemTax) {
					totalTax += tax?.taxAmount;
				}
			}
			return totalTax;
		};

		const totalTaxAmount = sumTaxAmount(filterItemArray);
		const roundedTotalTaxAmount = parseFloat(totalTaxAmount.toFixed(2));

		//subtotal order discount item base amount

		const sumODIBaseAmount = (items: Item[]): number => {
			let totalODIBaseAmt = 0;
			for (const item of items) {
				totalODIBaseAmt += item?.orderDiscountItem?.baseAmount;
			}
			return totalODIBaseAmt;
		};

		const totalODIBaseAmount = sumODIBaseAmount(filterItemArray);

		//subtotal order discount item tax amount

		const sumODITaxAmount = (items: Item[]): number => {
			let totalTax = 0;
			for (const item of items) {
				for (const tax of item?.orderDiscountItem?.orderDiscountItemTax)
					totalTax += tax?.taxAmount;
			}
			return totalTax;
		};

		const totalODITaxAmount = sumODITaxAmount(filterItemArray);
		const roundedTotalODITaxAmount = parseFloat(totalODITaxAmount.toFixed(2));

		//subtotal order item discount base amount

		const sumOIDBaseAmount = (items: Item[]): number => {
			let totalOIDBaseAmt = 0;
			for (const item of items) {
				totalOIDBaseAmt += item?.orderItemDiscount?.baseAmount;
			}
			return totalOIDBaseAmt;
		};

		const totalOIDBaseAmount = sumOIDBaseAmount(filterItemArray);

		//subtotal order item discount tax amount

		const sumOIDTaxAmount = (items: Item[]): number => {
			let totalOIDTax = 0;
			for (const item of items) {
				for (const tax of item?.orderItemDiscount?.orderItemDiscountTax)
					totalOIDTax += tax?.taxAmount;
			}
			return totalOIDTax;
		};

		const totalOIDTaxAmount = sumOIDTaxAmount(filterItemArray);
		const roundedTotalOIDTaxAmount = parseFloat(totalOIDTaxAmount.toFixed(2));

		//overall option item important field to sum up
		const sumOptionItemOverallField = (
			items: Item[],
		): {
			basePrice: number;
			taxAmount: number;
			discountBaseAmount: number;
			itemDiscountTaxAmount: number;
			discountItemTaxAmount: number;
		} => {
			let totalBasePriceOpt = 0;
			let totalTaxAmountOpt = 0;
			let totalDiscBaseAmountOpt = 0;
			let totalItemDiscTaxAmountOpt = 0;
			let totalDiscItemTaxAmountOpt = 0;
			for (const item of items) {
				if (item.orderItemOption) {
					for (const option of item.orderItemOption) {
						if (option.orderItemOptionItem) {
							for (const optionItem of option.orderItemOptionItem) {
								let totalTax = 0;
								optionItem?.orderItemOptionItemTax?.map(x => {
									totalTax += x?.taxAmount;
								});

								let totalOptItemOIDTax = 0;
								optionItem?.orderItemOptionItemDiscountTax?.map(x => {
									totalOptItemOIDTax += x?.taxAmount;
								});

								let totalOptItemODITax = 0;
								optionItem?.orderDiscountItemOptionTax?.map(v => {
									totalOptItemODITax += v?.taxAmount;
								});

								totalBasePriceOpt += optionItem.basePrice;
								totalTaxAmountOpt += totalTax;
								totalDiscBaseAmountOpt += optionItem.discountBaseAmount;
								totalItemDiscTaxAmountOpt += totalOptItemOIDTax;
								totalDiscItemTaxAmountOpt += totalOptItemODITax;
							}
						}
					}
				}
			}
			return {
				basePrice: totalBasePriceOpt,
				taxAmount: parseFloat(totalTaxAmountOpt.toFixed(2)),
				discountBaseAmount: totalDiscBaseAmountOpt,
				itemDiscountTaxAmount: parseFloat(totalItemDiscTaxAmountOpt.toFixed(2)),
				discountItemTaxAmount: parseFloat(totalDiscItemTaxAmountOpt.toFixed(2)),
			};
		};

		const totalOptItmOverallAmount = sumOptionItemOverallField(filterItemArray);

		optTaxAmount = totalOptItmOverallAmount?.taxAmount;

		discOptTaxAmount =
			totalOptItmOverallAmount?.itemDiscountTaxAmount +
			totalOptItmOverallAmount?.discountItemTaxAmount;

		totalOptItm =
			totalOptItmOverallAmount?.basePrice + totalOptItmOverallAmount?.taxAmount;

		discOptItm =
			totalOptItmOverallAmount?.discountBaseAmount +
			totalOptItmOverallAmount?.itemDiscountTaxAmount +
			totalOptItmOverallAmount?.discountItemTaxAmount;

		//tax
		finalTax =
			roundedTotalTaxAmount +
			optTaxAmount -
			roundedTotalODITaxAmount -
			roundedTotalOIDTaxAmount -
			discOptTaxAmount;

		//subtotal
		subtotal = totalAmount + roundedTotalTaxAmount + totalOptItm;

		//discount
		let ODI = totalODIBaseAmount + roundedTotalODITaxAmount;

		let OID = totalOIDBaseAmount + roundedTotalOIDTaxAmount;

		discount = ODI + OID + discOptItm;

		//total
		total = subtotal - discount;

		roundingAmount =
			getRoundingPolicy[0]?.inUse === true
				? generateRoundingAmount(
						total,
						getRoundingPolicy[0]?.smallestDenomination,
						getRoundingPolicy,
				  )
				: 0;
	}

	const [fs, setFS] = useState(null);
	const [ta, setTa] = useState(null);
	const [fd, setFD] = useState(null);
	const [to, setTo] = useState(null);
	const [ra, setRA] = useState(null);

	useEffect(() => {
		setFS(subtotal);
		setTa(finalTax);
		setFD(discount);
		setTo(total);
		setRA(roundingAmount);
	}, [subtotal, finalTax, discount, total]);

	let amount;

	return (amount = {
		subtotal: fs,
		tax: ta,
		discount: fd,
		total: page === 'payment' ? to + roundingAmount : to,
		roundingAmount: roundingAmount,
	});
};

export const generateRoundingAmount = (
	totalFooter,
	roundingPolicyAmount,
	getRoundingPolicy,
) => {
	const tempCents = totalFooter - Math.floor(totalFooter); // 16.24 - 16.00 = 0.24

	const cents = Math.round((tempCents + Number.EPSILON) * 100) / 100; // 0.24

	const roundingMulti = Math.round(
		(cents * 100) / (roundingPolicyAmount! * 100), // (0.24 * 100) / (0.05 * 100) = 24 / 5 = Math.round(4.8) = 5
	);

	const roundedAmount =
		getRoundingPolicy[0]?.inUse === true
			? Math.floor(totalFooter) + roundingPolicyAmount! * roundingMulti
			: totalFooter; // 16.00 + 0.05 * 5 = 16.

	const roundingAmount = (totalFooter - roundedAmount) * -1; // 16.24 - 16.25 = 0.01

	return Number(roundingAmount.toFixed(2));
};
