import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import React from 'react';

export const ConfirmationDialog = (props: any) => {
	const {
		updateConfirm,
		setUpdateConfirm,
		handleSubmit,
		onSubmit,
		targetPatron,
		currPatron,
		discount,
	} = props;

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={updateConfirm}
				onClose={() => setUpdateConfirm(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Confirmation</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							{discount !== 0 && currPatron === targetPatron ? (
								<span className="mdDesc" style={{ whiteSpace: 'initial' }}>
									The discount will not apply. Do you want to proceed with the
									changes?
								</span>
							) : currPatron !== targetPatron && discount === 0 ? (
								<span className="mdDesc" style={{ whiteSpace: 'initial' }}>
									The item price will change due to different patron. Do you
									want to proceed with the changes?
								</span>
							) : (
								<span className="mdDesc" style={{ whiteSpace: 'initial' }}>
									The discount will not apply & The item price will change due
									to different patron. Do you want to proceed with the changes?
								</span>
							)}
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'No',
								props: {
									onClick: () => setUpdateConfirm(false),
									variant: 'contained',
									color: 'secondary',
								},
							},
							{
								displayText: 'Yes',
								props: {
									onClick: () => {
										handleSubmit(onSubmit)();
										setUpdateConfirm(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
