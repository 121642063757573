import React from 'react';
import { CloseTable } from './CloseTable';

const closeTableRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/close-table`,
		},
		component: <CloseTable />,
	},
];

export default closeTableRoutes;
