import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { IAction } from 'helpers/model';
import React, { Reducer, useReducer } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { formatTime } from 'helpers/hooks/formatTime';
import {
	OrderStatus,
	useGetCurrentMealPeriodLazyQuery,
	useGetOrderQuery,
	useGetOutletNameQuery,
} from 'generated/graphql';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { Badge, Card, CardContent, Grid, Paper } from '@material-ui/core';
import NewOrderIcon from '../../../../assets/icons/NewOrderBig.svg';
import CloseTableIcon from '../../../../assets/icons/Close Table.svg';
import CloseTableGrayIcon from '../../../../assets/icons/CloseTableGray.svg';
import CloseOrderIcon from '../../../../assets/icons/CloseOrder.svg';
import { CloseTableDialog } from './CloseTableDialog';
import { CloseOrderDialog } from './CloseOrderDialog';

interface Props {
	outletID: any;
	tableID: any;
	currentMealPeriod: any;
	closeTableDialog: boolean;
	closeOrderDialog: boolean;
}

export const CloseTable = () => {
	const history = useHistory();
	let { state: location }: any = useLocation();

	let close = location?.close;
	let table = location.table;
	let order = location?.order;

	const { tableID, outletID } = useParams<Record<string, any>>();

	const initialState: Props = {
		outletID: outletID,
		tableID: tableID,
		currentMealPeriod: null,
		closeTableDialog: false,
		closeOrderDialog: false,
	};

	const reducer: Reducer<Props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	// API
	const {
		loading: outletLoading,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			dispatch({
				type: 'currentMealPeriod',
				payload: getCurrentMealPeriod,
			});
		},
	});

	const {
		loading: orderLoading,
		data: { getOrder } = { getOrder: [] },
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: { outletID, tableID: tableID, status: OrderStatus.Open },
	});

	return (
		<>
			<MainHeader
				onClick={() => {
					history.push({
						pathname: `/menu/outlet-app/${state?.outletID}/table/grid`,
					});
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Close Table', current: true },
				]}
				rightRouteSegments={[
					{
						name: state?.currentMealPeriod
							? `${state?.currentMealPeriod?.name}: ${formatTime(
									state?.currentMealPeriod?.startTime,
							  )} - ${formatTime(state?.currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>

			<DynamicSubHeader
				title={
					(table !== null || table !== undefined) && (
						<>
							{close === 'table' ? (
								<span className="highlight" style={{ color: '#ff9800' }}>
									{`${table?.prefix || ''}${table?.tableNumber || ''}`}
								</span>
							) : (
								<span className="highlight" style={{ color: '#ff9800' }}>
									{`${order?.table?.prefix || ''}${order?.table?.tableNumber ||
										''}`}
								</span>
							)}
							<span style={{ paddingLeft: '4px' }}>
								{`${order?.docNo || ''}`}
							</span>
							<span style={{ paddingLeft: '6px' }}>
								{`${order?.patron?.patronClass || ''}`}
							</span>
						</>
					)
				}
			/>

			<ContentWrapper singleDynamicInfo multiLineSubheader>
				<Grid container className="card-function mobile-app-grid">
					<Grid item xs={3} lg={3}></Grid>
					<Grid item xs={6} lg={3}>
						<Card style={{ backgroundColor: 'white' }}>
							{close === 'table' ? (
								<CardContent>
									<Paper
										onClick={() => {
											history.push({
												pathname: `/menu/outlet-app/${outletID}/table/grid/${table?.ID}/add`,
												state: {
													table: table,
													page: 'close-table',
												},
											});
										}}
										className="mobile-app"
									>
										<Badge>
											<img src={NewOrderIcon} alt="svg" />
										</Badge>
									</Paper>
									<div
										style={{ color: 'orange' }}
										className="xxTitle mobile-app-label"
									>{`New Order`}</div>
								</CardContent>
							) : (
								<CardContent>
									<Paper
										onClick={() => {
											dispatch({ type: 'closeOrderDialog', payload: true });
										}}
										className="mobile-app"
									>
										<Badge>
											<img src={CloseOrderIcon} alt="svg" />
										</Badge>
									</Paper>
									<div
										style={{ color: 'red' }}
										className="xxTitle mobile-app-label"
									>{`Close Order`}</div>
								</CardContent>
							)}
						</Card>
					</Grid>
					<Grid item xs={3} lg={3}></Grid>
				</Grid>

				<Grid container className="card-function mobile-app-grid">
					<Grid item xs={3} lg={3}></Grid>
					<Grid item xs={6} lg={3}>
						<Card style={{ backgroundColor: 'white' }}>
							<CardContent>
								<Paper
									onClick={() => {
										if (close === 'table') {
											dispatch({ type: 'closeTableDialog', payload: true });
										}
									}}
									className="mobile-app"
								>
									<Badge>
										<img
											src={
												close === 'table' ? CloseTableIcon : CloseTableGrayIcon
											}
											alt="svg"
										/>
									</Badge>
								</Paper>
								<div
									style={
										close === 'table' ? { color: 'red' } : { color: '#B9B9B9' }
									}
									className="xxTitle mobile-app-label"
								>{`Close Table`}</div>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={3} lg={3}></Grid>
				</Grid>
			</ContentWrapper>

			<CloseTableDialog
				state={state}
				dispatch={dispatch}
				table={table}
				getOrder={getOrder}
			/>

			<CloseOrderDialog
				state={state}
				dispatch={dispatch}
				orderItems={order?.orderItem}
				order={order}
			/>
		</>
	);
};
