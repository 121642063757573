import React from 'react';
import { OrderSummary } from './OrderSummary';

const orderSummaryRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/order/:orderID/order-summary`,
		},
		component: <OrderSummary />,
	},
];

export default orderSummaryRoutes;
