import { Switch } from '@material-ui/core';
import { OrderDiscountStatus } from 'generated/graphql';
import React from 'react';
import './discount.scss';

export const OrderDiscountFooter = (props: any) => {
	const {
		isDesktop,
		globalState,
		state,
		handleCheckBoxOD,
		getDiscount,
		footerAmount,
	} = props;

	return (
		<>
			<div
				className="order-discount-footer content-wrap full"
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
					justifyContent: 'space-between',
					display: 'flex',
					margin: 0,
				}}
			>
				<div
					style={{ paddingLeft: '10px', paddingTop: '8px' }}
					className="flex-space"
				>
					{state?.selectedOrder?.orderDiscount?.filter(
						disc => disc?.status === OrderDiscountStatus.Active,
					)?.length !== 0 ? (
						<span className="mdDesc highlight-text">
							{
								getDiscount?.filter(
									disc =>
										disc?.ID ===
										state?.selectedOrder?.orderDiscount?.filter(
											disc => disc?.status === OrderDiscountStatus.Active,
										)[0]?.discountID,
								)[0]?.code
							}
						</span>
					) : (
						<span
							className="mdDesc"
							style={{
								color: `${
									!state?.partialPaid &&
									state?.itemArray !== undefined &&
									state?.itemArray?.length !== 0
										? 'black'
										: 'darkgray'
								}`,
							}}
						>
							Order Discount
						</span>
					)}
				</div>

				<Switch
					checked={
						state?.selectedOrder?.orderDiscount?.filter(
							disc => disc?.status === OrderDiscountStatus.Active,
						)?.length !== 0 || state?.ODDialog
					}
					onChange={e => {
						if (
							!state?.partialPaid &&
							state?.itemArray !== undefined &&
							state?.itemArray?.length !== 0
						) {
							handleCheckBoxOD(e);
						}
					}}
					// disabled={
					// 	footerAmount?.total === 0 ||
					// 	state?.partialPaid ||
					// 	(state?.itemArray === undefined || state?.itemArray?.length === 0
					// 		? true
					// 		: false)
					// }
					color="primary"
				/>
			</div>
		</>
	);
};
